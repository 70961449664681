import React, { useState } from 'react'
import { VideoCard } from "material-ui-player";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
    Grid,
    Button,
    Modal,

} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#FFA100",
        },
    },
});
const Header = () => {
    const [openVideo, setOpenVideo] = useState(false);

    const handleCloseTutorialModal = () => {
        setOpenVideo(false);
    };
    const tutorialModal = (
        <div
            id="tutorial-modal"
            style={{
                background: "white",
                maxWidth: 1000,
                maxHeight: 600,
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 20,
                padding: 50,
                marginTop: 40,
                paddingLeft: 50,
                paddingRight: 50,
            }}
        >
            <ThemeProvider theme={theme}>
                <VideoCard
                    src="./tutorial.mp4"
                    autoplay={true}
                    mute={true}
                    thickness={"thin"}
                    onEnded={handleCloseTutorialModal}
                    width={800}
                    background={"white"}
                />
            </ThemeProvider>
        </div>
    );
    return (
        <div>
            <Grid item xs={12} style={{ display: "inline-block" }}>
                <img
                    alt="Decd Out Logo"
                    src={"./logo-alt.png"}
                    className="decdout-logo"
                />
                {/* play button here */}
                <Button
                    onClick={() => setOpenVideo(true)}
                    variant="contained"
                    style={{
                        position: "absolute",
                        top: "40px",
                        left: "225px",
                        borderRadius: "50px",
                        color: "white",
                        backgroundColor: "#FCB034",
                    }}
                >
                    Watch the tutorial
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Modal
                    open={openVideo}
                    onClose={handleCloseTutorialModal}
                    style={{ overflowY: "scroll", marginBottom: "20px" }}
                >
                    {tutorialModal}
                </Modal>
            </Grid>
        </div>
    )
}

export default Header