import React from 'react'
import Header from '../components/Header'
import maintenance from "../assets/maintenance.png"
const layout = ({ children }) => {
    return (
        <main>
            <Header />
            {/* <section className='maintenance-container d-flex justify-content-center align-items-center flex-column' style={{ height: "800px" }}>
                <div className='w-full d-flex justify-content-center align-items-center'>
                    <img src={maintenance} alt="Maintenance" width="550" height="auto" />
                </div>
                <h2>Website under maintenance....</h2>
                <p>Our website is currently undergoing scheduled maintenance.</p>
                <p>We should be back shortly. Thank you for your patience.</p>
            </section> */}
            {children}
        </main>
    )
}

export default layout