import React, { useEffect, useRef, useContext, useState, useMemo } from "react";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import EventEmitterContext from '../../../Utils/EventEmitterContext';
import { useFrame } from "@react-three/fiber";


const LogoCenteredTrolley = (props) => {
    const mesh = useRef()
    const texture = new THREE.TextureLoader().load(props.image)
    texture.repeat = new THREE.Vector2(1, 0.95)
    texture.center = new THREE.Vector2(1, 1)
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    texture.encoding = THREE.sRGBEncoding;
    const [load, setLoad] = useState(false)
    const eventEmitter = useContext(EventEmitterContext);
    const [customShape, setCustomShape] = useState(null)


    let process = props.process
    let transparent = true
    let roughness = 1
    let metalness = 0
    let clearcoat = 0
    let clearcoatRoughness = 0
    let metalMap = null
    let scale = 1
    let colorFilter = null;
    let emissiveFilter = null;
    let args = [10, 10]
    const showStrap = JSON.parse(sessionStorage.getItem('showStrap'))


    useEffect(() => {
        const callback = () => {
            setLoad(true)
        };
        eventEmitter.on('fullyLoaded', callback);
        return () => eventEmitter.off('fullyLoaded')
    }, [])


    useEffect(() => {
        if (load) {
            if (props.productCode === "BGR104" || props.productCode === "BG104") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_above_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            } else if (props.productCode === "BG206") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_back.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG201") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_strap_on.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };

                loadCustomShape();
            }
            else if (props.productCode === "BGR101") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_above_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };

                loadCustomShape();
            }
            else if (props.productCode === "BGR102") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_above_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };

                loadCustomShape();
            }
            else if (props.productCode === "BGR103") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_abovePocket_F.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR122") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_above_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR210") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_b.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG102") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_flap_b.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }

        }
    }, [load, props.productCode]);


    // const options = useMemo(() => {
    //     return {
    //         x: { value: -0.07, min: -10, max: 10, step: 0.01 },
    //         y: { value: 0, min: -10, max: 10, step: 0.01 },
    //         z: { value: 0, min: -10, max: 10, step: 0.01 },

    //     }
    // }, [])

    // const rot = useControls('Rotation Trolleyy BGR210', options)

    if (load) {
        if (process === "LASER ENGRAVED" || process === "REFLECTION LASER") {
            metalness = 1;
            roughness = 0.35;
            metalMap = new THREE.TextureLoader().load("brushed.png");
        } else if (process === "SILK SCREENED" || process === "LASER TOUCH") {
            metalness = 0.75;
            roughness = 1;
        } else if (process === "4CP FLEX") {
            clearcoat = 0;
            clearcoatRoughness = 0;
        } else if (process === "4CP FLEX TRANSFER") {
            metalness = 0.90;
            roughness = 1;
        } else {
            metalness = 0.75;
            roughness = 1;
            clearcoat = 0.75
            clearcoatRoughness = 0.075
        }
        if (props.productCode === 'BG500') {
            // args = [0.51, 0.78];
            args = [4.18, 5.12]
        }
        else if (props.productCode === 'BGR710') {
            scale = 0.11
            args = [0.75, 1];
        }

        else if (props.productCode === 'BGR204') {
            scale = 3.24
            args = [4.02, 5.35]
            texture.repeat = new THREE.Vector2(1.21, 1.02);
        }
        else if (props.productCode === 'BG204') {
            scale = 3.24
            args = [4.02, 5.35]
            texture.repeat = new THREE.Vector2(1.21, 1.02);
        }

    }

    useEffect(() => {
        if (load) {
            if (props.productCode === 'BG500') {
                mesh.current.rotation.x = 0.23
                mesh.current.rotation.y = 3.15;
            }
            else if (props.productCode === "BGR104") {
                mesh.current.rotation.x = -0.07
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            } else if (props.productCode === "BG104") {
                mesh.current.rotation.x = -0.07
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG206") {
                mesh.current.rotation.x = 0.08
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR204") {
                mesh.current.rotation.x = -0.44
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG204") {
                mesh.current.rotation.x = 0
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            if (props.productCode === "BG201") {
                if (showStrap) {
                    mesh.current.rotation.x = -1.56
                    mesh.current.rotation.y = 0.00
                    mesh.current.rotation.z = 0
                } else {
                    mesh.current.rotation.x = 0
                    mesh.current.rotation.y = 0.00
                    mesh.current.rotation.z = 0
                }
            }
            else if (props.productCode === "BGR101") {
                mesh.current.rotation.x = 0
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR102") {
                mesh.current.rotation.x = 0
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR103") {
                mesh.current.rotation.x = -0.01
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR210") {
                mesh.current.rotation.x = -0.05
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG102") {
                mesh.current.rotation.x = 0
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
        }
    }, [load])

    useFrame(() => {
        if (load && props.productCode === "BGR104") {
            mesh.current.scale.set(0.05, 0.07, 0.06);
        } else if (load && props.productCode === "BG104") {
            mesh.current.scale.set(0.05, 0.07, 0.06);
        }
        else if (load && props.productCode === "BG206") {
            mesh.current.scale.set(0.05, 0.077, 0.001);
        }
        else if (load && props.productCode === "BG201") {
            mesh.current.scale.set(0.02, 0.025, 0.02);
        }
        else if (load && props.productCode === "BGR101") {
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BGR102") {
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BGR103") {
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BGR122") {
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BGR210") {
            mesh.current.scale.set(0.032, 0.06, 0.05);
        }
        else if (load && props.productCode === "BG102") {
            if (props.isRectangle === "vertical") {
                console.log('vertical')
                mesh.current.scale.set(0.012, 0.012, 0.12);
            }
            else {
                console.log('else')
                mesh.current.scale.set(0.03, 0.056, 0.05);
            }
        }
    })

    return load ? (
        <mesh
            {...props}
            ref={mesh}
            scale={0.1}
        >
            {
                (props.productCode === "BGR104" ||
                    props.productCode === "BG104" ||
                    props.productCode === "BG206" ||
                    props.productCode === "BGR101" ||
                    props.productCode === "BGR102" ||
                    props.productCode === "BGR103" ||
                    props.productCode === "BGR122" ||
                    props.productCode === "BGR210" ||
                    props.productCode === "BG102"
                )
                    && customShape ? (
                    <bufferGeometry attach="geometry"
                        attributes={customShape.children[0].geometry.attributes}
                        boundingBox={customShape.children[0].geometry.boundingBox}
                        boundingSphere={customShape.children[0].geometry.boundingSphere}
                        drawRange={customShape.children[0].geometry.drawRange}
                        groups={customShape.children[0].geometry.groups}
                        index={customShape.children[0].geometry.index}
                        morphAttributes={customShape.children[0].geometry.morphAttributes}
                        morphTargetsRelative={customShape.children[0].geometry.morphTargetsRelative}
                        name={customShape.children[0].geometry.name}
                        type={customShape.children[0].geometry.type}
                        userData={customShape.children[0].geometry.userData}
                        uuid={customShape.children[0].geometry.uuid}
                    >
                    </bufferGeometry>
                ) : (
                    <planeBufferGeometry attach="geometry" args={args} />
                )
            }
            <meshPhysicalMaterial
                transparent={transparent}
                side={THREE.FrontSide}
                roughness={roughness}
                clearcoatRoughness={clearcoatRoughness}
                clearcoat={clearcoat}
                metalness={metalness}
                metalnessMap={metalMap}
                color={colorFilter}
                emissive={emissiveFilter}
                anisotropy={4}

            >
                <primitive attach="map" object={texture} />
            </meshPhysicalMaterial>
        </mesh>
    ) : null;
}

export default LogoCenteredTrolley