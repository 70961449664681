import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import EventEmitterContext from '../../../Utils/EventEmitterContext';




const LogoCenteredBack = (props) => {
  const mesh = useRef();
  const texture = new THREE.TextureLoader().load(props.image);
  texture.encoding = THREE.sRGBEncoding;
  const transparent = true;
  const [load, setLoad] = useState(false)
  const eventEmitter = useContext(EventEmitterContext);
  const [customShape, setCustomShape] = useState(null)

  let process = props.process;

  let roughness = 1;
  let metalness = 0;
  let clearcoat = 0;
  let clearcoatRoughness = 0;
  let args = [0.9575, 0.9575, 2.35, 250, 250, true, -4.15, 2];
  let metalMap = null;
  let colorFilter = null;
  let emissiveFilter = null;
  let scale = 1;


  useEffect(() => {
    const callback = () => {
      // Handle the event
      setLoad(true)
    };

    eventEmitter.on('fullyLoaded', callback);
    return () => eventEmitter.off('fullyLoaded')
  }, [])

  useEffect(() => {
    // Load the FBX file only when props.productCode is not "BG504"
    if (load && (props.productCode === "DW307" || props.productCode === "DW103")) {
      const loadCustomShape = async () => {
        try {
          const customShapeData = await new Promise((resolve, reject) => {
            new FBXLoader().load('/' + props.productCode + '_branding_mesh_f.fbx', resolve, undefined, reject);
          });
          setCustomShape(customShapeData);
        } catch (error) {
          console.error("Error loading FBX:", error);
        }
      };
      loadCustomShape();
    }
  }, [load, props.productCode]);





  if (load) {
    if (props.productCode !== "DW307" && props.productCode !== "DW103") {
      texture.repeat = new THREE.Vector2(1, 0.95);
      texture.center = new THREE.Vector2(1, 1);
    } else {
      if (props.productCode === "DW307") {
        scale = 0.255;
        if (props.isRectangle === "vertical") {
          texture.repeat = new THREE.Vector2(1, 1.65);
          texture.center = new THREE.Vector2(1, 0.5);
        } else if (props.isRectangle === "normal") {
          texture.repeat = new THREE.Vector2(0.55, 1.2);
          texture.center = new THREE.Vector2(0.5, 0.55);
          console.log("normal");
        } else {
          texture.repeat = new THREE.Vector2(1, 1.65);
          texture.center = new THREE.Vector2(1, 0.5);
        }
      } else if (props.productCode === "DW103") {
        scale = 0.2125;
        texture.repeat = new THREE.Vector2(1, 0.95);
        texture.center = new THREE.Vector2(1, 1);
      }
    }
  }
  if (load) {
    if (
      process === "LASER ENGRAVED" ||
      process === "REFLECTION LASER"
    ) {
      metalness = 1;
      roughness = 0.35;
      metalMap = new THREE.TextureLoader().load("brushed.png");
    } else if (process === "SILK SCREENED") {
      metalness = 0.75;
      roughness = 1;
      // colorFilter = new THREE.Color( 0xFFFFFF )
      // emissiveFilter = new THREE.Color( 0x252525 )
    } else if (process === "4CP FLEX") {
      clearcoat = 0;
      clearcoatRoughness = 0;
    }
    else if (process === "4CP FLEX TRANSFER") {
      metalness = 0.90;
      roughness = 1;
    }
    else {
      metalness = 0.8;
      roughness = 0.5;
      clearcoat = 0.75;
      clearcoatRoughness = 0.075;
      // colorFilter = new THREE.Color( 0x969696 )
      // emissiveFilter = new THREE.Color( 0x040404 )
    }
  }

  if (load) {
    if (props.productCode === "DW409") {
      args = [0.995, 0.995, 2.35, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW313") {
      args = [0.835, 0.835, 2.35, 250, 250, true, -4.15, 2.35];
    } else if (props.productCode === "DW319") {
      args = [0.985, 0.985, 2.35, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW311") {
      args = [0.8, 0.745, 1.9, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW314") {
      args = [0.82, 0.7675, 1.9, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW403") {
      args = [1.095, 1.095, 1.15, 250, 250, true, -4.15, 1.05];
    } else if (props.productCode === "DW404") {
      args = [1.095, 1.095, 1.425, 250, 250, true, -4.15, 1.05];
    } else if (props.productCode === "DW413") {
      args = [1.0945, 1.0945, 1.2, 250, 250, true, -4.15, 1.05];
    } else if (props.productCode === "DW410") {
      args = [1.1065, 1.11525, 1.65, 250, 250, true, -4.15, 1.55];
    } else if (props.productCode === "DW201") {
      args = [1.0, 1.0, 2, 250, 250, true, -0.9, 1.6];
    }
    else if (props.productCode === "DW412") {
      args = [1.11, 1.11, 1.65, 250, 250, true, -4.15, 1.55];
    } else if (props.productCode === "DW414") {
      args = [1.109475, 0.905, 2.15, 250, 250, true, -4.15, 1.55];
    } else if (props.productCode === "DW415") {
      args = [1.125, 0.91, 1.75, 250, 250, true, -4.075, 1.4];
    } else if (props.productCode === "DW402") {
      args = [1.1065, 1.11525, 1.65, 250, 250, true, -4.15, 1.55];
    } else if (props.productCode === "DW406") {
      args = [1.11775, 1.11775, 2, 250, 250, true, -4.15, 1.4];
    } else if (props.productCode === "DW411") {
      args = [1.11775, 1.11775, 2, 250, 250, true, -4.15, 1.4];
    } else if (props.productCode === "DW407") {
      args = [1.1177, 1.1179, 2, 250, 250, true, -4.15, 1.4];
    } else if (props.productCode === "DW306") {
      args = [0.985, 0.985, 2.15, 250, 250, true, -4.15, 1.55];
    } else if (props.productCode === "DW101") {
      args = [0.91, 0.915, 2.15, 250, 250, true, -4.15, 2];
      // args = [0.9115, 0.9115, 2.15 , 250, 250, true, -4.15, 2]
    } else if (props.productCode === "DW100" || props.productCode === "DW300") {
      args = [0.895, 0.895, 2.15, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW310") {
      args = [0.9275, 0.9275, 2.15, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW309") {
      // args = [0.795, 0.795, 1.85, 250, 250, true, -4.15, 2]
      args = [0.8, 0.745, 1.9, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW301") {
      // args = [1.005, 1.005, 1.85, 250, 250, true, -4.15, 1.6]
      args = [0.991875, 1.005, 1.85, 250, 250, true, -4.15, 1.6];
    } else if (props.productCode === "DW308") {
      args = [0.875, 0.875, 2.35, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW103") {
      args = [0.875, 0.875, 2.1, 250, 250, true, -2, 1.5]
    } else if (props.productCode === "DW401") {
      args = [1.142, 0.948, 2.35, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "DW307") {
      args = [0.9575, 0.9575, 2.35, 250, 250, true, 2.5, 1.25];
    } else if (props.productCode === "DW321") {
      args = [0.91, 0.91, 2.125, 250, 250, true, -4.15, 2];
    } else if (props.productCode === "G1249") {
      args = [0.3, 0.3, 1.1, 250, 250, true, 2.6, 1.1];
    }
    else if (props.productCode === "BGR710") {
      args = [0.63, 0.72, 1.1, 250, 250, true, 2.6, 1.1];
    }
    else if (props.productCode === "G3141") {
      args = [0.177, 0.177, 1, 250, 250, true, 0.75, 1.7];
    }
    else if (props.productCode === "G1298") {
      args = [0.177, 0.177, 1, 250, 250, true, 0.75, 1.7];
    }
    else if (props.productCode === "DW416") {
      args = [1.11, 0.91, 1.75, 250, 250, true, -0.9, 1.4];
    }
  }

  useEffect(() => {
    if (load) {
      if (props.productCode === "DW311") {
        mesh.current.rotation.x = -0;
      } else if (props.productCode === "DW313") {
        mesh.current.rotation.y = -0.175;
      } else if (props.productCode === "DW314") {
        mesh.current.rotation.x = 0;
        mesh.current.rotation.z = 0.0;
        // mesh.current.rotation.z = 0.001;
      } else if (props.productCode === "DW404") {
        mesh.current.rotation.x = 0.0725;
        mesh.current.rotation.z = 0.0425;
      } else if (props.productCode === "DW404") {
        mesh.current.rotation.x = 0.08275;
        mesh.current.rotation.y = 0.475;
        mesh.current.rotation.z = 0.0;
      } else if (props.productCode === "DW413") {
        mesh.current.rotation.x = 0.0825;
        mesh.current.rotation.y = 0.45;
        mesh.current.rotation.z = 0.0025;
      } else if (props.productCode === "DW402") {
        // mesh.current.rotation.x = -0.05;
        // mesh.current.rotation.y = 0.225;
        mesh.current.rotation.x = -0.03025;
        mesh.current.rotation.y = 0.225;
      } else if (props.productCode === "DW403") {
        // mesh.current.rotation.x = 0.0725;
        // mesh.current.rotation.z = 0.0025;
        // mesh.current.rotation.y = 0.45;
        mesh.current.rotation.x = 0.08275;
        mesh.current.rotation.y = 0.475;
        mesh.current.rotation.z = 0.0;
      } else if (props.productCode === "DW410") {
        mesh.current.rotation.x = -0.03;
        mesh.current.rotation.y = 0.225;
      } else if (props.productCode === "DW412") {
        mesh.current.rotation.x = -0.025;
        mesh.current.rotation.y = 0.225;
      } else if (props.productCode === "DW414") {
        mesh.current.rotation.y = 0.225;

        mesh.current.rotation.x = -0;
        mesh.current.rotation.z = -0;
      } else if (props.productCode === "DW415") {
        mesh.current.rotation.y = 0.2;
        mesh.current.rotation.x = 0.037;
        mesh.current.rotation.z = -0;
      } else if (props.productCode === "DW406" || props.productCode === "DW407") {
        mesh.current.rotation.y = 0.275;
      } else if (props.productCode === "DW306") {
        mesh.current.rotation.y = 0.225;
      } else if (props.productCode === "DW309") {

        mesh.current.rotation.x = 0;
      } else if (props.productCode === "DW301") {
        mesh.current.rotation.x = 0.015;
        mesh.current.rotation.y = 0.2;
      }
      else if (props.productCode === "DW201") {
        mesh.current.rotation.x = 0.01
        mesh.current.rotation.y = -3.10
        mesh.current.rotation.z = 0.00
      }
      else if (props.productCode === "DW103") {
        mesh.current.rotation.y = -3.15;
      } else if (props.productCode === "DW401") {
        mesh.current.rotation.x = -0;
      } else if (props.productCode === "DW307") {
        // mesh.current.rotation.x = -0.005;
        mesh.current.rotation.y = -9.4225;
        // mesh.current.rotation.z = -0.001;
      } else if (props.productCode === "DW411") {
        mesh.current.rotation.y = 0.3;
      }
      else if (props.productCode === "BGR710") {
        mesh.current.rotation.x = -0.08
        mesh.current.rotation.y = -3.14
      }
      else if (props.productCode === "G3141") {
        mesh.current.rotation.x = 0
        mesh.current.rotation.y = 2.73
        mesh.current.rotation.z = 0
      }
      else if (props.productCode === "G1298") {
        mesh.current.rotation.x = 0
        mesh.current.rotation.y = 2.73
        mesh.current.rotation.z = 0
      }
    }
  }, [load]);

  return load ? (
    <mesh {...props} ref={mesh} scale={scale}>
      {
        (props.productCode === 'DW307' || props.productCode === 'DW103') && customShape ? (
          <bufferGeometry attach="geometry"
            attributes={customShape.children[0].geometry.attributes}
            boundingBox={customShape.children[0].geometry.boundingBox}
            boundingSphere={customShape.children[0].geometry.boundingSphere}
            drawRange={customShape.children[0].geometry.drawRange}
            groups={customShape.children[0].geometry.groups}
            index={customShape.children[0].geometry.index}
            morphAttributes={customShape.children[0].geometry.morphAttributes}
            morphTargetsRelative={customShape.children[0].geometry.morphTargetsRelative}
            name={customShape.children[0].geometry.name}
            type={customShape.children[0].geometry.type}
            userData={customShape.children[0].geometry.userData}
            uuid={customShape.children[0].geometry.uuid}

          >
          </bufferGeometry>
        ) : (
          (props.productCode === "BGR710") ? (
            <planeBufferGeometry attach="geometry" args={args} />
          ) : (
            <cylinderBufferGeometry attach="geometry" args={args} />
          )
        )
      }
      <meshPhysicalMaterial
        transparent={transparent}
        side={THREE.DoubleSide}
        roughness={roughness}
        clearcoatRoughness={clearcoatRoughness}
        clearcoat={clearcoat}
        metalness={metalness}
        // normalMap={texture}
        metalnessMap={metalMap}
        color={colorFilter}
        emissive={emissiveFilter}
        alphaTest={0.5}
      >
        <primitive attach="map" object={texture} />
      </meshPhysicalMaterial>
    </mesh>
  ) : null;
};

export default LogoCenteredBack;
