import React, { useRef, useState, useLayoutEffect } from "react"
import Swal from 'sweetalert2'
import productData from "../../productData"
import Canvas3D from "./Canvas3D"
import {
    Grid,
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Fab,
} from "@mui/material"
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';



const ProductBuild = () => {

    const snapshotRef = useRef()


    const [productColor, setProductColor] = useState(localStorage.getItem("productColor"))
    const [colorList, setColorList] = useState([])

    const [sceneList, setSceneList] = useState([])

    console.log('productBuild')

    const onProductColorChange = (event) => {
        setProductColor(event.target.value)
        localStorage.setItem("productColor", event.target.value)
    }

    const onClickNext = () => {
        localStorage.setItem("productColor", productColor)
        window.location.href = '/brand-selection'
    }

    const onClickBack = () => {
        window.location.href = '/'
    }


    useLayoutEffect(() => {
        let productCode = localStorage.getItem('productCode')
        try {
            let productDetails = productData.find(product => product.productCode === productCode)
            setColorList(productDetails.colorsAvailable)
            let newState = []
            productDetails.colorsAvailable.forEach((product) => {
                let temp = {}
                temp['productColor'] = product.code
                temp['scene'] = <Canvas3D id="canvas-scene" ref={snapshotRef} productCode={localStorage.getItem('productCode')} productColor={product.code} />
                newState.push(temp)
            })
            setSceneList(newState)
        } catch (error) {
            Swal.fire({
                title: 'Product unavailable!',
                text: 'Please choose a product first before choosing a color variant.',
                icon: 'error',
                confirmButtonText: 'OK, take me back.',
            }).then((result) => {
                window.location.href = "/"
            })
        }
    }, [])


    return (
        <Box sx={{ flexGrow: 1, paddingTop: 10, paddingLeft: 20 }}>
            <Grid container>
                <Grid item xs={7}>
                    <Card sx={{ border: 'none', boxShadow: 'none' }}>
                        <CardContent>
                            <Grid container spacing={1} sx={{ padding: 2 }} alignItems="center">
                                <Grid item xs={12} style={{ paddingTop: 20, paddingLeft: 5 }}>
                                    <Grid container spacing={1} sx={{ padding: 2 }} alignItems="center">
                                        <Grid item xs={3}>
                                            <Typography style={{ fontSize: '1.5rem' }}><span className="main-font" style={{ color: 'red' }}>* </span><span className="main-font">Product Color</span></Typography>
                                        </Grid>
                                        <Grid item xs={5} style={{ marginLeft: 30 }}>
                                            <FormControl variant='outlined' sx={{ width: "100%", marginRight: 10 }} focused={false}>
                                                <Select
                                                    notched={false}
                                                    IconComponent={UnfoldMoreIcon}
                                                    style={{ borderRadius: 15, height: 70, paddingLeft: 10, fontSize: '1.5rem' }}
                                                    onChange={onProductColorChange}
                                                    value={productColor}
                                                >
                                                    <MenuItem selected disabled value={0} style={{ display: "none", color: "lightgray" }}>
                                                        <em style={{ color: "lightgray" }}><span className="main-font">Select product color</span></em>
                                                    </MenuItem>
                                                    {colorList && colorList.map((color, i) => {
                                                        return (
                                                            <MenuItem className="main-font" key={i} value={color.code}>
                                                                <span className="main-font">{color.name}</span>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    position: 'absolute',
                                    zIndex: '90',
                                    bottom: '7.5%'
                                }}>
                                    <Button onClick={onClickBack} style={{ backgroundColor: "#FFA100", color: 'white', borderRadius: 20, paddingRight: 20 }}>
                                        <NavigateBeforeRoundedIcon />
                                        <span className="main-font">BACK</span>
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button onClick={onClickNext} style={{ backgroundColor: "#FFA100", color: 'white', borderRadius: 20, paddingLeft: 20 }}>
                                        <span className="main-font">NEXT</span>
                                        <NavigateNextRoundedIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Fab
                        onClick={() => snapshotRef.current.takeScreenshot()}
                        sx={{
                            backgroundColor: "#ffbf4f",
                            color: 'white',
                            '&:hover': { backgroundColor: "#ffbf4f", color: 'black' },
                            position: 'absolute',
                            zIndex: '99',
                            right: '2.5%'
                        }}>
                        <CameraAltRoundedIcon />
                    </Fab>
                    <div id="canvas-area">
                        {
                            sceneList.map((item, i) => {
                                return (
                                    <div key={i} style={{ display: item.productColor === productColor ? 'block' : 'none' }}>
                                        {item.scene}
                                    </div>
                                )
                            })
                        }
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProductBuild