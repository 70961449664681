import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import * as THREE from "three";
import EventEmitterContext from '../../../Utils/EventEmitterContext';
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { useFrame } from "@react-three/fiber";



const LogoCenteredBottom = (props) => {
    const mesh = useRef()
    const texture = new THREE.TextureLoader().load(props.image)
    texture.repeat = new THREE.Vector2(1, 0.95)
    texture.center = new THREE.Vector2(1, 1)
    texture.encoding = THREE.sRGBEncoding;
    const [load, setLoad] = useState(false)
    const eventEmitter = useContext(EventEmitterContext);
    const [customShape, setCustomShape] = useState(null)

    let process = props.process
    let roughness = 1
    let metalness = 0
    let clearcoat = 0
    let clearcoatRoughness = 0
    let metalMap = null
    let scale = 1
    let colorFilter = null;
    let emissiveFilter = null;
    let args = [0.9575, 0.9575, 2.35, 250, 250, true, -1, 2]

    useEffect(() => {
        const callback = () => {
            setLoad(true)
        };
        eventEmitter.on('fullyLoaded', callback);
        return () => eventEmitter.off('fullyLoaded')
    }, [])


    useEffect(() => {
        // Load the FBX file only when load is true 

        if (load && props.productCode === "BGR104") {
            const loadCustomShape = async () => {
                try {
                    const customShapeData = await new Promise((resolve, reject) => {
                        new FBXLoader().load('/' + props.productCode + '_branding_pocket.fbx', resolve, undefined, reject);
                    });
                    setCustomShape(customShapeData);
                } catch (error) {
                    console.error("Error loading FBX:", error);
                }
            };
            loadCustomShape();
        }

    }, [load, props.productCode]);




    if (process === 'LASER ENGRAVED' || process === 'REFLECTION LASER') {
        metalness = 1
        roughness = 0.35
        metalMap = new THREE.TextureLoader().load('brushed.png')
    } else if (process === 'SILK SCREENED' || process === 'LASER TOUCH') {
        metalness = 0.75
        roughness = 1
    } else if (process === '4CP FLEX') {
        clearcoat = 0
        clearcoatRoughness = 0
    } else if (process === "4CP FLEX TRANSFER") {
        metalness = 0.90;
        roughness = 1;
    }
    else {
        metalness = 0.5
        roughness = 0.275
        clearcoat = 0.75
        clearcoatRoughness = 0.075
    }

    if (props.productCode === 'DW409') {
        args = [0.995, 0.995, 2.35, 250, 250, true, -1, 2]
    } else if (props.productCode === 'DW313') {
        args = [0.835, 0.835, 2.35, 250, 250, true, -1, 2]
    } else if (props.productCode === 'DW319') {
        args = [0.985, 0.985, 2.35, 250, 250, true, -1, 2]
    } else if (props.productCode === 'DW316' || props.productCode === 'DW317') {
        args = [0.8205, 0.8205, 1.85, 250, 250, true, -1, 2]
    } else if (props.productCode === 'G3001') {
        args = [0.205, 0.205, 1.1, 250, 250, true, -3.25, 3.5]
    } else if (props.productCode === 'G3108') {
        args = [0.205, 0.205, 1.1, 250, 250, true, -2.4, 1.6]
    } else if (props.productCode === 'G3113') {
        args = [0.205, 0.205, 1, 250, 250, true, -2.25, 1.5]
    } else if (props.productCode === 'G3141') {
        args = [0.1775, 0.1775, 1, 250, 250, true, -0.85, 1.75]
    } else if (props.productCode === 'G3148') {
        args = [0.2125, 0.2125, 1.25, 250, 250, true, -0.85, 1.75]
    } else if (props.productCode === 'G1103') {
        args = [0.2444, 0.235, 1.25, 250, 250, true, -3.4, 3.5]
    } else if (props.productCode === 'G1282') {
        args = [0.185, 0.185, 1.1, 250, 250, true, -0.85, 1.75]
    } else if (props.productCode === 'I154') {
        args = [0.175, 0.175, 1.15, 250, 250, true, -0.85, 1.75]
    } else if (props.productCode === 'EC146') {
        args = [0.1925, 0.1925, 1.15, 250, 250, true, -0.85, 1.75]
    } else if (props.productCode === 'EC122') {
        args = [0.205, 0.205, 1.1, 250, 250, true, -2.6, 1.75]
    } else if (props.productCode === 'EC131') {
        args = [0.205, 0.205, 1.1, 250, 250, true, -2.4, 1.75]
    } else if (props.productCode === 'I153') {
        args = [0.2125, 0.2125, 1.1, 250, 250, true, -2.55, 1.75]
    }

    useEffect(() => {
        if (load) {
            if (props.productCode === 'DW316') {
                mesh.current.rotation.x = 0;
            } else if (props.productCode === 'DW313') {
                mesh.current.rotation.x = 0;
            }

        }
    }, [load])

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={scale}
        >
            {
                (props.productCode === 'DW307' || props.productCode === 'DW103' || props.productCode === "BGR104") && customShape ? (
                    <bufferGeometry attach="geometry"
                        attributes={customShape.children[0].geometry.attributes}
                        boundingBox={customShape.children[0].geometry.boundingBox}
                        boundingSphere={customShape.children[0].geometry.boundingSphere}
                        drawRange={customShape.children[0].geometry.drawRange}
                        groups={customShape.children[0].geometry.groups}
                        index={customShape.children[0].geometry.index}
                        morphAttributes={customShape.children[0].geometry.morphAttributes}
                        morphTargetsRelative={customShape.children[0].geometry.morphTargetsRelative}
                        name={customShape.children[0].geometry.name}
                        type={customShape.children[0].geometry.type}
                        userData={customShape.children[0].geometry.userData}
                        uuid={customShape.children[0].geometry.uuid}

                    >
                    </bufferGeometry>
                ) : (
                    (props.productCode === "BGR710") ? (
                        <planeBufferGeometry attach="geometry" args={args} />
                    ) : (
                        <cylinderBufferGeometry attach="geometry" args={args} />
                    )
                )
            }
            <meshPhysicalMaterial
                transparent
                side={THREE.FrontSide}
                roughness={roughness}
                clearcoatRoughness={clearcoatRoughness}
                clearcoat={clearcoat}
                metalness={metalness}
                // normalMap={texture}
                metalnessMap={metalMap}
            >
                <primitive attach="map" object={texture} />
            </meshPhysicalMaterial>
        </mesh>
    );
}

export default LogoCenteredBottom