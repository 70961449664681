import React, { useRef, useState, useLayoutEffect, useEffect, useContext } from "react";
import { Grid, Box, Button, Card, CardContent, Typography, Fab, Tooltip } from "@mui/material";
import SwipeLeftTwoToneIcon from "@mui/icons-material/SwipeLeftTwoTone";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import ShareIcon from "@mui/icons-material/Share";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import Swal from "sweetalert2";

import Canvas3D from "./Canvas3D";
import BrandDesigner from "./BrandingDesigner";

import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./CanvasPreview";
import EventEmitterContext from '../../Utils/EventEmitterContext';
function initializeDesigner() {
  sessionStorage.setItem("currentRotationCENTERED ON FRONT", 0);
  sessionStorage.setItem("currentRotationSIDE OF BARREL", -90);
  sessionStorage.setItem("currentRotationIN LINE WITH CLIP", -90);
  sessionStorage.setItem("currentRotationBESIDE CLIP", -90);
  sessionStorage.setItem("currentRotationON CLIP", -90);
  if (sessionStorage.getItem("productType") === "pen") {
    sessionStorage.setItem("currentRotationBESIDE CLIP 2ND LOC", -90);
  } else {
    sessionStorage.setItem("currentRotationCENTERED ON BACK", 0);
  }
  sessionStorage.setItem("currentRotationCENTERED ON CAP", 0);
  sessionStorage.setItem("currentRotationCENTERED ON UPPER BODY", 0);
  sessionStorage.setItem("currentRotationCENTERED ON BAND", 0);
  sessionStorage.setItem("currentToggleCENTERED ON FRONT", false);
  sessionStorage.setItem("currentToggleCENTERED ON BACK", false);
  sessionStorage.setItem("currentToggleCENTERED ON CAP", false);
  sessionStorage.setItem("currentToggleCENTERED ON UPPER BODY", false);
  sessionStorage.setItem("currentToggleCENTERED ON BAND", false);
  sessionStorage.setItem("currentScaling", 100);
}
const Home = () => {
  const options = JSON.parse(sessionStorage.getItem("brandingOptions"));
  const [brandingProperties, setBrandingProperties] = useState(options.finalOptions);
  const [brandingOptions, setBrandingOptions] = useState(options.finalOptions);
  const [logoFront, setLogoFront] = useState("");
  const [logoFrontBottom, setLogoFrontBottom] = useState("");
  const [logoFrontUpper, setLogoFrontUpper] = useState("");
  const [logoBack, setLogoBack] = useState("");
  const [logoCap, setLogoCap] = useState("");
  const [logoClip, setLogoClip] = useState("");
  const [logoFrontRight, setLogoFrontRight] = useState("")
  const [logoFrontLeft, setLogoFrontLeft] = useState("")
  const [logoFrontAnchor, setLogoFrontAnchor] = useState("")
  const [logoFrontTrolley, setLogoFrontTrolley] = useState("")
  const [logoFrontFlap, setLogoFrontFlap] = useState("")
  const [showPrompt, setShowPrompt] = useState(true);
  const snapshotRef = useRef();
  const prodType = sessionStorage.getItem("productType")
  const [load, setLoad] = useState(false)
  const eventEmitter = useContext(EventEmitterContext);

  const changeLayer = (index) => {
    let newState = [...brandingOptions];
    sessionStorage.setItem("currentView", brandingOptions[index].location);
    newState.forEach((state, i) => {
      if (i === index) {
        state.showBranding = true;
      } else {
        state.showBranding = false;
      }
    });
    setBrandingOptions(newState);
  };

  const loadPositions = () => {
    Swal.fire({
      title: "Prepping merch to be dec’d out...",
      text: `Applying logos to ${prodType}`,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      customClass: {
        loader: "custom-loader",
      },
      timer: 6200,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setTimeout(() => {
      Swal.getTitle().textContent = "Applying selected branding solutions...";
    }, 2150);
    setTimeout(() => {
      Swal.getTitle().textContent = "Here we go...";
    }, 2300);
  };

  const callback = () => {
    console.log('trigger inside')
    setLoad(true)
    eventEmitter.off('fullyModel')

  };
  eventEmitter.on('fullyModel', callback);


  const renderBrandings = (options) => {
    options.forEach((branding) => {
      const canvas = document.createElement("canvas");
      const image = document.createElement("img");
      const canvasWidth = sessionStorage.getItem("productType") === "pen" ? 128 : 384;
      const canvasHeight = 535;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      image.src = branding.imgStr
      image.width = canvasWidth;

      // Calculate the center point of the image
      const centerX = canvasWidth / 2;
      const centerY = canvasHeight / 2;
      const location = branding.location.toUpperCase();

      canvasPreview(image, canvas, canvas, 100, 0, 0, 0, branding.location, centerX, centerY).then((data) => {
        if (location === "CENTERED ON FRONT" || location === "SIDE OF BARREL" || location === "CENTERED BELOW SPOUT" || location === "CENTERED ON BAND" || location === "CENTERED BELOW CARRY HANDLE" || location === "CENTERED NEXT TO HANDLE - RIGHT HANDED" || location === "CENTERED OPPOSITE OF PHONE STAND IN USE" || location === "IN LINE WITH WIRE GAUGE" || location === "ENLARGED AREA SIDE OF BARREL" || location === "SILICONE BAND" || location === "CENTERED ON FRONT POCKET") {
          setLogoFront(data);
        } else if (location === "CENTERED ON BACK" || location === "CENTERED NEXT TO HANDLE - LEFT HANDED" || location === "BESIDE CLIP ( 2ND IMPRINTS LOCATION)" || location === "ENLARGED IMPRINT AREA OTHER SIDE OF BARREL") {
          setLogoBack(data);
        } else if (location === "CENTERED ON UPPER BODY" || location === "BESIDE CLIP" || location === "CENTERED ON BOTTLE NECK" || location === "CENTERED ON FRONT TOP") {
          setLogoFrontUpper(data);
        } else if (location === "CENTERED ON FRONT BOTTOM" || location === "IN LINE WITH CLIP" || location === "FRONT OF THE SILICONE BOOT") {
          setLogoFrontBottom(data);
        } else if (location === "ON CLIP") {
          setLogoClip(data);
        } else if (location === "CENTERED FRONT RIGHT POCKET" || location === "CENTERED ON RIGHT FLAP" || location === "CENTERED BELOW GRAB HANDLE") {
          setLogoFrontRight(data)
        }
        else if (location === "CENTERED FRONT LEFT POCKET" || location === "CENTERED BOTTOM RIGHT" || location === "CENTER ON SIDE POCKET" || location === "CENTERED ON SIDE POCKET") {

          setLogoFrontLeft(data)
        }
        else if (location === "CENTERED BETWEEN ANCHORS - FRONT" || location === "CENTERED FRONT POCKET" || location === "CENTERED ON STRAP STABILIZER" || location === "CENTERED ON FRONT BETWEEN STRAPS" || location === "CENTERED ON FRONT OF POUCH" || location === "CENTERED ON SHOULDER STRAP") {
          setLogoFrontAnchor(data)
        }
        else if (location === "CENTERED ON TROLLEY STRAP" || location === "CENTERED ON STRAP STABILIZER" || location === "CENTERED ABOVE POCKET" || location === "CENTERED ON BACK BETWEEN STRAP" || location === "CENTER FRONT BOTTOM POCKET") {
          setLogoFrontTrolley(data)
        }
        else if (location === "CENTERED ON CLOSING FLAP" || location === "CENTERED ON SHOULDER STRAP FLAP" || location === "CENTER FRONT TOP POCKET") {
          setLogoFrontFlap(data)
        }
        else {
          setLogoCap(data);
        }
      });
    });
  };

  useEffect(() => {
    initializeDesigner();
    loadPositions(options.finalOptions);
    renderBrandings(options.finalOptions);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, paddingTop: 2 }}>
      <Grid container spacing={1} sx={{ padding: 2 }}>
        <Grid item xs={8}>
          <Card
            sx={{
              minWidth: 275,
              height: "100%",
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 10,
            }}
          >
            <CardContent>
              <Grid container spacing={1} sx={{ padding: 2 }} alignItems="center">
                {
                  <div className="workspace-btn">
                    <Typography style={{ lineHeight: 2 }}>
                      <b>Workspace:</b> &nbsp;
                    </Typography>
                    {brandingOptions &&
                      brandingOptions.map((branding, index) => {
                        return (
                          <Button
                            className="location-btn"
                            style={{
                              backgroundColor: "#FFBF4F",
                              color: "black",
                              fontSize: "0.64rem",
                              marginRight: 10,
                              fontWeight: "bold",
                            }}
                            key={index}
                            onClick={() => {
                              changeLayer(index);
                              snapshotRef.current.changeRotation(branding.location);
                            }}
                          >
                            {sessionStorage.getItem("productCode") === "I150"
                              ? "IN LINE WITH WIRE GAUGE"
                              : sessionStorage.getItem("productCode") === "G1249" && branding.location === "CENTERED ON BACK"
                                ? "BESIDE CLIP 2ND LOC"
                                : branding.location}
                          </Button>
                        );
                      })}
                  </div>
                }
              </Grid>
              {brandingOptions.length > 0 &&
                brandingOptions.map((branding, i) => {
                  return (
                    <div className="roy-one" key={i}>
                      {branding.showBranding && (
                        <BrandDesigner
                          setLogoFront={setLogoFront}
                          setLogoBack={setLogoBack}
                          setLogoFrontUpper={setLogoFrontUpper}
                          setBrandingOptions={setBrandingOptions}
                          setLogoFrontBottom={setLogoFrontBottom}
                          setLogoClip={setLogoClip}
                          setLogoCap={setLogoCap}
                          setLogoFrontRight={setLogoFrontRight}
                          setLogoFrontLeft={setLogoFrontLeft}
                          setLogoFrontAnchor={setLogoFrontAnchor}
                          setLogoFrontTrolley={setLogoFrontTrolley}
                          setLogoFrontFlap={setLogoFrontFlap}
                          branding={branding}
                          index={i}
                        />
                      )}
                    </div>
                  );
                })}
              <>
                {brandingOptions.length > 0 &&
                  brandingOptions.map((branding, i) => {
                    return (
                      <div className="roy-two" key={i}>
                        <BrandDesigner
                          setLogoFront={setLogoFront}
                          setLogoBack={setLogoBack}
                          setLogoFrontUpper={setLogoFrontUpper}
                          setBrandingOptions={setBrandingOptions}
                          setLogoFrontBottom={setLogoFrontBottom}
                          setLogoClip={setLogoClip}
                          setLogoCap={setLogoCap}
                          setLogoFrontRight={setLogoFrontRight}
                          setLogoFrontLeft={setLogoFrontLeft}
                          setLogoFrontAnchor={setLogoFrontAnchor}
                          setLogoFrontTrolley={setLogoFrontTrolley}
                          setLogoFrontFlap={setLogoFrontFlap}
                          branding={branding}
                          index={i}
                        />
                      </div>
                    );
                  })}
              </>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="Share" placement="left" arrow>
            <Fab
              onClick={() => snapshotRef.current.shareProduct()}
              sx={{
                backgroundColor: "#ffbf4f",
                color: "white",
                "&:hover": { backgroundColor: "#ffbf4f", color: "black" },
                position: "absolute",
                zIndex: "99",
                right: "2.5%",
              }}
            >
              <ShareIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Save Snapshot" placement="left" arrow>
            <Fab
              onClick={() => snapshotRef.current.takeScreenshot()}
              sx={{
                backgroundColor: "#ffbf4f",
                color: "white",
                "&:hover": { backgroundColor: "#ffbf4f", color: "black" },
                position: "absolute",
                zIndex: "99",
                top: "100px",
                right: "2.5%",
              }}
            >
              <DownloadRoundedIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Reset View" placement="left" arrow>
            <Fab
              onClick={() => snapshotRef.current.resetView()}
              sx={{
                backgroundColor: "#ffbf4f",
                color: "white",
                "&:hover": { backgroundColor: "#ffbf4f", color: "black" },
                position: "absolute",
                zIndex: "99",
                top: "170px",
                right: "2.5%",
              }}
            >
              <FlipCameraAndroidIcon />
            </Fab>
          </Tooltip>


          <div id="canvas-area" style={{ height: "95vh" }} onMouseDown={() => setShowPrompt(false)}>
            <Canvas3D
              ref={snapshotRef}
              brandingProperties={brandingProperties}
              productCode={sessionStorage.getItem("productCode")}
              productColor={sessionStorage.getItem("productColor")}
              logoFront={logoFront}
              logoFrontUpper={logoFrontUpper}
              logoFrontBottom={logoFrontBottom}
              logoBack={logoBack}
              logoCap={logoCap}
              logoClip={logoClip}
              logoFrontRight={logoFrontRight}
              logoFrontLeft={logoFrontLeft}
              logoFrontAnchor={logoFrontAnchor}
              logoFrontTrolley={logoFrontTrolley}
              logoFrontFlap={logoFrontFlap}
            />
            {showPrompt !== false && (
              <Grid
                id="prompt-area"
                className="promp-area"
                onMouseDown={() => setShowPrompt(false)}
                sx={{
                  right: {
                    xl: "23%",
                    lg: "14%",
                  },
                  textAlign: "center",
                  color: "white",
                  pointerEvents: "none",
                  cursor: "move",
                  zIndex: 85,
                  position: "absolute",
                  top: "50%",
                }}
              >
                <SwipeLeftTwoToneIcon style={{ fontSize: "5rem" }} />
                <Typography>click & hold</Typography>
                <Typography>to rotate</Typography>
              </Grid>
            )}
          </div>


        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
