import React, { useEffect, useRef, useState, useContext } from "react";
import * as THREE from "three";
import EventEmitterContext from '../../../Utils/EventEmitterContext';


const LogoCenteredUpperBody = (props) => {
  const mesh = useRef();
  const texture = new THREE.TextureLoader().load(props.image);
  texture.repeat = new THREE.Vector2(1, 0.95);
  texture.center = new THREE.Vector2(1, 1);
  texture.encoding = THREE.sRGBEncoding;
  const [load, setLoad] = useState(false)
  const eventEmitter = useContext(EventEmitterContext);

  let process = props.process;
  let roughness = 1;
  let metalness = 0;
  let clearcoat = 0;
  let clearcoatRoughness = 0;
  let metalMap = null;
  let args = [0.9575, 0.9575, 2.35, 250, 250, true, -1, 2];



  useEffect(() => {
    const callback = () => {
      // Handle the event
      console.log('Event triggered! LOGO.JS FUCK YOU KA!');
      setLoad(true)
    };

    eventEmitter.on('fullyLoaded', callback);
    return () => eventEmitter.off('fullyLoaded')
  }, [])


  if (load) {
    if (process === "LASER ENGRAVED" || process === "REFLECTION LASER") {
      metalness = 1;
      roughness = 0.35;
      metalMap = new THREE.TextureLoader().load("brushed.png");
    } else if (process === "SILK SCREENED") {
      metalness = 0.75;
      roughness = 1;
    } else if (process === "4CP FLEX") {
      clearcoat = 0;
      clearcoatRoughness = 0;
    } else if (process === "4CP FLEX TRANSFER") {
      metalness = 0.90;
      roughness = 1;
    } else {
      metalness = 0.5;
      roughness = 0.275;
      clearcoat = 0.75;
      clearcoatRoughness = 0.075;
    }
  }
  if (load) {
    if (props.productCode === "DW409") {
      args = [0.995, 0.995, 2.35, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW313") {
      args = [0.835, 0.835, 2.35, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW319") {
      args = [0.985, 0.985, 2.35, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW316" || props.productCode === "DW317") {
      args = [0.8205, 0.8205, 1.85, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW311") {
      args = [0.83, 0.82, 0.7, 250, 250, true, -1.035, 1.2];
    } else if (props.productCode === "G3141") {
      args = [0.177, 0.177, 1, 250, 250, true, 0.75, 1.7];
    } else if (props.productCode === "G1298") {
      if (props.isRectangle === "vertical") {
        console.log('vertical')
        args = [0.179, 0.179, 1.7, 250, 250, true, 0.75, 2];
      } else if (props.isRectangle === "normal") {
        console.log('normal')
        args = [0.179, 0.179, 1.7, 250, 250, true, 0.75, 2];
      } else {
        console.log('else')
        args = [0.179, 0.179, 0.90, 250, 250, true, 0.75, 2];
      }

    }
    else if (props.productCode === "G1182") {
      args = [0.205, 0.205, 1.25, 250, 250, true, -0.85, 1.7];
    } else if (props.productCode === "G3113") {
      args = [0.205, 0.205, 1, 250, 250, true, -0.7, 1.5];
    } else if (props.productCode === "G3038") {
      args = [0.205, 0.205, 0.9, 250, 250, true, -0.65, 1.4];
    } else if (props.productCode === "G3108") {
      args = [0.205, 0.205, 0.9, 250, 250, true, -0.65, 1.4];
    } else if (props.productCode === "G1249") {
      args = [0.3, 0.3, 1.1, 250, 250, true, -0.6, 1.1];
    } else if (props.productCode === "EC122") {
      args = [0.205, 0.205, 1.1, 250, 250, true, -0.85, 1.7];
    } else if (props.productCode === "EC131") {
      args = [0.205, 0.205, 1.1, 250, 250, true, -0.85, 1.7];
    } else if (props.productCode === "HW204") {
      args = [0.35, 0.35, 1.1, 250, 250, true, -0.45, 1];
    } else if (props.productCode === "G1103") {
      args = [0.2375, 0.24975, 1.1, 250, 250, true, -0.85, 1.7];
    } else if (props.productCode === "G3148") {
      args = [0.211, 0.211, 1, 250, 250, true, 0.75, 1.7];
    } else if (props.productCode === "EC146") {
      args = [0.1925, 0.1925, 1.15, 250, 250, true, 0.7, 1.7];
    } else if (props.productCode === "I154") {
      args = [0.175, 0.175, 1.1, 250, 250, true, 0.7, 1.7];
    } else if (props.productCode === "I153") {
      args = [0.2125, 0.2125, 1.1, 250, 250, true, -0.85, 1.7];
    }
    else if (props.productCode === "I160") {
      args = [0.2125, 0.2125, 1.1, 250, 250, true, -0.85, 1.7];
    }
    else if (props.productCode === "I116") {
      args = [0.205, 0.205, 0.9, 250, 250, true, -0.7, 1.5];
    } else if (props.productCode === "I136") {
      args = [0.205, 0.205, 0.9, 250, 250, true, -0.7, 1.5];
    } else if (props.productCode === "I138") {
      args = [0.205, 0.205, 1.1, 250, 250, true, -0.8, 1.6];
    } else if (props.productCode === "I128" || props.productCode === "I228") {
      args = [0.205, 0.205, 1, 250, 250, true, -0.75, 1.6];
    }
  }

  useEffect(() => {
    if (load) {
      if (props.productCode === "DW316") {
        mesh.current.rotation.x = -0.03;
      }
      else if (props.productCode === "DW311") {
        mesh.current.rotation.x = 0.01;
        mesh.current.rotation.y = 0.42;
      }
      else if (props.productCode === "I153") {
        mesh.current.rotation.x = 0
        mesh.current.rotation.y = 0
        mesh.current.rotation.z = 0
      }
      else if (props.productCode === "I160") {
        mesh.current.rotation.x = 0
        mesh.current.rotation.y = 1.49
        mesh.current.rotation.z = 0
      }
    }
  }, [load]);

  return load ? (
    <mesh {...props} ref={mesh}>
      <cylinderBufferGeometry attach="geometry" args={args} />
      <meshPhysicalMaterial
        transparent
        side={THREE.FrontSide}
        roughness={roughness}
        clearcoatRoughness={clearcoatRoughness}
        clearcoat={clearcoat}
        metalness={metalness}
        // normalMap={texture}
        metalnessMap={metalMap}
      >
        <primitive attach="map" object={texture} />
      </meshPhysicalMaterial>
    </mesh>
  ) : null;
};

export default LogoCenteredUpperBody;
