import { useEffect } from "react";

export function useDebounceEffect(effect, timeout, deps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      effect();
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [deps, effect, timeout]);
}