import { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  // const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = crop.width * scaleX
  canvas.height = crop.height * scaleY

  ctx.imageSmoothingQuality = 'high'

  if (image.width > (image.height * 1.25)) {
    sessionStorage.setItem("rectangleType", "normal")
  } else if (image.height > (image.width * 1.25)) {
    sessionStorage.setItem("rectangleType", "vertical")
  } else {
    sessionStorage.setItem("rectangleType", "not")
  }

  // const cropX = crop.x * scaleX
  // const cropY = crop.y * scaleY

  // const rotateRads = rotate * TO_RADIANS
  // const centerX = image.width / 2
  // const centerY = image.height / 2

  ctx.save()

  ctx.setTransform(scale, 0, 0, scale, canvas.width / 2, canvas.height / 2)

  ctx.drawImage(image, -image.naturalWidth / 2, -image.naturalHeight / 2)
  ctx.restore()

  return canvas.toDataURL('image/png')
}
