import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import * as THREE from "three";
import { useLoader, useFrame } from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import EventEmitterContext from '../../Utils/EventEmitterContext';


const Product = (props) => {
  let productCode = "DW305";
  let productColor = "WhitePearl";
  let scale = 0.255;
  let position = [0, -3.05, 0];
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const eventEmitter = useContext(EventEmitterContext);



  const fbxRef = useRef();
  function convertString(str) {
    if (str.includes("/")) {
      const parts = str.split("/");
      const convertedParts = parts.map((part) => {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      });
      const result = convertedParts.join("");
      return result;
    } else if (str.includes(" ")) {
      const words = str.split(" ");
      const convertedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
      const result = convertedWords.join("");
      return result;
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
  }

  const manager = new THREE.LoadingManager();
  const textureLoader = new THREE.TextureLoader(manager);

  if (props.productCode) {
    productCode = props.productCode;
  }
  if (props.productColor) {
    let converted = convertString(props.productColor);
    productColor = converted;
  }


  if (productCode === "DW409") {
    position = [0, -1.75, 0];
  } else if (productCode === "DW402") {
    position = [0, -2.25, 0];
  } else if (productCode === "DW312") {
    position = [0, -2.25, 0];
  } else if (productCode === "DW321") {
    scale = 0.225;
    position = [0, -0.11, 0];
  } else if (productCode === "DW322") {
    scale = 0.0925;
    position = [0, -2.9, 0];
  } else if (productCode === "DW323") {
    scale = 0.08;
    position = [0, -3.1, 0];
  } else if (productCode === "DW415") {
    scale = 0.225;
    position = [-1.68, -2.56, -0.37];
  } else if (productCode === "DW416") {
    scale = 0.2;
    position = [0, -1, 0];
  } else if (productCode === "DW403") {
    position = [0, -1.25, 0];
  } else if (productCode === "DW404" || productCode === "DW413") {
    position = [0, -1.25, 0];
  } else if (productCode === "DW406" || productCode === "DW411") {
    position = [0, -1.25, 0];
  } else if (productCode === "DW407") {
    position = [0, -1.25, 0];
  } else if (productCode === "DW408") {
    position = [0, -1.25, 0];
  } else if (
    productCode === "DW410" ||
    productCode === "DW412" ||
    productCode === "DW604" ||
    productCode === "DW603"
  ) {
    position = [0, -2.25, 0];
  } else if (productCode === "DW304") {
    position = [0, -2, 0];
  } else if (productCode === "EC146") {
    position = [0, -2, 0];
    scale = 0.35;
  } else if (productCode === "EC117") {
    position = [0, -0.2, 0];
    scale = 0.4;
  } else if (productCode === "I154") {
    position = [0, -0.25, 0];
    scale = 0.35;
  } else if (productCode === "G3141" || productCode === "G3148") {
    scale = 0.4;
    position = [0, -2.5, 0];

  } else if (productCode === "G1282" || productCode === "G1249") {
    position = [0, -2.75, 0];
    scale = 0.35;
  } else if (
    productCode === "G1005" ||
    productCode === "G1009" ||
    productCode === "G1103" ||
    productCode === "G1247" ||
    productCode === "G1254" ||
    productCode === "G3001"
  ) {
    position = [0, -2.5, 0];
    scale = 0.4;
  } else if (productCode === "G1182") {
    position = [0, 0, 0];
    scale = 0.4;
  } else if (productCode === "G3038") {
    position = [0, 0, 0];
    scale = 0.4;
  } else if (
    productCode === "G3108" ||
    productCode === "G3113" ||
    productCode === "HW204"
  ) {
    position = [0, -2.5, 0];
    scale = 0.4;
  } else if (productCode === "HW205C") {
    position = [0, -2.5, 0];
    scale = 0.35;
  } else if (productCode === "BG504") {
    position = [0, -1.5, 0];
    scale = 0.1;
  } else if (productCode === "BG700") {
    scale = 0.16;
    position = [0.13, -0.75, -0.09];
  } else if (productCode === "EC122") {
    position = [0, 0, 0];
    scale = 0.4;
  } else if (
    productCode === "EC131" ||
    productCode === "I153" ||
    productCode === "I116" ||
    productCode === "I136" ||
    productCode === "I151" ||
    productCode === "I150"
  ) {
    position = [0, -2.5, 0];
    scale = 0.4;
  } else if (
    productCode === "I138" ||
    productCode === "I128" ||
    productCode === "I228"
  ) {
    position = [0, 0, 0];
    scale = 0.4;
  }
  else if (productCode === "I160") {
    scale = 0.30;
    position = [0, -2.10, 0];
  }
  else if (productCode === "DW104") {
    scale = 0.2;
  } else if (productCode === "DW201") {
    scale = 0.2;
    position = [1.59, -2.5, -0.5];
  } else if (productCode === "DW324") {
    scale = 0.18;
  } else if (productCode === "DW325") {
    scale = 0.207;
    position = [-2.33, -2.95, 0.58];
  } else if (productCode === "DW326") {
    scale = 0.225;
  } else if (productCode === "DW405") {
    scale = 0.23;
    position = [-1.0, -0.4, 1.2];
  } else if (productCode === "DW417") {
    position = [0, -0.3, 0];
  } else if (productCode === "DW500") {
    position = [0, -0.7, 0];
  } else if (productCode === "DW502") {
    position = [0, -0.7, 0];
  } else if (productCode === "DW305") {
    position = [0.0, -3.1, 0.0];
  }

  if (
    productCode === "DW313" ||
    productCode === "DW316" ||
    productCode === "DW317" ||
    productCode === "DW314" ||
    productCode === "DW309" ||
    productCode === "DW308" ||
    productCode === "DW103" ||
    productCode === "DW311"
  ) {
    scale = 0.21;
  } else if (productCode === "DW310" || productCode === "DW101") {
    scale = 0.25;
  } else if (productCode === "DW603" || productCode === "DW604") {
    scale = 0.325;
  } else if (productCode === "DW305") {
    scale = 0.24;
  }
  else if (productCode === "BG500") {
    scale = 0.10
    position = [-0.19, -2.43, -0.94];
  }
  else if (productCode === "BGR710") {
    scale = 0.09
    position = [-0.19, 0, -0.94];
  }
  else if (productCode === "BG201") {
    scale = 0.05
    position = [-0.19, -0.53, 0.70];
  }
  else if (productCode === "BGR104") {
    scale = 0.07
    position = [-0.19, -1.5, 0.74];
  }
  else if (productCode === "BG104") {
    scale = 0.07
    position = [-0.19, -1.5, 0.74];
  }
  else if (productCode === "BG102") {
    scale = 0.09
    position = [-0.19, -1.50, 0.74];
  }
  else if (productCode === "BG206") {
    scale = 0.08
    position = [-0.15, -1, -0.13];
  }
  else if (productCode === "BGR204") {
    scale = 0.08
    position = [-0.15, -1.18, 0];
  }
  else if (productCode === "BG116") {
    scale = 0.08
    position = [-0.15, -1.65, 0];
  }
  else if (productCode === "BG204") {
    scale = 0.08
    position = [-0.15, -1.18, 0];
  }
  else if (productCode === "BG600") {
    scale = 0.09
    position = [0.13, -1.50, 0.69];
  }
  else if (productCode === "BG706") {
    scale = 0.17
    position = [-0.25, -1.18, -0.30];
  }
  else if (productCode === "BGR101") {
    scale = 0.08
    position = [-0.23, -1.30, 0.60];
  }
  else if (productCode === "BGR102") {
    scale = 0.08
    position = [-0.23, -1.30, 0.60];
  }
  else if (productCode === "BG400") {
    scale = 0.1
    position = [-0.15, -1, -0.13];
  }
  else if (productCode === "G1298") {
    scale = 0.36;
    position = [0, -2.5, 0];
  }
  else if (productCode === "BGR103") {
    scale = 0.07
    position = [-0.23, -0.95, 1.00];
  }
  else if (productCode === "BGR122") {
    scale = 0.07
    position = [-0.23, -0.95, 1.00];
  }
  else if (productCode === "BGR210") {
    scale = 0.07
    position = [-0.23, -0.95, 0.90];
  }


  useEffect(() => {

    manager.onProgress = function (item, loaded, total) {
      // console.log('Loading progress:', item, loaded, total);
      if (loaded === total) {
        eventEmitter.trigger('fullyLoaded');
        eventEmitter.trigger('fullyModel')
      }
    };
    // const closeModal = () => {
    //   Swal.close()
    // }
    const loadingSetter = () => {
      setIsLoadingComplete(true)
      // closeModal()
      return eventEmitter.off('fullyLoaded')
    }

    eventEmitter.on('fullyLoaded', loadingSetter)
  }, [])



  const show = JSON.parse(sessionStorage.getItem('showStrap'))
  // const showNew = JSON.parse(sessionStorage.getItem('showNew'))
  let hasReloaded = false;
  const fbx = useLoader(FBXLoader, `/${productCode}${show ? "_strap_up" : ""}.fbx`);

  const copiedScene = useMemo(() => fbx.clone(), [fbx]);
  const allowedURLs = [
    "http://localhost:3000/",
    "http://40.113.34.8:3000/",
    "https://decdoutmerch.com/",
  ];

  if (fbx.name === "closed" && allowedURLs.includes(window.location.href)) {
    copiedScene.rotateY(2.9);
  }
  fbx.children.forEach((mesh, i) => {
    if (mesh.name.includes("Eva_Foam")) {
      if (
        productCode === "DW302" ||
        productCode === "DW305" ||
        productCode === "DW406" ||
        productCode === "DW407" ||
        productCode === "DW306" ||
        productCode === "DW403" ||
        productCode === "DW402" ||
        productCode === "DW309" ||
        productCode === "DW310" ||
        productCode === "DW301" ||
        productCode === "DW308" ||
        productCode === "DW401"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_Eva_foam_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_Eva_foam_mat_Normal.png"
          ),

          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_Eva_foam_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_Eva_foam_mat_Roughness.png"
          ),
        });
      }
    } else if (productCode === "DW321") {
      mesh.children.forEach((mat, i) => {
        if (
          mat.name === "Bottom_low" ||
          mat.name === "Body_low" ||
          mat.name === "Body_metal_low" ||
          mat.name === "Lid_holder_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
          });

          if (productColor === "Black")
            mat.material.color = new THREE.Color(0x2a2c2b);
          if (productColor === "Green")
            mat.material.color = new THREE.Color(0x3a3e33);
          if (productColor === "Grey")
            mat.material.color = new THREE.Color(0x66696a);
        }

        if (
          mat.name === "Rubber2_low" ||
          mat.name === "Rubber1_low" ||
          mat.name === "Rubber_holder_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_rubber_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_rubber_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_rubber_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_rubber_mat_Roughness.png"
            ),
          });
        }

        if (mat.name === "Lid_low" || mat.name === "Inner_lid_helix_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Roughness.png"
            ),
          });
        }
      });
    }
    // else if (productCode === "BG116") {
    //   if (productColor === "Black") {
    //     if (
    //       mesh.name.includes("straps_low") ||
    //       mesh.name.includes("topouterbackmid_low") ||
    //       mesh.name.includes("topsides_low") ||
    //       mesh.name.includes("front_strap_low") ||
    //       mesh.name.includes("mouthtrims_low") ||
    //       mesh.name.includes("zipperhandle_low") ||
    //       mesh.name.includes("zipfront_low")
    //     ) {
    //       mesh.material = new THREE.MeshStandardMaterial({
    //         map: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_BaseColor.png"
    //         ),
    //         metalnessMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Metallic.png"
    //         ),
    //         normalMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Normal.png"
    //         ),
    //         roughnessMap: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_Roughness.png"
    //         ),
    //         color: new THREE.Color(0x2D2A2D),
    //         roughness: 1,
    //       });
    //     }
    //     else if (
    //       mesh.name.includes("frontright_low") ||
    //       mesh.name.includes("frontleft_low") ||
    //       mesh.name.includes("sidepocket_low") ||
    //       mesh.name.includes("sidecut_low") ||
    //       mesh.name.includes("back_low") ||
    //       mesh.name.includes("bottomwalls_low") ||
    //       mesh.name.includes("bottom_low")
    //     ) {
    //       mesh.material = new THREE.MeshStandardMaterial({
    //         map: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_BaseColor.png"
    //         ),
    //         metalnessMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Metallic.png"
    //         ),
    //         normalMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Normal.png"
    //         ),
    //         roughnessMap: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_Roughness.png"
    //         ),
    //         color: new THREE.Color(0x8c8c94),
    //         roughness: 1,
    //       });
    //     }
    //     if (mesh.name.includes("shoulderstrapsliner_low") ||
    //       mesh.name.includes("tophandle_low") ||
    //       mesh.name.includes("backwall_low") ||
    //       mesh.name.includes("pads_low") ||
    //       mesh.name.includes("backrings_low") ||
    //       mesh.name.includes("sidezip_low") ||
    //       mesh.name.includes("shoulderstrapsouter_low") ||
    //       mesh.name.includes("triangles_low") ||
    //       mesh.name.includes("buckle_low") ||
    //       mesh.name.includes("openerhandle_low") ||
    //       mesh.name.includes("bottleopener_low")
    //     ) {
    //       mesh.material = new THREE.MeshStandardMaterial({
    //         map: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_BaseColor.png"
    //         ),
    //         metalnessMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Metallic.png"
    //         ),
    //         normalMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Normal.png"
    //         ),
    //         roughnessMap: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_Roughness.png"
    //         ),
    //         color: new THREE.Color(0x262626),
    //         roughness: 1,
    //       });
    //     }
    //   }
    //   else if (sessionStorage.getItem("productColor") === "GREY" && !hasReloaded) {
    //     console.log('HAHAHA')
    //     if (
    //       mesh.name.includes("straps_low") ||
    //       mesh.name.includes("topouterbackmid_low") ||
    //       mesh.name.includes("topsides_low") ||
    //       mesh.name.includes("front_strap_low") ||
    //       mesh.name.includes("mouthtrims_low") ||
    //       mesh.name.includes("shoulderstrapsliner_low") ||
    //       mesh.name.includes("tophandle_low") ||
    //       mesh.name.includes("backwall_low") ||
    //       mesh.name.includes("shoulderstrapsouter_low") ||
    //       mesh.name.includes("zipperhandle_low") ||
    //       mesh.name.includes("sidezip_low") ||
    //       mesh.name.includes("zipfront_low") ||
    //       mesh.name.includes("bottleopener_low") ||
    //       mesh.name.includes("openerhandle_low") ||
    //       mesh.name.includes("buckle_low") ||
    //       mesh.name.includes("pads_low")
    //     ) {
    //       mesh.material = new THREE.MeshStandardMaterial({
    //         map: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_BaseColor.png"
    //         ),
    //         metalnessMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Metallic.png"
    //         ),
    //         normalMap: textureLoader.load(
    //           productCode + "_" + productColor + "_mat_Normal.png"
    //         ),
    //         roughnessMap: textureLoader.load(
    //           productCode +
    //           "_" +
    //           productColor +
    //           "_mat_Roughness.png"
    //         ),
    //         color: new THREE.Color(0x2D2A2D),
    //         roughness: 1,
    //       });

    //     }
    //     hasReloaded = true
    //   }

    //   if (!hasReloaded) {
    //     // Reload the page if the flag is still false
    //     window.location.reload();
    //   }
    //   if (mesh.name.includes("bag_buckle_a_low")) {
    //     mesh.material = new THREE.MeshPhysicalMaterial({
    //       map: textureLoader.load(
    //         productCode +
    //         "_" +
    //         productColor +
    //         "_mat_BaseColor.png"
    //       ),
    //       metalnessMap: textureLoader.load(
    //         productCode + "_" + productColor + "_mat_Metallic.png"
    //       ),
    //       normalMap: textureLoader.load(
    //         productCode + "_" + productColor + "_mat_Normal.png"
    //       ),
    //       roughnessMap: textureLoader.load(
    //         productCode +
    //         "_" +
    //         productColor +
    //         "_mat_Roughness.png"
    //       ),
    //       metalness: 1,
    //       color: new THREE.Color(0xFFFFFF),
    //       roughness: 0.1,
    //       clearcoat: 1,
    //       clearcoatRoughness: 0,

    //     });
    //   }
    // }
    else if (productCode === "BG116") {
      // console.log(mesh, 'BG116')
      if (
        mesh.name.includes("straps_low") ||
        mesh.name.includes("topouterbackmid_low") ||
        mesh.name.includes("topsides_low") ||
        mesh.name.includes("front_strap_low") ||
        mesh.name.includes("mouthtrims_low") ||
        mesh.name.includes("shoulderstrapsliner_low") ||
        mesh.name.includes("tophandle_low") ||
        mesh.name.includes("backwall_low") ||
        mesh.name.includes("shoulderstrapsouter_low") ||
        mesh.name.includes("zipperhandle_low") ||
        mesh.name.includes("sidezip_low") ||
        mesh.name.includes("zipfront_low") ||
        mesh.name.includes("bottleopener_low") ||
        mesh.name.includes("openerhandle_low") ||
        mesh.name.includes("buckle_low") ||
        mesh.name.includes("pads_low") ||
        mesh.name.includes("backrings_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          color: new THREE.Color(0x2D2A2D),
          roughness: 1,
        });
        if (productColor === "Grey" && mesh.name.includes("backrings_low")) {
          console.log('WEW')
          mesh.material.color = new THREE.Color(0x9E9FA4)
        }
      }
      if (
        mesh.name.includes("frontright_low") ||
        mesh.name.includes("frontleft_low") ||
        mesh.name.includes("sidepocket_low") ||
        mesh.name.includes("sidecut_low") ||
        mesh.name.includes("back_low") ||
        mesh.name.includes("bottomwalls_low") ||
        mesh.name.includes("bottom_low") ||
        mesh.name.includes("triangles_low") ||
        mesh.name.includes("shoulderstrapsinner_low") ||
        mesh.name.includes("topouterfrontmid_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          roughness: 1,
        });
        if (productColor === "Black") {
          mesh.material.color = new THREE.Color(0x8C8C94)
        }
        else {
          mesh.material.color = new THREE.Color(0x9E9FA4)
          // mesh.material.color = new THREE.Color(0x7C7E7D)

        }
      }
      if (mesh.name.includes("bag_buckle_a_low")) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,
        });
      }

    }
    // else if (productCode === "BG116") {
    //   mesh.material = new THREE.MeshStandardMaterial({
    //     map: textureLoader.load(
    //       productCode +
    //       "_" +
    //       productColor +
    //       "_mat_BaseColor.png"
    //     ),
    //     metalnessMap: textureLoader.load(
    //       productCode + "_" + productColor + "_mat_Metallic.png"
    //     ),
    //     normalMap: textureLoader.load(
    //       productCode + "_" + productColor + "_mat_Normal.png"
    //     ),
    //     roughnessMap: textureLoader.load(
    //       productCode +
    //       "_" +
    //       productColor +
    //       "_mat_Roughness.png"
    //     ),
    //     roughness: 1,
    //   });
    //   // if (productColor === "Grey" && mesh.name.includes("backrings_low")) {
    //   //   console.log('first')
    //   //   mesh.material.color = new THREE.Color(0x9E9FA4)
    //   // }
    // }
    else if (productCode === "BG500") {
      if (
        mesh.name.includes("back_holder_low") ||
        mesh.name.includes("handles_low") ||
        mesh.name.includes("trim_pocket_low") ||
        mesh.name.includes("buckle_harness_low") ||
        mesh.name.includes("strap_harness_low") ||
        mesh.name.includes("strap_shoulder_low") ||
        mesh.name.includes("trim_top_low") ||
        mesh.name.includes("zipper_low") ||
        mesh.name.includes("D_ring_L_low") ||
        mesh.name.includes("D_ring_R_low") ||
        mesh.name.includes("zipper_head_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          aoMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_AO.png"
          ),
          displacementMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Height.png"
          ),
          displacementScale: 0.1,
          roughness: 0.552786,
        });
      }

      if (mesh.name.includes("front_low") || mesh.name.includes("back_low")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          color: new THREE.Color(0x292929),
          roughness: 1
          // aoMap: textureLoader.load(
          //   productCode + "_" + productColor + "_mat_AO.png"
          // ),

        });
      }

      if (mesh.name.includes("bottom_low")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          color: new THREE.Color(0x232323),
          roughness: 0.5,
          // aoMap: textureLoader.load(
          //   productCode + "_" + productColor + "_mat_AO.png"
          // ),

        });
      }
      if (mesh.type === "Group") {
        mesh.children.map((child) => {
          // console.log(child, 'mismong child')
          child.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            aoMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_AO.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            roughness: 0.552786,

          });
        })
      }
    }
    else if (productCode === "BGR710") {
      if (mesh.name.includes("bag_folio_hook_low") ||
        mesh.name.includes("bag_folio_polySurface1_low") ||
        mesh.name.includes("bag_folio_polySurface2_low") ||
        mesh.name.includes("bag_folio_polySurface3_low") ||
        mesh.name.includes("bag_folio_polySurface4_low") ||
        mesh.name.includes("bag_folio_polySurface5_low") ||
        mesh.name.includes("bag_folio_polySurface6_low") ||
        mesh.name.includes("bag_folio_polySurface7_low") ||
        mesh.name.includes("bag_folio_polySurface8_low") ||
        mesh.name.includes("bag_folio_polySurface9_low") ||
        mesh.name.includes("bag_folio_polySurface10_low") ||
        mesh.name.includes("bag_folio_polySurface11_low") ||
        mesh.name.includes("bag_folio_polySurface12_low") ||
        mesh.name.includes("bag_folio_polySurface13_low") ||
        mesh.name.includes("bag_folio_ring_buckle_low") ||
        mesh.name.includes("bag_folio_polySurface14_low")

      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          color: new THREE.Color(0x232323),
          roughness: 0.5,

        });
      }
      if (
        mesh.name.includes("bag_folio_slide_buckle_low") ||
        mesh.name.includes("bag_folio_zipper_low")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          color: new THREE.Color(0x232323),
          roughness: 0.5,
        });
      }
    }
    else if (productCode === "BG201") {
      if (mesh.name.includes("BG201_body01_low") ||
        mesh.name.includes("BG201_body02_low") ||
        mesh.name.includes("BG201_body03_low") ||
        mesh.name.includes("BG201_body04_low") ||
        mesh.name.includes("BG201_body05_low") ||
        mesh.name.includes("BG201_body06_low") ||
        mesh.name.includes("BG201_body07_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),

          roughness: 1,
        });
        if (productColor === "Black") {
          mesh.material.color = new THREE.Color(0x232323)
        } else if (productColor === "Grey") {
          mesh.material.color = new THREE.Color(0xC5C6CD)
        }
        else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x605C50)
        }
      }
      else if (mesh.name.includes("BG201_buckle_low") ||
        mesh.name.includes("BG201_carabiner_low") ||
        mesh.name.includes("BG201_D_ring_low") ||
        mesh.name.includes("BG201_handle01_low") ||
        mesh.name.includes("BG201_handle02_low") ||
        mesh.name.includes("BG201_shoulder01_low") ||
        mesh.name.includes("BG201_shoulder02_low") ||
        mesh.name.includes("BG201_shoulder03_low") ||
        mesh.name.includes("BG201_strap01_low") ||
        mesh.name.includes("BG201_strap02_low") ||
        mesh.name.includes("BG201_strap03_low") ||
        mesh.name.includes("BG201_strap04_low") ||
        mesh.name.includes("BG201_strap05_low") ||
        mesh.name.includes("BG201_strap06_low") ||
        mesh.name.includes("BG201_strap_ring_low") ||
        mesh.name.includes("BG201_zipliner_low") ||
        mesh.name.includes("BG201_zipper_w_thumbgrip_low") ||
        mesh.name.includes("BG201_zippercover_low") ||
        mesh.name.includes("BG201_zippersupport_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_acc_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_acc_mat_Roughness.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_acc_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_acc_mat_Metallic.png"
          ),
          color: new THREE.Color(0x232323),
          roughness: 1,
        });

      }

      if (mesh.name.includes("group1")) {
        mesh.children.forEach((strap) => {
          // console.log(strap, 'STRAP MESHES')
          strap.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_acc_mat_BaseColor.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_acc_mat_Roughness.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_acc_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_acc_mat_Metallic.png"
            ),
            color: new THREE.Color(0x232323),
            roughness: 1,
          });
        })
      }
    }
    else if (productCode === "BG504") {
      if (mesh.name.includes("bg204_1_low") ||
        mesh.name.includes("bg204_2_low") ||
        mesh.name.includes("bg204_3_low") ||
        mesh.name.includes("bg204_4_low") ||
        mesh.name.includes("bg204_5_low") ||
        mesh.name.includes("bg204_6_low") ||
        mesh.name.includes("bg204_7_low") ||
        mesh.name.includes("bg204_8_low") ||
        mesh.name.includes("bg204_9_low") ||
        mesh.name.includes("bg204_10_low") ||
        mesh.name.includes("bg204_11_low") ||
        mesh.name.includes("bg204_12_low") ||
        mesh.name.includes("bg204_13_low") ||
        mesh.name.includes("bg204_14_low") ||
        mesh.name.includes("bg204_15_low") ||
        mesh.name.includes("bg204_16_low") ||
        mesh.name.includes("bg204_17_low") ||
        mesh.name.includes("bg204_18_low") ||
        mesh.name.includes("bg204_19_low") ||
        mesh.name.includes("buckle1_1_low") ||
        mesh.name.includes("buckle1_2_low") ||
        mesh.name.includes("buckle2_low") ||
        mesh.name.includes("zipper_grip_low") ||
        mesh.name.includes("zipper_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          roughness: 0.85,
        });
        if (productColor === "Black") {
          mesh.material.color = new THREE.Color(0x232323)
        }
        else if (productColor === "Grey") {
          mesh.material.color = new THREE.Color(0xCBC8CF)
          if (mesh.name.includes("bg204_10_low") ||
            mesh.name.includes("bg204_15_low") ||
            mesh.name.includes("bg204_6_low") ||
            mesh.name.includes("bg204_7_low") ||
            mesh.name.includes("bg204_8_low") ||
            mesh.name.includes("bg204_18_low") ||
            mesh.name.includes("bg204_17_low") ||
            mesh.name.includes("bg204_14_low") ||
            mesh.name.includes("bg204_5_low") ||
            mesh.name.includes("bg204_16_low") ||
            mesh.name.includes("bg204_12_low") ||
            mesh.name.includes("buckle1_1_low") ||
            mesh.name.includes("buckle1_2_low") ||
            mesh.name.includes("bg204_11_low") ||
            mesh.name.includes("bg204_9_low")
          ) {
            mesh.material.color = new THREE.Color(0x232323)
          }
        }
      }
      if (mesh.name.includes("strap_clip1_low") || mesh.name.includes("strap_clip2_low")) {
        mesh.children.forEach((child, i) => {
          child.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),

            roughness: 0.85,
          });
          if (productColor === "Black") {
            child.material.color = new THREE.Color(0x232323)
          }
          else if (productColor === "Grey") {
            child.material.color = new THREE.Color(0x232323)
            if (mesh.name.includes("bg204_10_low") ||
              mesh.name.includes("bg204_15_low") ||
              mesh.name.includes("bg204_6_low") ||
              mesh.name.includes("bg204_7_low") ||
              mesh.name.includes("bg204_8_low") ||
              mesh.name.includes("bg204_18_low") ||
              mesh.name.includes("bg204_17_low")
            ) {
              mesh.material.color = new THREE.Color(0x232323)
            }
          }
        })
      }
    }
    else if (productCode === "BGR104") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode +
          "_" +
          productColor +
          "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode +
          "_" +
          productColor +
          "_mat_Roughness.png"
        ),
        roughness: 1,

      });

      if (productColor === "Black") {
        mesh.material.color = new THREE.Color(0x232323)
        if (mesh.name.includes("buckle104_main_low") || mesh.name.includes("buckle104_button_low")
        ) {
          mesh.material = new THREE.MeshPhysicalMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_Roughness.png"
            ),
            metalness: 1,
            color: new THREE.Color(0xFFFFFF),
            roughness: 0.1,
            clearcoat: 1,
            clearcoatRoughness: 0,

          });
        }
      }
      else if (productColor === "NavyBlue") {
        mesh.material.color = new THREE.Color(0x13294B)
        if (mesh.name.includes("buckle104_main_low") || mesh.name.includes("buckle104_button_low")
        ) {
          mesh.material = new THREE.MeshPhysicalMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_Roughness.png"
            ),
            metalness: 1,
            color: new THREE.Color(0xFFFFFF),
            roughness: 0.1,
            clearcoat: 1,
            clearcoatRoughness: 0,

          });
        }
        if (mesh.name.includes("strap_front_top_low") ||
          mesh.name.includes("straps_front_bot_low") ||
          mesh.name.includes("pockets_side2_low") ||
          mesh.name.includes("straps_low") ||
          mesh.name.includes("shoulderL_low") ||
          mesh.name.includes("shoulderR_low") ||
          mesh.name.includes("buckle1_low") ||
          mesh.name.includes("buckle2_low") ||
          mesh.name.includes("buckle104_strap_low") ||
          mesh.name.includes("handle_low")
        ) {
          mesh.material.color = new THREE.Color(0x232323)
        }
      }
      else {
        mesh.material.color = new THREE.Color(0x9D1A28)
        if (mesh.name.includes("strap_front_top_low") ||
          mesh.name.includes("straps_front_bot_low") ||
          mesh.name.includes("pockets_side2_low") ||
          mesh.name.includes("straps_low") ||
          mesh.name.includes("shoulderL_low") ||
          mesh.name.includes("shoulderR_low") ||
          mesh.name.includes("buckle1_low") ||
          mesh.name.includes("buckle2_low") ||
          mesh.name.includes("buckle104_strap_low") ||
          mesh.name.includes("handle_low")
        ) {
          mesh.material.color = new THREE.Color(0x232323)
        }
        if (mesh.name.includes("buckle104_main_low") || mesh.name.includes("buckle104_button_low")
        ) {
          mesh.material = new THREE.MeshPhysicalMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_Roughness.png"
            ),
            metalness: 1,
            color: new THREE.Color(0xFFFFFF),
            roughness: 0.1,
            clearcoat: 1,
            clearcoatRoughness: 0,

          });
        }
      }

    }
    else if (productCode === "BG104") {
      if (
        mesh.name.includes("bg104_final2body_back_low") ||
        mesh.name.includes("bg104_final2body_front_low") ||
        mesh.name.includes("bg104_final2bottom_low") ||
        mesh.name.includes("bg104_final2buckle1_low") ||
        mesh.name.includes("bg104_final2buckle2_low") ||
        mesh.name.includes("bg104_final2buckle104_button_low") ||
        mesh.name.includes("bg104_final2buckle104_main_low") ||
        mesh.name.includes("bg104_final2buckle104_strap_low") ||
        mesh.name.includes("bg104_final2front_tape_low") ||
        mesh.name.includes("bg104_final2handle_low") ||
        mesh.name.includes("bg104_final2pockets_side_low") ||
        mesh.name.includes("bg104_final2pockt_front_low") ||
        mesh.name.includes("bg104_final2shoulder_low") ||
        mesh.name.includes("bg104_final2strap_front_top_low") ||
        mesh.name.includes("bg104_final2straps_front_bot_low") ||
        mesh.name.includes("bg104_final2straps_low") ||
        mesh.name.includes("bg104_final2tape_low") ||
        mesh.name.includes("bg104_final2top_cover_low") ||
        mesh.name.includes("bg104_final2triangles_low") ||
        mesh.name.includes("bg104_final2zipper_head_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          aoMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_AO.png"
          ),

          color: new THREE.Color(0x666666),
          aoMapIntensity: 0.1,
          roughness: 1
        });
      }
      if (
        mesh.name.includes("bg104_final2strap_front_top_low") ||
        mesh.name.includes("bg104_final2straps_front_bot_low") ||
        mesh.name.includes("bg104_final2straps_front_bot_low")
      ) {
        mesh.material.color = new THREE.Color(0x232323)
      }
      if (mesh.name.includes("bg104_final2buckle104_main_low")) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,

        });
      }

    }
    else if (productCode === "BG206") {
      if (
        mesh.name.includes("back_strap_low") ||
        mesh.name.includes("body_low") ||
        mesh.name.includes("bottom_cover_low") ||
        mesh.name.includes("bottom_low") ||
        mesh.name.includes("buttons_low") ||
        mesh.name.includes("Elasticloop_low") ||
        mesh.name.includes("fastener_low") ||
        mesh.name.includes("handle_back_low") ||
        mesh.name.includes("handle_front_low") ||
        mesh.name.includes("handlepad_back_low") ||
        mesh.name.includes("handlepad_front_low") ||
        mesh.name.includes("leftstrap_low") ||
        mesh.name.includes("strap_over_low") ||
        mesh.name.includes("strap_under_low") ||
        mesh.name.includes("topflap_low") ||
        mesh.name.includes("zipper_head_low") ||
        mesh.name.includes("zipper_pull_low") ||
        mesh.name.includes("zipper_ring_low") ||
        mesh.name.includes("zipperseam_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          aoMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_AO.png"
          ),
          color: new THREE.Color(0x666666),
          aoMapIntensity: 0.1,
          roughness: 1
        });
      }

      if (productColor === "Grey") {
        if (mesh.name.includes("handle_back_low") ||
          mesh.name.includes("handle_front_low") ||
          mesh.name.includes("handlepad_back_low") ||
          mesh.name.includes("handlepad_front_low") ||
          mesh.name.includes("strap_over_low") ||
          mesh.name.includes("bottom_cover_low") ||
          mesh.name.includes("zipper_pull_low") ||
          mesh.name.includes("leftstrap_low") ||
          mesh.name.includes("buttons_low")
        ) {
          mesh.material.color = new THREE.Color(0x232323)
        }

        if (mesh.name.includes("zipper_head_low")) {
          mesh.material = new THREE.MeshPhysicalMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_mat_Roughness.png"
            ),
            metalness: 1,
            color: new THREE.Color(0xFFFFFF),
            roughness: 0.1,
            clearcoat: 1,
            clearcoatRoughness: 0,
          });
        }
      }
    }
    else if (productCode === "BGR204") {
      if (mesh.name === "Body_low") {
        mesh.children.forEach((body, i) => {

          body.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_body_mtl_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mtl_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mtl_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_body_mtl_Roughness.png"
            ),
            aoMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_body_mtl_AO.png"
            ),
            // emissiveMap: textureLoader.load(
            //   productCode +
            //   "_" +
            //   productColor +
            //   "_body_mtl_Emissive.png"
            // ),
            aoMapIntensity: 0.1,
            roughness: 1
          });
          if (productColor === "Black") {
            body.material.color = new THREE.Color(0x232323)

          }
          else {
            body.material.color = new THREE.Color(0x13294B)
            if (body.name === "BGR204_SideHandle_low") {
              body.material.color = new THREE.Color(0x232323)
            }
            if (body.name.includes("BGR204_ZipperSeam_low") || body.name.includes("BGR204_SPzipperSeam_low")) {
              body.material.color = new THREE.Color(0x232323)
            }
          }
        })
      }
      else if (mesh.name === "Misc_low") {
        mesh.children.forEach((misc, i) => {

          misc.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_misc_mtl_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mtl_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mtl_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_misc_mtl_Roughness.png"
            ),
            aoMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_misc_mtl_AO.png"
            ),
            aoMapIntensity: 0,
            roughness: 1,


          });
          if (productColor === "Black") {
            misc.material.color = new THREE.Color(0x232323)
          }

          else if (productColor === "NavyBlue") {
            if (misc.name === "BGR204_SideZipper_low" || misc.name === "BGR204_ZipperPull01_low" || misc.name === "BGR204_ZipperPull02_low" || misc.name === "BGR204_ZipperSlider01_low" || misc.name === "BGR204_ZipperSlider02_low" || misc.name === "BGR204_TopZipper_low" ||
              misc.name === "BGR204_Straploop01_low" || misc.name === "BGR204_Straploop02_low" || misc.name === "BGR204_Straploop03_low" || misc.name === "BGR204_Straploop04_low" || misc.name === "BGR204_RightLoop_low" || misc.name === "BGR204_LeftLoop_low" || misc.name === "BGR204_SideStrap_low" || misc.name === "BGR204_ShoulderPad_B_low" || misc.name === "BGR204_ShoulderStrap_low" || misc.name === "BGR204_Hook01_low" || misc.name === "BGR204_Hook02_low" || misc.name === "BGR204_LeftSideloop_low" || misc.name === "BGR204_Rightideloop_low" || misc.name === "BGR204_RightLoop_low" || misc.name === "handle") {
              misc.material.color = new THREE.Color(0x232323)
            } else {
              misc.material.color = new THREE.Color(0x13294B)
            }

          }
        })
      }
    }
    else if (productCode === "BG204") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_baseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_roughness.png"
        ),
        color: new THREE.Color(0x666666),
        roughness: 1
      });
      if (
        mesh.name.includes("handle_rt_low") ||
        mesh.name.includes("handle_lf_low") ||
        mesh.name.includes("bodystraps_low") ||
        mesh.name.includes("carrying_strap_low") ||
        mesh.name.includes("strappad_inner2_low") ||
        mesh.name.includes("strap_pad_outer2_low") ||
        mesh.name.includes("carabiner_hooks_low") ||
        mesh.name.includes("sideloops_low") ||
        mesh.name.includes("loop_low") ||
        mesh.name.includes("side_handle_low")
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_baseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_roughness.png"
          ),
          color: new THREE.Color(0x232323),
          roughness: 1
        });
      }
    }
    else if (productCode === "BG600") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
        color: new THREE.Color(0x131313),
        aoMapIntensity: 0.1,
        roughness: 1
      });
      if (mesh.name.includes("back_low") ||
        mesh.name.includes("front_low") ||
        mesh.name.includes("mouth_low") ||
        mesh.name.includes("corner_pads_low")

      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          aoMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_AO.png"
          ),
          color: new THREE.Color(0x131313),
          aoMapIntensity: 0.1,
          roughness: 1,
          side: THREE.DoubleSide
        });
      }
    }
    else if (productCode === "BG700") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
        color: new THREE.Color(0x666666),
        aoMapIntensity: 0.1,
        roughness: 1
      });
    }
    else if (productCode === "BG706") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),

        aoMapIntensity: 0.1,
        roughness: 1
      });

      if (productColor === "Black") {
        mesh.material.color = new THREE.Color(0x232323)
      } else {
        mesh.material.color = new THREE.Color(0xABACB0)
      }
      if (mesh.name.includes("BG706_zipper1_low") || mesh.name.includes("BG706_zipper2_low")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_zipper_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_zipper_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_zipper_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_zipper_mat_Roughness.png"
          ),
          color: new THREE.Color(0x232323),
          roughness: 1
        });
      }
    }
    else if (productCode === "BGR101") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),

        color: new THREE.Color(0x131313),
        roughness: 1
      });

      if (mesh.name.includes("buckle104_main_low") ||
        mesh.name.includes("buckle104_button_low")
      ) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,
        });
      }

    }
    else if (productCode === "BGR102") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),

        color: new THREE.Color(0x131313),
        roughness: 1
      });

      if (mesh.name.includes("buckle104_main_low") ||
        mesh.name.includes("buckle104_button_low")
      ) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,
        });
      }

    }
    else if (productCode === "BGR103") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),

        color: new THREE.Color(0x48484A),
        roughness: 1
      });

      if (mesh.name.includes("BGR103_zippers_low")) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,
        });
      }

    }
    else if (productCode === "BGR122") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        color: new THREE.Color(0x666666),
        roughness: 1
      });

      if (mesh.name.includes("straps_low")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          color: new THREE.Color(0x1A1C1B),
          roughness: 1
        });
      }

    }
    else if (productCode === "BGR210") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        color: new THREE.Color(0xe9eaee),
        roughness: 1
      });

      if (mesh.name.includes("zipper_head_low")) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0x262626),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,
        });
      }
      if (mesh.name.includes("straps_low") ||
        mesh.name.includes("carabiner_low") ||
        mesh.name.includes("side_holders_low") ||
        mesh.name.includes("zip_garment_low") ||
        mesh.name.includes("fr_pocket2_low") ||
        mesh.name.includes("strap_handles_low")

      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          color: new THREE.Color(0x262626),
          roughness: 1
        });
      }

    }
    else if (productCode === "BG400") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        color: new THREE.Color(0x333335),
        roughness: 1
      });
      if (mesh.name.includes("BG400_leatherStrap")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          color: new THREE.Color(0x000000),
          roughness: 1
        });
      }
      if (mesh.name.includes("BG400_metal")) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,

        });
      }
    }
    else if (productCode === "BG102") {
      if (mesh.name.includes("fabric_body")) {
        // Load the textures
        const baseColorTexture = textureLoader.load(
          productCode + "_" + productColor + "_heather_fabric_mat_BaseColor.png"
        );
        const normalMapTexture = textureLoader.load(
          productCode + "_" + productColor + "_heather_fabric_mat_Normal.png"
        );
        const metallicTexture = textureLoader.load(
          productCode + "_" + productColor + "_heather_fabric_mat_Metallic.png"
        );
        const roughnessTexture = textureLoader.load(
          productCode + "_" + productColor + "_heather_fabric_mat_Roughness.png"
        );

        // Set repeatWrapping for each texture
        baseColorTexture.wrapS = THREE.RepeatWrapping;
        baseColorTexture.wrapT = THREE.RepeatWrapping;

        normalMapTexture.wrapS = THREE.RepeatWrapping;
        normalMapTexture.wrapT = THREE.RepeatWrapping;

        metallicTexture.wrapS = THREE.RepeatWrapping;
        metallicTexture.wrapT = THREE.RepeatWrapping;

        roughnessTexture.wrapS = THREE.RepeatWrapping;
        roughnessTexture.wrapT = THREE.RepeatWrapping;

        // Create the material
        const material = new THREE.MeshStandardMaterial({
          map: baseColorTexture,
          normalMap: normalMapTexture,
          metalnessMap: metallicTexture,
          roughnessMap: roughnessTexture,
          color: new THREE.Color(0x505253),
          roughness: 0.5,
        });

        // Assign the material to the mesh
        mesh.material = material;
      }

      else if (mesh.name.includes("net_pocket")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_pocket_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_pocket_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_pocket_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_pocket_mat_Roughness.png"
          ),
          alphaMap: textureLoader.load(
            productCode + "_" + productColor + "_pocket_mat_Opacity.png"
          ),
          transparent: true,

          color: new THREE.Color(0x1F2525),
          roughness: 0.5,
          metalness: 1

        });
      }
      else if (mesh.name.includes("plastic_buckle_basic")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_plastic_black_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_plastic_black_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_plastic_black_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_plastic_black_mat_Roughness.png"
          ),
          color: new THREE.Color(0x1F2525),
          roughness: 0.5,
        });
      }
      else if (mesh.name.includes("spacermesh_back")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_spacemesh_back_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_spacemesh_back_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_spacemesh_back_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_spacemesh_back_mat_Roughness.png"
          ),
          // color: new THREE.Color(0x666666),
          roughness: 0.5,
          metalness: 1
        });

        // Set repeatWrapping for each texture
        mesh.material.map.wrapS = THREE.RepeatWrapping;
        mesh.material.map.wrapT = THREE.RepeatWrapping;

        mesh.material.normalMap.wrapS = THREE.RepeatWrapping;
        mesh.material.normalMap.wrapT = THREE.RepeatWrapping;

        mesh.material.metalnessMap.wrapS = THREE.RepeatWrapping;
        mesh.material.metalnessMap.wrapT = THREE.RepeatWrapping;

        mesh.material.roughnessMap.wrapS = THREE.RepeatWrapping;
        mesh.material.roughnessMap.wrapT = THREE.RepeatWrapping;
      }
      else if (mesh.name.includes("zipper_quad")) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_metal_black_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_metal_black_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_metal_black_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_metal_black_mat_Roughness.png"
          ),
          // color: new THREE.Color(0x666666),
          roughness: 0.5,
          metalness: 1

        });
      }
      if (mesh.name.includes("trims")) {
        mesh.children.forEach((child) => {
          if (child.name.includes("trim_pocket_garter")) {
            child.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_BaseColor.png"
              ),
              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Normal.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Metallic.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Roughness.png"
              ),
              alphaMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Opacity.png"
              ),
              transparent: true,
              alphaTest: 0.5,
              color: new THREE.Color(0x1F2525),
              roughness: 0.5,
              metalness: 1

            });
          }
          else if (child.name.includes("trim_stitches")) {
            child.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_BaseColor.png"
              ),
              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Normal.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Metallic.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Roughness.png"
              ),
              alphaMap: textureLoader.load(
                productCode + "_" + productColor + "_trims_mat_Opacity.png"
              ),
              transparent: true,
              alphaTest: 0.5,
              color: new THREE.Color(0x1F2525),
              roughness: 0.5,
              metalness: 1

            });
          }
          else if (child.name.includes("trim_strap_belt")) {
            // Load the textures
            const baseColorTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_BaseColor.png"
            );
            const normalMapTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Normal.png"
            );
            const metallicTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Metallic.png"
            );
            const roughnessTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Roughness.png"
            );

            // Set repeatWrapping for each texture
            baseColorTexture.wrapS = THREE.RepeatWrapping;
            baseColorTexture.wrapT = THREE.RepeatWrapping;

            normalMapTexture.wrapS = THREE.RepeatWrapping;
            normalMapTexture.wrapT = THREE.RepeatWrapping;

            metallicTexture.wrapS = THREE.RepeatWrapping;
            metallicTexture.wrapT = THREE.RepeatWrapping;

            roughnessTexture.wrapS = THREE.RepeatWrapping;
            roughnessTexture.wrapT = THREE.RepeatWrapping;

            // Create the material
            const material = new THREE.MeshStandardMaterial({
              map: baseColorTexture,
              normalMap: normalMapTexture,
              metalnessMap: metallicTexture,
              roughnessMap: roughnessTexture,
              color: new THREE.Color(0x1F2525),
              roughness: 0.5,
              metalness: 1
            });

            // Assign the material to the child
            child.material = material;
          }
          else if (child.name.includes("trim_strap_border")) {
            // Load the textures
            const baseColorTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_BaseColor.png"
            );
            const normalMapTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Normal.png"
            );
            const metallicTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Metallic.png"
            );
            const roughnessTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Roughness.png"
            );

            // Set repeatWrapping for each texture
            baseColorTexture.wrapS = THREE.RepeatWrapping;
            baseColorTexture.wrapT = THREE.RepeatWrapping;

            normalMapTexture.wrapS = THREE.RepeatWrapping;
            normalMapTexture.wrapT = THREE.RepeatWrapping;

            metallicTexture.wrapS = THREE.RepeatWrapping;
            metallicTexture.wrapT = THREE.RepeatWrapping;

            roughnessTexture.wrapS = THREE.RepeatWrapping;
            roughnessTexture.wrapT = THREE.RepeatWrapping;

            // Create the material
            const material = new THREE.MeshStandardMaterial({
              map: baseColorTexture,
              normalMap: normalMapTexture,
              metalnessMap: metallicTexture,
              roughnessMap: roughnessTexture,
              color: new THREE.Color(0x1F2525),
              roughness: 0.5,
              metalness: 1
            });

            // Assign the material to the child
            child.material = material;
          }
          else if (child.name.includes("trim_zipper_front")) {
            // Load the textures
            const baseColorTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_BaseColor.png"
            );
            const normalMapTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Normal.png"
            );
            const metallicTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Metallic.png"
            );
            const roughnessTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Roughness.png"
            );

            // Set repeatWrapping for each texture
            baseColorTexture.wrapS = THREE.RepeatWrapping;
            baseColorTexture.wrapT = THREE.RepeatWrapping;

            normalMapTexture.wrapS = THREE.RepeatWrapping;
            normalMapTexture.wrapT = THREE.RepeatWrapping;

            metallicTexture.wrapS = THREE.RepeatWrapping;
            metallicTexture.wrapT = THREE.RepeatWrapping;

            roughnessTexture.wrapS = THREE.RepeatWrapping;
            roughnessTexture.wrapT = THREE.RepeatWrapping;

            // Create the material
            const material = new THREE.MeshStandardMaterial({
              map: baseColorTexture,
              normalMap: normalMapTexture,
              metalnessMap: metallicTexture,
              roughnessMap: roughnessTexture,
              color: new THREE.Color(0x1F2525),
              roughness: 0.5,
              metalness: 1
            });

            // Assign the material to the child
            child.material = material;
          }
          else if (child.name.includes("trim_zipper_pockets")) {
            // Load the textures
            const baseColorTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_BaseColor.png"
            );
            const normalMapTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Normal.png"
            );
            const metallicTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Metallic.png"
            );
            const roughnessTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Roughness.png"
            );

            // Set repeatWrapping for each texture
            baseColorTexture.wrapS = THREE.RepeatWrapping;
            baseColorTexture.wrapT = THREE.RepeatWrapping;

            normalMapTexture.wrapS = THREE.RepeatWrapping;
            normalMapTexture.wrapT = THREE.RepeatWrapping;

            metallicTexture.wrapS = THREE.RepeatWrapping;
            metallicTexture.wrapT = THREE.RepeatWrapping;

            roughnessTexture.wrapS = THREE.RepeatWrapping;
            roughnessTexture.wrapT = THREE.RepeatWrapping;

            // Create the material
            const material = new THREE.MeshStandardMaterial({
              map: baseColorTexture,
              normalMap: normalMapTexture,
              metalnessMap: metallicTexture,
              roughnessMap: roughnessTexture,
              roughness: 0.5,
              color: new THREE.Color(0x1F2525),
              metalness: 1
            });

            // Assign the material to the child
            child.material = material;

          }
          else if (child.name.includes("trim_zipper_top")) {
            // Load the textures
            const baseColorTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_BaseColor.png"
            );
            const normalMapTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Normal.png"
            );
            const metallicTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Metallic.png"
            );
            const roughnessTexture = textureLoader.load(
              productCode + "_" + productColor + "_trims_mat_Roughness.png"
            );

            // Set repeatWrapping for each texture
            baseColorTexture.wrapS = THREE.RepeatWrapping;
            baseColorTexture.wrapT = THREE.RepeatWrapping;

            normalMapTexture.wrapS = THREE.RepeatWrapping;
            normalMapTexture.wrapT = THREE.RepeatWrapping;

            metallicTexture.wrapS = THREE.RepeatWrapping;
            metallicTexture.wrapT = THREE.RepeatWrapping;

            roughnessTexture.wrapS = THREE.RepeatWrapping;
            roughnessTexture.wrapT = THREE.RepeatWrapping;

            // Create the material
            const material = new THREE.MeshStandardMaterial({
              map: baseColorTexture,
              normalMap: normalMapTexture,
              metalnessMap: metallicTexture,
              roughnessMap: roughnessTexture,
              roughness: 0.5,
              color: new THREE.Color(0x1F2525),
              metalness: 1
            });

            // Assign the material to the child
            child.material = material;
          }
        })
      }
    }

    else if (productCode === "I160") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        color: new THREE.Color(0x666666),
        roughness: 1
      });
      if (productColor === "Yellow") {
        mesh.material.color = new THREE.Color(0xCA9C28)
      } else if (productColor === "Blue") {
        mesh.material.color = new THREE.Color(0x3955be)
      } else if (productColor === "Green") {
        mesh.material.color = new THREE.Color(0x6d8a38)
      }
      else if (productColor === "Orange") {
        mesh.material.color = new THREE.Color(0xF56334)
      }
      else if (productColor === "Purple") {
        mesh.material.color = new THREE.Color(0x68478D)
      }
      else if (productColor === "Red") {
        mesh.material.color = new THREE.Color(0xA4343A)
      }
      else if (productColor === "White") {
        mesh.material.color = new THREE.Color(0xffffff)
      }

      if (mesh.name.includes("clip_1_low") ||
        mesh.name.includes("end_shaft_low") ||
        mesh.name.includes("ring_low") ||
        mesh.name.includes("tip_low") ||
        mesh.name.includes("rings_low") ||
        mesh.name.includes("nib_low")
      ) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,

        });
      }

    }
    else if (productCode === "G1298") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        roughness: 0.5
      });
      if (productColor === "Black") {
        mesh.material.color = new THREE.Color(0x2D2926)
      } else if (productColor === "Blue") {
        mesh.material.color = new THREE.Color(0x13294B)
      } else if (productColor === "Champagne") {
        mesh.material.color = new THREE.Color(0x9F8170)
      } else if (productColor === "GunMetal") {
        mesh.material.color = new THREE.Color(0x54585A)
      }
      else if (productColor === "Silver") {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          metalness: 1,             // Set metalness to 1 for a metallic appearance
          color: new THREE.Color(0xAAAAAA), // Adjust the color to a light gray for silver
          roughness: 0.5,           // Increase roughness to reduce shininess
          clearcoat: 0,
        });
      }

      if (mesh.name.includes("clip_1_low") ||
        mesh.name.includes("end_shaft_low") ||
        mesh.name.includes("ring_low") ||
        mesh.name.includes("tip_low") ||
        mesh.name.includes("rings_low") ||
        mesh.name.includes("nib_low")
      ) {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
          metalness: 1,
          color: new THREE.Color(0xFFFFFF),
          roughness: 0.1,
          clearcoat: 1,
          clearcoatRoughness: 0,
        });


      }

    }
    else if (productCode === "DW322") {
      if (mesh.name.includes("body_grp_low")) {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
          });

          if (
            mat.name.includes("phone_stand_low") ||
            mat.name.includes("body_thread_mouth_low")
          ) {
            mat.material.roughness = 0.9;
            mat.material.metalness = 1;
          }
        });
      } else if (mesh.name.includes("cap_grp_low")) {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
          });

          if (mat.name.includes("cap_top_low")) {
            mat.material.roughness = 0.9;
            mat.material.metalness = 1;
          }
        });
      }
    } else if (productCode === "DW415") {
      mesh.children.forEach((mat, i) => {
        mat.children.forEach((material, i) => {

          if (material.name === "Body_low" || material.name === "Handle_low") {
            material.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_body_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_body_Metallic.png"
              ),
              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_body_Normal.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_body_Roughness.png"
              ),
            });

            if (productColor === "Black")
              material.material.color = new THREE.Color(0x2a2c2b);
            if (productColor === "Green")
              material.material.color = new THREE.Color(0x3a3e33);
            if (productColor === "Grey")
              material.material.color = new THREE.Color(0x66696a);
          }

          if (
            material.name === "Straw_low" ||
            material.name === "Bottom_low" ||
            material.name === "Lid_straw_open_low" ||
            material.name === "Cover_flip_low" ||
            material.name === "Rubber_lid_low"
          ) {
            material.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_mat_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Metallic.png"
              ),
              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Normal.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Roughness.png"
              ),
            });

            if (productColor === "Black")
              material.material.color = new THREE.Color(0x2a2c2b);
            if (productColor === "Green")
              material.material.color = new THREE.Color(0x3a3e33);
            if (productColor === "Grey")
              material.material.color = new THREE.Color(0x66696a);
          }
          if (material.name === "Lid_low") {
            material.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_clear_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_Metallic.png"
              ),
              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_Normal.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_Roughness.png"
              ),
              color: new THREE.Color(0x171717),
              transparent: true,
              opacity: 0.7,
              roughness: 0.75,
            });
          }
        });
      });
    } else if (productCode === "DW104") {
      mesh.children.forEach((mat, i) => {
        if (
          mat.name === "under_silicon_low" ||
          mat.name === "Silcon_cover_inner_low" ||
          mat.name === "Silicon_cover_outer_low" ||
          mat.name === "Silicon_cover_top_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_silicon_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_silicon_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_silicon_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_silicon_mat_Roughness.png"
            ),
          });
        }
        if (
          mat.name === "Cover_base_low" ||
          mat.name === "Cover_helix_low" ||
          mat.name === "Silcon_bottle_low" ||
          mat.name === "Cover_hook_low" ||
          mat.name === "Cover_att_low" ||
          mat.name === "Opener_low" ||
          mat.name === "Cylinder_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
          });
        }
        if (mat.name === "Cover_top_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_clear_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_mat_Roughness.png"
            ),
            transparent: true,
            opacity: 0.75,
            roughness: 0.75,
          });
        }

        if (mat.name === "Bottle_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_bottle_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_bottle_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_bottle_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_bottle_mat_Roughness.png"
            ),
            transparent: true,
            opacity: 0.75,
            roughness: 0.75,
          });
        }
      });
    } else if (productCode === "DW201") {
      if (mesh.name === "cover_clear_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_BaseColor.png"
          ),
          aoMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_AO.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Normal.png"
          ),
          // roughnessMap: textureLoader.load(
          //   productCode + "_" + productColor + "_clear_mat_Roughness.png"
          // ),
          transparent: true,
          opacity: 0.4,
          roughness: 0.1,
          metalness: 0.75,
        });
      }
      if (mesh.name === "bottle_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_BaseColor.png"
          ),
          aoMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_AO.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Roughness.png"
          ),
          // color: new THREE.Color(0xFFFFFFF),
          transparent: true,
          opacity: 0.4,
          roughness: 0.3,
          metalness: 0.69,
          side: THREE.DoubleSide,

        });
      }

      if (mesh.name === "Cover_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          aoMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_AO.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          roughness: 0.5,
        });
      }

      if (mesh.name === "Cover_rubber_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_Clear_rubber_mat_BaseColor.png"
          ),
          aoMap: textureLoader.load(
            productCode + "_" + productColor + "_Clear_rubber_mat_AO.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_Clear_rubber_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_Clear_rubber_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_Clear_rubber_mat_Roughness.png"
          ),
        });
      }
    } else if (productCode === "DW324") {
      if (
        mesh.name === "Body_low" ||
        mesh.name === "Bottle_helix_low" ||
        mesh.name === "Cover_helix_low" ||
        mesh.name === "Cover_top_inner_low" ||
        mesh.name === "Cover_cylinder_low"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });

        if (productColor === "Black")
          mesh.material.color = new THREE.Color(0x373737);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xae3236);
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x2f3454);

        if (mesh.name === "Body_low") {
          mesh.material.metalness = 1;
        }
      }

      if (
        mesh.name === "Cover_top_low" ||
        mesh.name === "Handle_low" ||
        mesh.name === "Cover_base_low"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_sec_mat_BaseColor.png"
          ),
          color: new THREE.Color(0x373737),
        });
      }

      if (mesh.name === "Ora_pad_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_Roughness.png"
          ),
        });

        mesh.material.color = new THREE.Color(0x373737);
      }
    } else if (productCode === "DW326") {
      mesh.children.forEach((mat, i) => {
        if (mat.name === "Body_Color_low" || mat.name === "Holder_Color_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            metalness: 1,
          });
          if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x0082c6);
          } else if (productColor === "Black") {
            mat.material.color = new THREE.Color(0x2B2725);
          } else if (productColor === "Orange") {
            mat.material.color = new THREE.Color(0xF24038);
          }
          else if (productColor === "Pink") {
            mat.material.color = new THREE.Color(0xF93DB1);
          }

        }
        if (mat.name === "Body_Inside_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            metalness: 1,
          });
        }
        if (
          mat.name === "Cover_base_low" ||
          mat.name === "Cover_attach_low" ||
          mat.name === "Cover_top_low" ||
          mat.name === "Holder_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_Roughness.png"
            ),
          });

          mat.material.color = new THREE.Color(0x373637);
        }
      });
    } else if (productCode === "DW325") {
      mesh.children.forEach((mat, i) => {
        if (mat.name === "Body_Color_low" || mat.name === "Holder_Color_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            metalness: 1,
          });
          if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x0082c6);
          } else if (productColor === "Black") {
            mat.material.color = new THREE.Color(0x2B2725);
          } else if (productColor === "Orange") {
            mat.material.color = new THREE.Color(0xF24038);
          }
          else if (productColor === "Pink") {
            mat.material.color = new THREE.Color(0xF93DB1);
          }

        }
        if (mat.name === "Body_Inside_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            metalness: 1,
          });
        }
        if (
          mat.name === "Cover_base_low" ||
          mat.name === "Cover_attach_low" ||
          mat.name === "Cover_top_low" ||
          mat.name === "Holder_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cover_mat_Roughness.png"
            ),
          });

          mat.material.color = new THREE.Color(0x373637);
        }
      });
    } else if (productCode === "DW417") {
      if (mesh.name === "Mug_low" || mesh.name === "Mug_bottom_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughness: 0.8,
        });

        if (mesh.name === "Mug_bottom_low") {
          mesh.material.metalness = 1;
        }
      }

      if (mesh.name === "Clear_tab_low" || mesh.name === "Clear_lid_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Normal.png"
          ),
          transparent: true,
          opacity: 0.4,
          roughness: 0.25,
        });
      }

      if (mesh.name === "Silicone_low" || mesh.name === "Rubber_seal_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_silicon_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_silicon_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_silicon_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_silicon_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Ora_pad_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_Roughness.png"
          ),
        });

        mesh.material.color = new THREE.Color(0x373737);
      }
    } else if (productCode === "DW418") {
      if (mesh.name === "Body_low" || mesh.name === "Base_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });

        switch (productColor) {
          case "Green":
            mesh.material.color = new THREE.Color(0x49875E)
            break;
          case "Blue":
            mesh.material.color = new THREE.Color(0x201B53)
            break;
          case "Orange":
            mesh.material.color = new THREE.Color(0xF49736)
            break;
          case "Red":
            mesh.material.color = new THREE.Color(0xB53C3E)
            break;
          case "Yellow":
            mesh.material.color = new THREE.Color(0xDED836)
            break;
          case "Purple":
            mesh.material.color = new THREE.Color(0x3E1B55)
            break;
          case "Black":
            mesh.material.color = new THREE.Color(0x181818)
            break;
        }

      }

      if (mesh.name === "Body2_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          roughness: 0.5,
          metalness: 1,
          color: new THREE.Color(0xC0C0C0)
        });
      }

      if (mesh.name === "Clear_tab_low" || mesh.name === "Clear_lid_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Normal.png"
          ),
          transparent: true,
          opacity: 0.6,
          roughness: 0.25,
          metalness: 1,
        });
      }

      if (mesh.name === "Silicone_low" || mesh.name === "Rubber_seal_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_rubber_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_rubber_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_rubber_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_rubber_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Ora_pad_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_orapad_mat_Roughness.png"
          ),
        });
      }
    }

    else if (productCode === "DW500") {
      if (
        mesh.name === "Sleeve_low" ||
        mesh.name === "Cover_low" ||
        mesh.name === "Straw_top_low" ||
        mesh.name === "straw_bottom_low" ||
        mesh.name === "Thread_cover_low"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Body_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Normal.png"
          ),
          // roughnessMap: textureLoader.load(productCode + '_' + productColor + '_clear_mat_Roughness.png'),
          transparent: true,
          opacity: 0.4,
          roughness: 0.1,
          metalness: 0.75,

        });
      }
    } else if (productCode === "DW502") {
      if (mesh.name === "Sleeve_low" || mesh.name === "Cover_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Body_low") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Normal.png"
          ),
          // roughnessMap: textureLoader.load(productCode + '_' + productColor + '_clear_mat_Roughness.png'),
          transparent: true,
          opacity: 0.4,
          roughness: 0.1,
          metalness: 0.75,
        });
      }
    } else if (productCode === "DW405") {
      mesh.children.forEach((mat) => {
        if (mat.name === "Mug_low" || mat.name === "Straw_top_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            metalness: 1,
          });

          if (productColor === "Navy")
            mat.material.color = new THREE.Color(0x252b4d);
          if (productColor === "Red")
            mat.material.color = new THREE.Color(0x801b1b);
          // if (productColor === 'Blue') mat.material.color = new THREE.Color( 0x42A3E7 );
          // if (productColor === 'Orange') mat.material.color = new THREE.Color( 0xFF6A63 );
        }

        if (mat.name === "Ora_pad_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_orapad_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_orapad_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_orapad_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_orapad_mat_Roughness.png"
            ),
          });

          mat.material.color = new THREE.Color(0x373737);
        }

        if (
          mat.name === "Cover_low" ||
          mat.name === "Cover_inside_low" ||
          mat.name === "Cover_rubbera_low" ||
          mat.name === "Cover_rubberb_low" ||
          mat.name === "Cover_plastic_low" ||
          mat.name === "Mug_Bottom_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_BaseColor.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Roughness.png"
            ),
          });

          if (mat.name === "Mug_Bottom_low") {
            mat.material.metalness = 1;
          }
        }
      });
    } else if (productCode === "DW416") {
      mesh.children.forEach((mat, i) => {
        if (
          mat.name === "Body_low" ||
          mat.name === "Handle_low" ||
          mat.name === "Mug_lower_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
          });

          if (productColor === "Black")
            mat.material.color = new THREE.Color(0x2a2c2b);
          if (productColor === "Green")
            mat.material.color = new THREE.Color(0x3a3e33);
          if (productColor === "Grey")
            mat.material.color = new THREE.Color(0x66696a);
        }

        if (
          mat.name === "Straw_low" ||
          mat.name === "Bottom_low" ||
          mat.name === "Cover_part1_low" ||
          mat.name === "Cover_part2_open_low"
        ) {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
          });

          if (productColor === "Black")
            mat.material.color = new THREE.Color(0x2a2c2b);
          if (productColor === "Green")
            mat.material.color = new THREE.Color(0x3a3e33);
          if (productColor === "Grey")
            mat.material.color = new THREE.Color(0x66696a);
        }

        if (mat.name === "Lid_low") {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_clear_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_Roughness.png"
            ),
            color: new THREE.Color(0x171717),
            transparent: true,
            opacity: 0.7,
            roughness: 0.75,
          });
        }
      });
    } else if (mesh.name.includes("phone_stand_low")) {
      if (productCode === "DW323") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
          metalness: 1,
        });
      }
    } else if (mesh.name.includes("cap_handle_low")) {
      if (productCode === "DW323") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Roughness.png"
          ),
          metalness: 1,
        });
      }
    } else if (mesh.name.includes("cap_spout_low")) {
      if (productCode === "DW323") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Roughness.png"
          ),
        });

        if (productColor === "Black")
          mesh.material.color = new THREE.Color(0x303332);
      }
    } else if (mesh.name.includes("bottle") && productCode !== "HW205C") {
      mesh.children.forEach((mat, i) => {
        mat.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
        });

        if (productCode === "DW303") {
          mat.material.roughness = 0.8;
          mat.material.metalness = 0.6;
          if (productColor === "Natural") {
            mat.material.color = new THREE.Color(0xad8257);
          } else if (productColor === "Walnut") {
            mat.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_mat_BaseColor.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Roughness.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Metallic.png"
              ),
              // normalMap: textureLoader.load(
              //   productCode + "_" + productColor + "_mat_Normal.png"
              // ),
              color: new THREE.Color(0x72432f),
              roughness: 1,
              metalness: 0.8
            });

          }
        }

        if (productCode === "DW320") {
          if (productColor === "Navy") {
            mat.material.color = new THREE.Color(0x677588);
          }
        }

        if (productCode === "DW305") {
          if (productColor === "Silver") {
            mat.material.color = new THREE.Color(0x6e7173);
            mat.material.metalness = 0.5;
          } else if (productColor === "RoseGold") {
            mat.material.metalness = 0.5;
            mat.material.color = new THREE.Color(0x997062);
          } else if (productColor === "WhitePearl") {
            mat.material.roughness = 0.25;
            mat.material.metalness = 0.5;
          }
        }

        if (productCode === "DW304") {
          if (productColor === "Black") {
            mat.material.color = new THREE.Color(0x353636);
          } else if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x344994);
          } else if (productColor === "Red") {
            mat.material.color = new THREE.Color(0xa92e3d);
          } else if (productColor === "Stainless") {
            mat.material.metalness = 1;
          }
        }
      });
    } else if (mesh.name.includes("Silicon")) {
      mesh.children.forEach((mat, i) => {
        mat.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_Silicon_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_Silicon_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_Silicon_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_Silicon_mat_Roughness.png"
          ),
        });
      });
    } else if (mesh.name.includes("body_inner_low")) {
      if (productCode === "DW103") {
        mesh.material = new THREE.MeshPhysicalMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_inner_mat_BaseColor.png"
          ),
          alphaMap: textureLoader.load(
            productCode + "_" + productColor + "_body_inner_mat_Opacity.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_inner_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_inner_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_inner_mat_Roughness.png"
          ),
          roughness: 0.7,
          transmission: 1,
          thickness: 1

        });
        // mesh.material.roughness = 0.552786
        // mesh.material.metalness = 0
      }
      // else {
      //   mesh.material = new THREE.MeshStandardMaterial({
      //     map: textureLoader.load(
      //       productCode + "_" + productColor + "_body_inner_mat_BaseColor.png"
      //     ),
      //   });
      //   mesh.material.roughness = 0.6;
      //   mesh.material.metalness = 1;
      // }
    } else if (mesh.name.includes("silicone_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_Silicon_mat_BaseColor.png"
        ),
      });
      mesh.material.roughness = 0.6;
      mesh.material.metalness = 1;
    } else if (mesh.name.includes("silicone_grp")) {
      mesh.children.forEach((mat, i) => {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_Silicon_mat_BaseColor.png"
          ),
        });
        mesh.material.roughness = 0.6;
        mesh.material.metalness = 1;
      });
    } else if (mesh.name.includes("body_outer_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_body_outer_mat_BaseColor.png"
        ),
      });
      mesh.material.roughness = 0.5;
      mesh.material.metalness = 0.75;

      if (productCode === "DW100") {
        if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x67ab1d);
        } else if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x1e45c0);
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0xff0000);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xd7591b);
        }
      }
    } else if (mesh.name.includes("cap_body_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_cap_mat_BaseColor.png"
        ),
      });
      mesh.material.roughness = 0.5;
      mesh.material.metalness = 0.5;
    } else if (mesh.name.includes("cap_top_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_cap_mat_BaseColor.png"
        ),
      });
      mesh.material.roughness = 0.6;
      mesh.material.metalness = 1;
    } else if (mesh.name.includes("cap_lid_low")) {
      if (productCode === "DW312") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Roughness.png"
          ),
        });
      }
    } else if (mesh.name.includes("Cap_grp_low")) {
      mesh.children.forEach((mat, i) => {
        mat.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Roughness.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Metallic.png"
          ),
          metalness: 1,
        });

        if (productCode === "DW304") {
          if (productColor === "Black") {
            mat.material.color = new THREE.Color(0x353636);
          } else if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x344994);
          } else if (productColor === "Red") {
            mat.material.color = new THREE.Color(0xa92e3d);
          }
        }
      });
    } else if (mesh.name.includes("cap_grp_low")) {
      if (productCode === "DW401") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
            metalness: 1,
          });
        });
      } else if (productCode === "DW317" || productCode === "DW316") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
            metalness: 1,
          });
        });
      } else if (productCode === "DW301") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
          });
        });
      }
      else if (productCode === "DW103") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
            opacity: 0.9,
            // metalness: 1
          });
          if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x40A0C1);
          } else if (productColor === "Green") {
            mat.material.color = new THREE.Color(0x556c6c);
          } else if (productColor === "Orange") {
            mat.material.color = new THREE.Color(0xAD6345);
          } else if (productColor === "Sand") {
            mat.material.color = new THREE.Color(0x776261);
          }
        });
      }
    } else if (mesh.name.includes("cap_grp")) {
      if (productCode === "DW101") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
          });

          if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x3a4b69);
          } else if (productColor === "Green") {
            mat.material.color = new THREE.Color(0x77b750);
          } else if (productColor === "Orange") {
            mat.material.color = new THREE.Color(0xd9831d);
          } else if (productColor === "Yellow") {
            mat.material.color = new THREE.Color(0xbca801);
          }
        });
      } else if (productCode === "DW301" || productCode === "DW308") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
          });

          if (productCode === "DW308") {
            mat.material.metalness = 1;

            if (productColor === "Blue") {
              mat.material.color = new THREE.Color(0x2a4789);
            } else if (productColor === "Red") {
              mat.material.color = new THREE.Color(0x972a2f);
            }
          }
        });
      } else {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
        });
        mesh.material.roughness = 0.6;
        mesh.material.metalness = 1;
      }
    } else if (mesh.name.includes("ring_low")) {
      if (productCode !== "DW403") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_ring_mat_BaseColor.png"
          ),
        });
      } else {
        mesh.material = new THREE.MeshStandardMaterial({
          // metalness: 1,
          // roughness: 0
        });
      }

      if (productCode === "I154") {

        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          metalness: 1,
          roughness: 0.5,
        });


      }

      if (productCode === "DW409") {
        if (productColor === "Blue") {
        } else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x666666);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0x8c8c8c);
        } else if (productColor === "Purple") {
          mesh.material.color = new THREE.Color(0x666666);
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0x4d4d4d);
        } else if (productColor === "Yellow") {
          mesh.material.color = new THREE.Color(0x8c8c8c);
        }
      } else if (productCode === "DW603" || productCode === "DW604") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
        });
      }
    } else if (mesh.name.includes("body_low")) {
      if (
        productCode === "DW312" ||
        productCode === "DW311" ||
        productCode === "DW314" ||
        productCode === "DW404" ||
        productCode === "DW413" ||
        productCode === "DW409" ||
        productCode === "DW403" ||
        productCode === "DW309" ||
        productCode === "DW310" ||
        productCode === "DW301" ||
        productCode === "DW300" ||
        productCode === "DW603" ||
        productCode === "DW604" ||
        productCode === "DW307"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
        });
        if (productCode === "DW307") {
          mesh.material.roughnessMap = textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          );
          if (productColor === "RoseGold") {
            mesh.material.color = new THREE.Color(0xb57060);
            mesh.material.roughness = 0.85;
            mesh.material.metalness = 1;
          } else if (productColor === "StainlessSteel") {
            mesh.material.roughness = 0.75;
            mesh.material.metalness = 1;
          }
        }

        if (productCode === "DW604") {
          mesh.material.color = new THREE.Color(0x69463f);
        }

        if (productCode === "DW413") {
          mesh.material.metalness = 1;
          mesh.material.roughness = 0.4;
        }

        if (productCode === "DW300") {
          mesh.material.metalness = 1;
          if (productColor === "Blue") {
            mesh.material.color = new THREE.Color(0x808080);
          } else if (productColor === "Orange") {
            mesh.material.color = new THREE.Color(0x999999);
          } else if (productColor === "Red") {
            mesh.material.color = new THREE.Color(0x999999);
          }
        }

        if (productCode === "DW403") {
          mesh.material.metalness = 1;

          if (productColor === "RoseGold") {
            mesh.material.color = new THREE.Color(0xc28d74);
          }
        }

        if (productCode === "DW310") {
          mesh.material.metalness = 1;
          mesh.material.roughness = 0.5;

          if (productColor === "Blue") {
            mesh.material.color = new THREE.Color(0x1e1c77);
          } else if (productColor === "Green") {
            mesh.material.color = new THREE.Color(0x689425);
          } else if (productColor === "Grey") {
            mesh.material.color = new THREE.Color(0x5e5e5d);
          } else if (productColor === "Orange") {
            mesh.material.color = new THREE.Color(0xbf6603);
          } else if (productColor === "Red") {
            mesh.material.color = new THREE.Color(0xae3338);
          }
        }

        if (productCode === "DW309") {
          if (productColor === "Blue") {
            mesh.material.color = new THREE.Color(0x319bd8);
          } else if (productColor === "Orange") {
            mesh.material.color = new THREE.Color(0xff5347);
          } else if (productColor === "Pink") {
            mesh.material.color = new THREE.Color(0xff47a6);
          }
        }

        if (productColor === "Gold" || productColor === "Silver") {
          mesh.material.roughness = 0.25;
          mesh.material.metalness = 0.9;
        } else {
          mesh.material.metalnessMap = textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          );
          mesh.material.roughnessMap = textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          );
        }

        if (productColor === "Gold") {
          mesh.material.color = new THREE.Color(0xe8a35a);
        }

        if (productCode === "DW311") {
          if (productColor !== "Silver") {
            mesh.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_body_mat_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_body_mat_Metallic.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_body_mat_Roughness.png"
              ),
            });
          } else {
            mesh.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_body_mat_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_body_mat_Metallic.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_body_mat_Roughness.png"
              ),
              metalness: 1,
              roughness: 1,
            });
          }

          if (productColor === "Blue") {
            mesh.material.color = new THREE.Color(0x2b48b0);
          } else if (productColor === "Green") {
            mesh.material.color = new THREE.Color(0x9dad50);
          } else if (productColor === "Orange") {
            mesh.material.color = new THREE.Color(0xff8028);
          } else if (productColor === "Red") {
            mesh.material.color = new THREE.Color(0x922433);
          } else if (productColor === "Silver") {
            mesh.material.color = new THREE.Color(0x82898f);
          }
        }

        if (productCode === "DW409") {
          if (productColor === "Blue") {
          } else if (productColor === "Green") {
            mesh.material.color = new THREE.Color(0x666666);
          } else if (productColor === "Orange") {
            mesh.material.color = new THREE.Color(0x8c8c8c);
          } else if (productColor === "Purple") {
            mesh.material.color = new THREE.Color(0x666666);
          } else if (productColor === "Red") {
            mesh.material.color = new THREE.Color(0x4d4d4d);
          } else if (productColor === "Yellow") {
            mesh.material.color = new THREE.Color(0x8c8c8c);
          }
        }

        if (productCode === "DW314" && productColor === "Silver") {
          mesh.material.color = new THREE.Color(0xa3a3a3);
        }

        if (productCode === "DW404") {
          mesh.material.metalness = 1;
        }
      } else {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
        });

        mesh.material.map = textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        );

        if (productCode === "DW313") {
          mesh.material.metalness = 0.5;
          mesh.material.roughness = 0.3;
        }

        if (productColor === "Silver") {
          mesh.material.color = new THREE.Color(0xffffff);
        } else {
          mesh.material.metalnessMap = textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          );
          mesh.material.roughnessMap = textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          );
        }

        if (productCode === "G3141") {
          mesh.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_logo_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_logo_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_logo_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_logo_mat_Roughness.png"
            ),
            aoMap: textureLoader.load(
              productCode + "_" + productColor + "_body_logo_mat_AO.png"
            ),
          });

          if (productColor === "Blue")
            mesh.material.color = new THREE.Color(0x1b253b);
          if (productColor === "Green")
            mesh.material.color = new THREE.Color(0x31380d);
          if (productColor === "Orange")
            mesh.material.color = new THREE.Color(0x965000);
          if (productColor === "Red")
            mesh.material.color = new THREE.Color(0x750d1c);
        }

        if (productCode === "DW323") {
          mesh.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
          });
        }

        if (productCode === "G3148") {
          mesh.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
            aoMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_AO.png"
            ),
          });

          if (productColor === "Orange") {
            mesh.material.color = new THREE.Color(0xffd1b8);
          }
        }

        if (productCode === "BG700") {
          mesh.material.color = new THREE.Color(0x808080);
        }
      }
      if (productCode === "DW103") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
          transparent: true,
          opacity: 0.9,

          roughness: 1,
        });

        if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x689fb2);
        } else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x556c6c);
        }
        else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0x8E4A10);
        }
        else if (productColor === "Sand") {
          mesh.material.color = new THREE.Color(0x6F5B5B);
        }

      }
    } else if (mesh.name.includes("cap_main_low")) {
      if (productCode === "DW300") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_main_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_main_mat_Normal.png"
          ),
          transparent: true,
          opacity: 0.75,
          metalness: 0.5,
          roughness: 0.1,
        });
      }
    } else if (mesh.name.includes("cap_tab_low")) {
      if (productCode === "DW300") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_capringtab_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_capringtab_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_capringtab_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_capringtab_mat_Roughness.png"
          ),
          // metalness: 0.5,
          // roughness: 0.3,
        });

        if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x808080);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0x999999);
          // mesh.material.color = new THREE.Color( 0xFF6E17 )
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0x999999);
        }
      }
    } else if (mesh.name.includes("cap_low")) {
      // mesh.material = new THREE.MeshLambertMaterial({ map: bodyOuterLowTexture });
      if (productCode === "DW313") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalness: 0.5,
          roughness: 0.3,
        });
      } else if (productCode === "DW314") {
        if (productColor === "Silver") {
          mesh.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            metalness: 1,
            roughness: 0.25,
            color: new THREE.Color(0xa3a3a3),
          });
        } else {
          mesh.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + '_' + productColor + '_cap_mat_Metallic.png'
            ),
            roughnessMap: textureLoader.load(
              productCode + '_' + productColor + '_cap_mat_Roughness.png'
            ),
          });
        }
      } else if (productCode === "DW309") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + '_' + productColor + '_cap_mat_Metallic.png'
          ),
          metalness: 1,
          roughness: 0.75,
        });

        if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x319bd8);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xff5347);
        } else if (productColor === "Pink") {
          mesh.material.color = new THREE.Color(0xff47a7);
        }
      } else if (productCode === "DW310") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + '_' + productColor + '_cap_mat_Metallic.png'
            ),
            metalness: 1,
            roughness: 0.25,
          });

          if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x1e1c77);
          } else if (productColor === "Green") {
            mat.material.color = new THREE.Color(0x689425);
          } else if (productColor === "Grey") {
            mat.material.color = new THREE.Color(0x5e5e5d);
          } else if (productColor === "Orange") {
            mat.material.color = new THREE.Color(0xbf6603);
          } else if (productColor === "Red") {
            mat.material.color = new THREE.Color(0xae3338);
          }
        });
      } else if (productCode === "DW603") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Roughness.png"
          ),
        });
      } else if (productCode === "DW604") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_cap_mat_Roughness.png"
          ),
          color: new THREE.Color(0x522e24),
        });
      }
    } else if (mesh.name.includes("handle_low")) {
      if (productCode === "DW313") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalness: 0.5,
          roughness: 0.3,
        });
      } else if (productCode === "BG700") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
          metalness: 0.5,
          roughness: 0.8,
        });
      }
    } else if (mesh.name.includes("handle_grp")) {
      if (productCode === "DW101") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_handle_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_handle_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_handle_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_handle_mat_Roughness.png"
            ),
            emissiveMap: textureLoader.load(
              productCode + "_" + productColor + "_handle_mat_Emissive.png"
            ),
            color: new THREE.Color(0x0f0d0d),
          });
        });
      }
    } else if (mesh.name.includes("clear_gloss_grp")) {
      if (productCode === "DW101") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_clear_gloss_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_gloss_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_gloss_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_clear_gloss_mat_Roughness.png"
            ),
          });

          if (productColor !== "Black" && productColor !== "White") {
            mat.material.opacity = 0.5;
            if (productColor === "Blue") {
              mat.material.color = new THREE.Color(0x3a4b69);
            } else if (productColor === "Green") {
              mat.material.color = new THREE.Color(0x77b750);
            } else if (productColor === "Orange") {
              mat.material.color = new THREE.Color(0xd9831d);
            } else if (productColor === "Yellow") {
              mat.material.color = new THREE.Color(0xbca801);
            }
          }
        });
      }
    } else if (mesh.name.includes("clear_rough_grp")) {
      if (productCode === "DW101") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_clear_rough_mat_BaseColor.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_rough_mat_Normal.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_rough_mat_Metallic.png"
            ),
            roughnessMap: textureLoader.load(
              productCode +
              "_" +
              productColor +
              "_clear_rough_mat_Roughness.png"
            ),
          });

          if (productColor !== "Black" && productColor !== "White") {
            mat.material.opacity = 0.9;
            if (productColor === "Blue") {
              mat.material.color = new THREE.Color(0x3a4b69);
            } else if (productColor === "Green") {
              mat.material.color = new THREE.Color(0x77b750);
            } else if (productColor === "Orange") {
              mat.material.color = new THREE.Color(0xd9831d);
            } else if (productColor === "Yellow") {
              mat.material.color = new THREE.Color(0xbca801);
            }
          }
        });
      }
    } else if (mesh.name.includes("handle_joints_low")) {
      if (productCode === "DW313") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalness: 0.5,
          roughness: 0.3,
        });
      }
    } else if (mesh.name.includes("handle_knob_low")) {
      if (productCode === "DW313") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalness: 1,
          roughness: 0.1,
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
        });
      }
    } else if (mesh.name.includes("rubber_cover_low")) {
      if (productCode === "DW313") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalness: 0.25,
          roughness: 0.75,
        });
      }
    } else if (mesh.name.includes("cap_silicon_low")) {
      if (productCode === "DW313") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_Silicon_mat_BaseColor.png"
          ),
          metalness: 0.25,
          roughness: 0.75,
        });
      }
    } else if (mesh.name.includes("lid")) {
      if (productCode === "DW414") {
        mesh.children.forEach((mat, i) => {
          if (mat.name.includes("clear")) {
            mat.children.forEach((parts, i) => {
              parts.material = new THREE.MeshStandardMaterial({
                map: textureLoader.load(
                  "DW410_Bamboo_clear_mat_BaseColor.png"
                ),

                normalMap: textureLoader.load(
                  "DW410_Bamboo_clear_mat_Normal.png"
                ),
                roughness: 0,
                metalness: 0.5,
                alphaMap: textureLoader.load(
                  "DW410_Bamboo_clear_mat_Opacity.png"
                ),
                opacity: 0.6,
                transparent: true,
              });
            });
          }
        });
      }
    } else if (mesh.name.includes("clear_grp")) {
      if (productCode !== "DW404") {
        if (productCode === "DW403") {
          mesh.children.forEach((mat, i) => {
            mat.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_BaseColor.png"
              ),

              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_Normal.png"
              ),
              roughness: 0,
              metalness: 0.75,

              alphaMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_Opacity.png"
              ),
              opacity: 0.9,
              transparent: true,
            });
          });
        } else {
          mesh.children.forEach((mat, i) => {
            mat.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_BaseColor.png"
              ),

              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_Normal.png"
              ),
              roughness: 0,
              metalness: 0.75,

              alphaMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_Opacity.png"
              ),
              opacity: 0.6,
              transparent: true,
            });
          });
        }
      } else {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_clear_mat_BaseColor.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_mat_Normal.png"
            ),
            roughness: 0,
            metalness: 0.75,
            opacity: 0.6,
            transparent: true,

            alphaMap: textureLoader.load(
              productCode + "_" + productColor + "_clear_mat_Opacity.png"
            ),
          });
        });
      }
    } else if (mesh.name.includes("cap_outer_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_cap_mat_BaseColor.png"
        ),
        metallicMap: textureLoader.load(
          productCode + "_" + productColor + "_cap_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_cap_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_cap_mat_Roughness.png"
        ),
      });
      mesh.material.map = textureLoader.load(
        productCode + "_" + productColor + "_cap_mat_BaseColor.png"
      );
      mesh.material.roughness = 0.5;
      mesh.material.metalness = 0.4;
    } else if (mesh.name.includes("orapad_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_orapad_mat_BaseColor.png"
        ),
        metallicMap: textureLoader.load(
          productCode + "_" + productColor + "_orapad_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_orapad_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_orapad_mat_Roughness.png"
        ),
      });
      mesh.material.map = textureLoader.load(
        productCode + "_" + productColor + "_orapad_mat_BaseColor.png"
      );
      mesh.material.roughness = 0.8;
      mesh.material.metalness = 0.5;

      if (productCode === "DW316") {
        mesh.material.color = new THREE.Color(0x282727);
      }
    } else if (mesh.name.includes("Bottle_low")) {
      if (productCode === "DW319") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_BaseColor.png"
          ),
          metallicMap: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_Roughness.png"
          ),
        });

        if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0xba1c2f);
        } else if (productColor === "Navy") {
          mesh.material.color = new THREE.Color(0x39445b);
        } else if (productColor === "Yellow") {
          mesh.material.color = new THREE.Color(0xf6c741);
        }
      }

      if (productCode === "DW306") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_bottle_mat_Roughness.png"
          ),
        });

        if (productColor === "Gunmetal") {
          mesh.material.color = new THREE.Color(0x6b6064);
        }
      }
    } else if (mesh.name.includes("Ora_pad")) {
      if (
        productCode === "DW319" ||
        productCode === "DW410" ||
        productCode === "DW404"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_Ora_pad_mat_BaseColor.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_Ora_pad_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_Ora_pad_mat_Roughness.png"
          ),
        });
      }
    } else if (mesh.name.includes("cup_low")) {
      if (productCode === "DW316" || productCode === "DW317") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_cup_mat_BaseColor.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_cup_mat_Normal.png"
          ),

          metalness: 0.75,
          roughness: 0.15,
          color: new THREE.Color(0xd4d4d4),
        });
      } else if (productCode === "DW323") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
        });

        if (productColor === "Black")
          mesh.material.color = new THREE.Color(0x2a2c2b);
      }
    } else if (mesh.name.includes("body_grp_low")) {
      if (productCode === "DW316" || productCode === "DW317") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),

            metalness: 0.5,
            roughness: 0.4,
            color: new THREE.Color(0x7e8287),
          });
        });
      }
    } else if (mesh.name.includes("Body_grp_low")) {
      if (productCode === "DW401") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
          });

          if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x273a86);
          } else if (productColor === "Red") {
            mat.material.color = new THREE.Color(0x921928);
          } else if (productColor === "StainlessSteel") {
            mat.material.metalness = 1;
          }
        });
      }
    } else if (mesh.name.includes("straw_low")) {
      if (productCode === "DW401") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_straw_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_straw_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_straw_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_straw_mat_Roughness.png"
          ),
        });
      }
    } else if (mesh.name.includes("body_grp")) {
      if (productCode === "DW410" || productCode === "DW412") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
          });

          if (productColor === "Bamboo") {
            mat.material.color = new THREE.Color(0x8c8c8c);
          }

          if (productColor === "BlackGold") {
            mat.material.metalness = 0.8;
            mat.material.color = new THREE.Color(0xa6804e);
          }

          if (productColor === "WhiteRosegold") {
            mat.material.metalness = 0.8;
          }
        });
      } else if (productCode === "DW406" || productCode === "DW411") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),

            metalness: 0.5,
          });

          if (productColor === "RoseGold") {
          } else if (productColor === "StainlessSteel") {
            mat.material.color = new THREE.Color(0x8d9395);
          } else if (productColor === "Navy") {
            mat.material.color = new THREE.Color(0x64768c);
          } else if (productColor === "Gray") {
            mat.material.color = new THREE.Color(0x5b5e5f);
          }
        });
      } else if (productCode === "DW407") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
          });

          if (mat.name.includes("body_low")) {
            mat.material.roughness = 0.1;
            mat.material.metalness = 0.5;
          }

          if (productColor === "Chrome") {
            mat.material.color = new THREE.Color(0x888888);
          } else if (productColor === "Gold") {
            mat.material.color = new THREE.Color(0x595959);
          }
        });
      } else if (productCode === "DW402") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
            metalness: 1,
          });
        });
      } else if (productCode === "DW408") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),

            metalness: 0.5,
          });

          if (mat.name.includes("body_base_low")) {
            mat.material.encoding = THREE.sRGBEncoding;
          }
        });
      } else if (productCode === "DW308") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
            metalness: 1,
          });

          if (productColor === "Blue") {
            mat.material.color = new THREE.Color(0x2a4789);
          } else if (productColor === "Red") {
            mat.material.color = new THREE.Color(0x972a2f);
          }
        });
      }
    } else if (mesh.name.includes("body")) {
      if (productCode === "DW414") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
            metalness: 1,
          });

          if (productColor === "Bamboo") {
            mat.material.color = new THREE.Color(0xa6804e);
          }

          if (productColor === "Gold") {
            mat.material.color = new THREE.Color(0x595959);
          }
        });
      }
    } else if (mesh.name.includes("wrap_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_body_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_body_mat_Metallic.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_body_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_body_mat_Roughness.png"
        ),
      });

      if (productCode === "DW604") {
        mesh.material.color = new THREE.Color(0x81553a);
      } else if (productCode === "DW603") {
        mesh.material.color = new THREE.Color(0x4a352d);
      }
    } else if (mesh.name.includes("Nib_low") || mesh.name.includes("nib_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),
        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        productCode === "EC146" ||
        productCode === "EC117" ||
        productCode === "I154" ||
        productCode === "G3141"
      ) {
        mesh.material.metalness = 1;
        mesh.material.roughness = 0.5;
      }
    } else if (
      mesh.name.includes("Ball_low") ||
      mesh.name.includes("ball_low")
    ) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
      });

      if (productCode === "EC146" || productCode === "EC117") {
        mesh.material.metalness = 1;
        mesh.material.roughness = 0.5;
      } else if (productCode === "I154") {
        mesh.material.metalness = 0.25;
        mesh.material.roughness = 0.75;

        if (productColor === "BlackGold")
          mesh.material.color = new THREE.Color(0xf7d37c);
        if (productColor === "BlackRosegold")
          mesh.material.color = new THREE.Color(0xeb986a);
        if (productColor === "WhiteRosegold")
          mesh.material.color = new THREE.Color(0xeb986a);
      }
    } else if (mesh.name.includes("tip_low") || mesh.name.includes("Tip_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (productCode === "EC117") {
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x034e8c);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x9c9b73);
        if (productColor === "Grey")
          mesh.material.color = new THREE.Color(0x3d3f42);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xb55518);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xdb4258);
        if (productColor === "Natural")
          mesh.material.color = new THREE.Color(0xad9f84);
      } else if (productCode === "I154") {
        mesh.material.metalness = 1;
        mesh.material.roughness = 0.5;

        if (productColor === "BlackGold")
          mesh.material.color = new THREE.Color(0xfce09a);
        if (productColor === "BlackRosegold")
          mesh.material.color = new THREE.Color(0xfaddcd);
        if (productColor === "WhiteRosegold")
          mesh.material.color = new THREE.Color(0xfaddcd);
      } else if (productCode === "G3141") {
        mesh.material.metalness = 1;
        mesh.material.roughness = 0.5;
      }
    } else if (mesh.name.includes("head_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });
    } else if (mesh.name.includes("body_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (productCode === "I154") {
        if (productColor === "CharcoalChrome")
          mesh.material.color = new THREE.Color(0xffffff);
        if (productColor === "WhiteRosegold")
          mesh.material.color = new THREE.Color(0xffffff);
      }
    } else if (mesh.name.includes("clip_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
      });

      if (productCode === "EC117") {
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x034e8c);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x9c9b73);
        if (productColor === "Grey")
          mesh.material.color = new THREE.Color(0x3d3f42);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xb55518);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xdb4258);
        if (productColor === "Natural")
          mesh.material.color = new THREE.Color(0xad9f84);
      } else if (productCode === "I154") {
        mesh.material.metalness = 1;
        mesh.material.roughness = 0.5;

        if (productColor === "BlackGold")
          mesh.material.color = new THREE.Color(0xfce09a);
        if (productColor === "BlackRosegold")
          mesh.material.color = new THREE.Color(0xfaddcd);
        if (productColor === "WhiteRosegold")
          mesh.material.color = new THREE.Color(0xfaddcd);
      }
    } else if (mesh.name.includes("clip1_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
      });

      if (productCode === "G3141") {
        mesh.material.metalness = 0.75;
        mesh.material.roughness = 0.5;
      }
    } else if (mesh.name.includes("clip2_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
      });

      if (productCode === "G3141") {
        mesh.material.metalness = 0.75;
        mesh.material.roughness = 0.5;
      }
    } else if (mesh.name.includes("Barrel_low") && productCode !== "I150") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
      });

      if (productCode === "EC117")
        mesh.material.color = new THREE.Color(0xad9f84);
    } else if (mesh.name.includes("end_ball_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });
    } else if (mesh.name.includes("end_shaft_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

    } else if (mesh.name.includes("clicker_low")) {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
        aoMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_AO.png"
        ),
      });

      if (productCode === "EC117") {
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x034e8c);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x9c9b73);
        if (productColor === "Grey")
          mesh.material.color = new THREE.Color(0x3d3f42);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xb55518);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xdb4258);
        if (productColor === "Natural")
          mesh.material.color = new THREE.Color(0xad9f84);
      }

      if (productCode === "G3141") {
        mesh.material.metalness = 0.75;
        mesh.material.roughness = 0.5;
      }
    } else if (mesh.name.includes("Ballpoint_low")) {
      if (productCode === "G3148") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
        });
      }
    } else if (mesh.name.includes("Ballpoint_body_low")) {
      if (productCode === "G3148") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
        });
      }
    } else if (mesh.name.includes("Body_low")) {
      if (productCode === "G3148") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
          metalness: 1,
          roughness: 0.5,
        });
      }
    } else if (mesh.name.includes("Textured_body_low")) {
      if (productCode === "G3148") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_body_mat_Roughness.png"
          ),
        });
      }
    } else if (mesh.name.includes("Movable_low")) {
      if (productCode === "G3148") {
        mesh.children.forEach((mat, i) => {
          if (mat.name.includes("Cover_low")) {
            mat.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_Metallic.png"
              ),

              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_Normal.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_Roughness.png"
              ),
              metalness: 1,
              roughness: 0.5,
            });
          }

          if (mat.name.includes("Holder_low")) {
            mat.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_Metallic.png"
              ),

              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_Normal.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_cover_mat_Roughness.png"
              ),
              metalness: 1,
              roughness: 0.5,
            });
          }
        });
      }
    } else if (mesh.name.includes("opaque_grp")) {
      if (productCode === "G1282") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_misc_mat_Roughness.png"
            ),
          });
          if (mat.name.includes("nib_low") || mat.name.includes("ball_low")) {
            mat.material.metalness = 1;
            mat.material.roughness = 0.5;
          } else {
            if (productColor === "Green")
              mat.material.color = new THREE.Color(0x54c247);
            if (productColor === "Orange")
              mat.material.color = new THREE.Color(0xff6c2f);
            if (productColor === "Yellow")
              mat.material.color = new THREE.Color(0xf7d417);
          }
        });
      }
    } else if (productCode === "G1005") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Tip" ||
        mesh.name === "Clip" ||
        mesh.name === "Push_Chrome" ||
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Push_top"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.roughness = 0.8;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Grip" || mesh.name === "Push") {
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x0033ab);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x7eb852);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xcb1f35);
        if (productColor === "Gray")
          mesh.material.color = new THREE.Color(0x969491);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xd86018);
      }
    } else if (productCode === "G1009") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Tip" ||
        mesh.name === "Clip" ||
        mesh.name === "Push_Chrome" ||
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Push_top"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel" || mesh.name === "Push") {
        mesh.material.roughness = 0.9;
        mesh.material.metalness = 0.8;
      }
    } else if (productCode === "G1103") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Tip" ||
        mesh.name === "Clip" ||
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Metal_Ring_01" ||
        mesh.name === "Metal_Ring_02" ||
        mesh.name === "Top_Cap"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x0c47c7);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xff6d12);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xfc1e40);
      }

      if (mesh.name === "Barrel" || mesh.name === "Push") {
        if (productColor !== "White") {
          mesh.material.roughness = 0.9;
          mesh.material.metalness = 0.8;
        } else {
          mesh.material.roughness = 1;
          mesh.material.metalness = 0.2;
        }
      }
    } else if (productCode === "G1182") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Tip" ||
        mesh.name === "Clip" ||
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "MetalRing" ||
        mesh.name === "Clip_Attachment" ||
        mesh.name === "Clip_Connector" ||
        mesh.name === "Push_top"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel" || mesh.name === "Grip_low") {
        mesh.material.roughness = 0.9;
        mesh.material.metalness = 0.8;

        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x2daafa);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x15e876);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xde2634);
        if (productColor === "Silver") {
          if (mesh.name === "Barrel") {
            mesh.material.roughness = 0.7;
            mesh.material.metalness = 1;
          }
        }
      }
    } else if (productCode === "G1247") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Tip" ||
        mesh.name === "Clip" ||
        mesh.name === "Nib" ||
        mesh.name === "Ball"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel" || mesh.name === "Push") {
        mesh.material.roughness = 0.9;
        mesh.material.metalness = 0.9;

        if (productColor === "Black")
          mesh.material.color = new THREE.Color(0x454545);
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x048bcf);
        if (productColor === "GunMetal")
          mesh.material.color = new THREE.Color(0x827c78);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xf04d00);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xcd2131);
        if (productColor === "Silver") {
          mesh.material.roughness = 0.75;
          mesh.material.metalness = 1;
        }
      }
    } else if (productCode === "G1249") {
      if (mesh.name !== "Bottom_barrel") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_mat_Roughness.png"
          ),
        });
      } else {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_clear_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_clear_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_clear_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_clear_mat_Roughness.png"
          ),
          transparent: true,
          opacity: 0.9,
          roughness: 1,
        });
      }

      if (
        mesh.name === "Tip" ||
        mesh.name === "Pen_Clip" ||
        mesh.name === "NIB" ||
        mesh.name === "Ball" ||
        mesh.name === "Mid_Ring" ||
        mesh.name === "Push" ||
        mesh.name === "Upper_barrel"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Bottom_barrel") {
        if (productColor === "Black")
          mesh.material.color = new THREE.Color(0x302a27);
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x0c1a66);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x0d3d1f);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xc94102);
        if (productColor === "Purple")
          mesh.material.color = new THREE.Color(0x30114a);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0x630101);
        if (productColor === "Yellow")
          mesh.material.color = new THREE.Color(0x9c8403);
      }
    } else if (productCode === "G1254") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (mesh.name === "Nib" || mesh.name === "Ball") {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Clip" || mesh.name === "Barrel_Top")
        mesh.material.color = new THREE.Color(0x252326);

      if (mesh.name === "Barrel") {
        if (productColor === "Black")
          mesh.material.color = new THREE.Color(0x252326);
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x2857ad);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x889357);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xc95308);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xf74a56);
      }
    } else if (productCode === "G3001") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Tip" ||
        mesh.name === "Clip_low" ||
        mesh.name === "TopCap_low"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.roughness = 0.9;
        mesh.material.metalness = 0.9;

        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x237efa);
        if (productColor === "White") {
          mesh.material.roughness = 0.75;
          mesh.material.metalness = 0.2;
        }
      }
    } else if (productCode === "G3038") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Tip" ||
        mesh.name === "Clip_High_Curve" ||
        mesh.name === "Metal_Ring_01" ||
        mesh.name === "Metal_Ring_02" ||
        mesh.name === "InnerBarrel"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.roughness = 0.9;
        mesh.material.metalness = 0.9;

        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x00824a);
      }
    } else if (productCode === "G3108") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Tip" ||
        mesh.name === "Clip_high" ||
        mesh.name === "InnerBarrel"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel_Full" || mesh.name === "Top") {
        mesh.material.roughness = 0.9;
        mesh.material.metalness = 0.9;

        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x237efa);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xc90a21);
      }
    } else if (productCode === "G3113") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Tip" ||
        mesh.name === "Clip_Stopper" ||
        mesh.name === "Tip_Detail"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (
        mesh.name === "Barrel_Mid" ||
        mesh.name === "Barrel_01" ||
        mesh.name === "Clip" ||
        mesh.name === "Loop_Top"
      ) {
        mesh.material.roughness = 0.9;
        mesh.material.metalness = 0.9;
      }
    } else if (productCode === "HW204") {
      mesh.material = new THREE.MeshStandardMaterial({
        map: textureLoader.load(
          productCode + "_" + productColor + "_mat_BaseColor.png"
        ),
        metalnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Metallic.png"
        ),

        normalMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Normal.png"
        ),
        roughnessMap: textureLoader.load(
          productCode + "_" + productColor + "_mat_Roughness.png"
        ),
      });

      if (mesh.name === "Nib" || mesh.name === "Ball") {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (
        mesh.name === "Barrel_Bottom" ||
        mesh.name === "Barrel_top" ||
        mesh.name === "Barrel" ||
        mesh.name === "PushPart"
      ) {
        mesh.material.roughness = 0.8;
        mesh.material.metalness = 0.5;
      }

      if (mesh.name === "Barrel_Bottom" || mesh.name === "Barrel_top") {
        if (productColor === "Black")
          mesh.material.color = new THREE.Color(0x000000);
        if (productColor === "Blue")
          mesh.material.color = new THREE.Color(0x0033ab);
        if (productColor === "Green")
          mesh.material.color = new THREE.Color(0x0db02b);
        if (productColor === "Orange")
          mesh.material.color = new THREE.Color(0xe37100);
        if (productColor === "Red")
          mesh.material.color = new THREE.Color(0xba122b);
      }
    } else if (productCode === "HW205C") {
      if (
        mesh.name === "Grip" ||
        mesh.name === "Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      } else {
        mesh.children.forEach((mat, i) => {
          if (mat.name === "SprayNozzle") {
            mat.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_mat_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Metallic.png"
              ),
              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Normal.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_mat_Roughness.png"
              ),
            });
          } else {
            mat.material = new THREE.MeshStandardMaterial({
              map: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_BaseColor.png"
              ),
              metalnessMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_Metallic.png"
              ),
              normalMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_Normal.png"
              ),
              roughnessMap: textureLoader.load(
                productCode + "_" + productColor + "_clear_mat_Roughness.png"
              ),
              opacity: 0.45,
              transparent: true,
              roughness: 0,
            });
          }
        });
      }

      if (mesh.name === "Clip") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Metallic.png"
            ),
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_mat_Roughness.png"
            ),
            roughness: 0.75,
            metalness: 1,
          });
        });
      }

      if (mesh.name === "Nib" || mesh.name === "Ball") {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.roughness = 0.8;
        mesh.material.metalness = 0.5;
      }
    } else if (mesh.name.includes("transparent_grp")) {
      if (productCode === "G1282") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
          });
          if (productColor === "Green")
            mat.material.color = new THREE.Color(0x54c247);
          if (productColor === "Orange")
            mat.material.color = new THREE.Color(0xff6c2f);
          if (productColor === "Yellow")
            mat.material.color = new THREE.Color(0xf7d417);
        });
      }
    } else if (productCode === "EC122") {
      if (
        mesh.name === "EC122_v01_Clip_Barrel" ||
        mesh.name === "EC122_v01_Tip" ||
        mesh.name === "EC122_v01_Ball" ||
        mesh.name === "EC122_v01_Nib" ||
        mesh.name === "EC122_v01_Clip_01" ||
        mesh.name === "EC122_v01_Clip_02" ||
        mesh.name === "EC122_v01_End_Cap" ||
        mesh.name === "EC122_v01_Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (
        mesh.name === "EC122_v01_Nib" ||
        mesh.name === "EC122_v01_Ball" ||
        mesh.name === "EC122_v01_Clip_02" ||
        mesh.name === "EC122_v01_End_Cap" ||
        mesh.name === "EC122_v01_Tip"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (
        (mesh.name === "EC122_v01_Clip_Barrel" ||
          mesh.name === "EC122_v01_Clip_01") &&
        productColor === "Black"
      ) {
        mesh.material.color = new THREE.Color(0x000000);
        mesh.material.roughness = 1;
        mesh.material.metalness = 0.5;
      } else {
        mesh.material.roughness = 1;
        mesh.material.metalness = 0.5;
      }

      if (mesh.name === "EC122_v01_Barrel" && productColor === "Black") {
        mesh.material.color = new THREE.Color(0xcfa28d);
      }
    } else if (productCode === "EC131") {
      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Metal_Ring_01" ||
        mesh.name === "Metal_Ring_02" ||
        mesh.name === "InnerBarrel" ||
        mesh.name === "Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Clip" ||
        mesh.name === "Metal_Ring_01" ||
        mesh.name === "Metal_Ring_02" ||
        mesh.name === "InnerBarrel" ||
        mesh.name === "Tip"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.color = new THREE.Color(0xbf8d5a);
      }
    } else if (productCode === "I153") {
      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Top_TouchPad" ||
        mesh.name === "Top_Cap" ||
        mesh.name === "Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (
        mesh.name === "Nib" ||
        mesh.name === "Ball" ||
        mesh.name === "Clip" ||
        mesh.name === "Top_Cap" ||
        mesh.name === "Tip"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel" || mesh.name === "Top_TouchPad") {
        if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0xb71d37);
        } else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x75763a);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xf36a24);
        } else if (productColor === "Silver") {
          mesh.material.roughness = 0.8;
          mesh.material.metalness = 1;
        }
      }
    } else if (productCode === "I116") {
      if (
        mesh.name === "Clip1" ||
        mesh.name === "Tip1" ||
        mesh.name === "Ball1" ||
        mesh.name === "Nib1" ||
        mesh.name === "Touch_Pad1" ||
        mesh.name === "Metal_Ring_011" ||
        mesh.name === "Metal_Ring_021" ||
        mesh.name === "InnerBarrel1" ||
        mesh.name === "Barrel_Full"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (
        mesh.name === "Nib1" ||
        mesh.name === "Ball1" ||
        mesh.name === "Clip1" ||
        mesh.name === "Metal_Ring_011" ||
        mesh.name === "Metal_Ring_021" ||
        mesh.name === "InnerBarrel1" ||
        mesh.name === "Tip1"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      } else if (mesh.name === "Barrel_Full") {
        mesh.material.roughness = 1;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel_Full") {
        if (productColor === "NavyBlue") {
          mesh.material.color = new THREE.Color(0x346cb6);
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0x961b20);
        }
      }
    } else if (productCode === "I136") {
      if (
        mesh.name === "Clip1" ||
        mesh.name === "Tip1" ||
        mesh.name === "Ball1" ||
        mesh.name === "Nib1" ||
        mesh.name === "Touch_Pad1" ||
        mesh.name === "Metal_Ring_011" ||
        mesh.name === "Metal_Ring_021" ||
        mesh.name === "InnerBarrel1" ||
        mesh.name === "Barrel_Full"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (
        mesh.name === "Nib1" ||
        mesh.name === "Ball1" ||
        mesh.name === "Clip1" ||
        mesh.name === "Metal_Ring_011" ||
        mesh.name === "Metal_Ring_021" ||
        mesh.name === "InnerBarrel1" ||
        mesh.name === "Tip1"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      } else if (mesh.name === "Barrel_Full") {
        mesh.material.roughness = 1;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel_Full") {
        if (productColor === "Gold") {
          mesh.material.color = new THREE.Color(0xd4b07e);
        } else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0xd4d82b);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xf68020);
        }
      }
    } else if (productCode === "I151") {
      if (
        mesh.name === "Clip_low" ||
        mesh.name === "Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Touch_tip" ||
        mesh.name === "Cap" ||
        mesh.name === "Grip" ||
        mesh.name === "Push_part" ||
        mesh.name === "Highlighter" ||
        mesh.name === "Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Cap") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_clear_mat_Roughness.png"
          ),
          transparent: true,
          opacity: 0.9,
          roughness: 0.9,
          metalness: 1,
        });

        if (productColor === "Black") {
          mesh.material.color = new THREE.Color(0x404145);
        } else if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x1b265e);
        } else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x07b264);
        } else if (productColor === "Lime") {
          mesh.material.color = new THREE.Color(0xcbd92c);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xf14822);
        } else if (productColor === "Pink") {
          mesh.material.color = new THREE.Color(0xed3b7d);
        }
      } else if (mesh.name === "Grip" || mesh.name === "Touch_tip") {
        mesh.material.color = new THREE.Color(0x00000);
        mesh.material.roughness = 0.6;
        mesh.material.metalness = 1;
      } else if (
        mesh.name === "Barrel" ||
        mesh.name === "Clip_low" ||
        mesh.name === "Push_part"
      ) {
        if (productColor === "Black") {
          mesh.material.color = new THREE.Color(0x404145);
        } else if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x124a99);
        } else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x07b264);
        } else if (productColor === "Lime") {
          mesh.material.color = new THREE.Color(0xcbd92c);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xf14822);
        } else if (productColor === "Pink") {
          mesh.material.color = new THREE.Color(0xed3b7d);
        }
      }

      if (mesh.name === "Nib" || mesh.name === "Ball" || mesh.name === "Tip") {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }
    } else if (productCode === "I150") {
      if (
        mesh.name === "Clip_ring" ||
        mesh.name === "Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Top_TouchPad" ||
        mesh.name === "Clip" ||
        mesh.name === "Threaded_top" ||
        mesh.name === "Screwdriver_heads" ||
        mesh.name === "Screwdriver_Rubbe" ||
        mesh.name === "Top_Cap" ||
        mesh.name === "Grip_low" ||
        mesh.name === "Top_bump_low" ||
        mesh.name === "Level_lines" ||
        mesh.name === "Barrel_low"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      } else if (
        mesh.name === "Air_Bubble" ||
        mesh.name === "LevelBG" ||
        mesh.name === "Level"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_bubble_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_bubble_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_bubble_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_bubble_mat_Roughness.png"
          ),
          color: new THREE.Color(0x48733e),
        });
      }

      if (
        mesh.name === "Nib" ||
        mesh.name === "Top_Cap" ||
        mesh.name === "Ball" ||
        mesh.name === "Clip" ||
        mesh.name === "Clip_ring" ||
        mesh.name === "Top_bump_low" ||
        mesh.name === "Threaded_top" ||
        mesh.name === "Screwdriver_heads" ||
        mesh.name === "Screwdriver_Rubbe" ||
        mesh.name === "Grip_low" ||
        mesh.name === "Tip"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      } else if (mesh.name === "Barrel_low") {
        mesh.material.roughness = 0.75;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel_low") {
        if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x1b6db6);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xf68020);
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0xc12129);
        }
      }
    } else if (productCode === "I138") {
      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Metal_Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Metal_Rim_01" ||
        mesh.name === "Metal_Rim_02" ||
        mesh.name === "Metal_Rim_03" ||
        mesh.name === "Top" ||
        mesh.name === "Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Top") {
        mesh.material.color = new THREE.Color(0x000000);
      }

      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Metal_Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Metal_Rim_01" ||
        mesh.name === "Metal_Rim_02" ||
        mesh.name === "Metal_Rim_03"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.roughness = 0.6;
        mesh.material.metalness = 1;
        if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x008ac1);
        } else if (productColor === "DarkBlue") {
          mesh.material.color = new THREE.Color(0x2c3277);
        } else if (productColor === "Green348") {
          mesh.material.color = new THREE.Color(0x02924a);
        } else if (productColor === "Green367") {
          mesh.material.color = new THREE.Color(0x7baf41);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xdc8029);
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0xc2212a);
        } else if (productColor === "Yellow") {
          mesh.material.color = new THREE.Color(0xead218);
        }
      }
    }
    else if (productCode === "I128") {
      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Metal_Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Metal_Rim_01" ||
        mesh.name === "Metal_Rim_02" ||
        mesh.name === "Metal_Rim_03" ||
        mesh.name === "Top" ||
        mesh.name === "Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Top") {
        mesh.material.color = new THREE.Color(0x000000);
      }

      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Metal_Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Metal_Rim_01" ||
        mesh.name === "Metal_Rim_02" ||
        mesh.name === "Metal_Rim_03"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.roughness = 0.6;
        mesh.material.metalness = 1;
        if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x008ac1);
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0xe8283d);
        } else if (productColor === "Gold") {
          mesh.material.color = new THREE.Color(0xd4b07e);
        }
      }
    }
    else if (productCode === "I228") {
      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Metal_Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Metal_Rim_01" ||
        mesh.name === "Metal_Rim_02" ||
        mesh.name === "Metal_Rim_03" ||
        mesh.name === "Top" ||
        mesh.name === "Barrel"
      ) {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode + "_" + productColor + "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),

          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Roughness.png"
          ),
        });
      }

      if (mesh.name === "Top") {
        mesh.material.color = new THREE.Color(0x000000);
      }

      if (
        mesh.name === "Clip" ||
        mesh.name === "Tip" ||
        mesh.name === "Metal_Tip" ||
        mesh.name === "Ball" ||
        mesh.name === "Nib" ||
        mesh.name === "Metal_Rim_01" ||
        mesh.name === "Metal_Rim_02" ||
        mesh.name === "Metal_Rim_03"
      ) {
        mesh.material.roughness = 0.5;
        mesh.material.metalness = 1;
      }

      if (mesh.name === "Barrel") {
        mesh.material.roughness = 0.6;
        mesh.material.metalness = 1;
        if (productColor === "Blue") {
          mesh.material.color = new THREE.Color(0x4363ae);
        } else if (productColor === "Red") {
          mesh.material.color = new THREE.Color(0xf04a58);
        } else if (productColor === "Orange") {
          mesh.material.color = new THREE.Color(0xf05e0c);
        } else if (productColor === "Green") {
          mesh.material.color = new THREE.Color(0x658254);
        }
      }
    }
    else if (mesh.name.includes("transparent_grp")) {
      if (productCode === "G1282") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Metallic.png"
            ),

            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_body_mat_Roughness.png"
            ),
          });
          if (productColor === "Green")
            mat.material.color = new THREE.Color(0x54c247);
          if (productColor === "Orange")
            mat.material.color = new THREE.Color(0xff6c2f);
          if (productColor === "Yellow")
            mat.material.color = new THREE.Color(0xf7d417);
        });
      }
    } else if (mesh.name.includes("_low")) {
      if (productCode === "BG700") {
        mesh.material = new THREE.MeshStandardMaterial({
          map: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_BaseColor.png"
          ),
          metalnessMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Metallic.png"
          ),
          normalMap: textureLoader.load(
            productCode + "_" + productColor + "_mat_Normal.png"
          ),
          roughnessMap: textureLoader.load(
            productCode +
            "_" +
            productColor +
            "_mat_Roughness.png"
          ),
        });

        if (
          mesh.name.includes("top_front_low") ||
          mesh.name.includes("top_back_low") ||
          mesh.name.includes("side_right_low") ||
          mesh.name.includes("side_left_low")
        ) {
          mesh.material.color = new THREE.Color(0x808080);
        }
      }
    }
    else {
      if (productCode !== "HW205C") {
        mesh.children.forEach((mat, i) => {
          mat.material = new THREE.MeshStandardMaterial({
            map: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_BaseColor.png"
            ),
            metalnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Metallic.png"
            ),
            metalness: 1,
            normalMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Normal.png"
            ),
            roughnessMap: textureLoader.load(
              productCode + "_" + productColor + "_cap_mat_Roughness.png"
            ),
          });

          if (productCode === "DW319") {
            mat.material.color = new THREE.Color(0xab8750);
          }

          if (productCode === "DW306") {
            if (productColor === "BlackChrome" || productColor === "Gunmetal") {
            } else if (productColor === "BlackGold") {
              mat.material.color = new THREE.Color(0x766e52);
            } else if (productColor === "White") {
              mat.material.color = new THREE.Color(0x715447);
            }
          }
        });
      }
    }
  });


  useFrame(() => {
    const fbx = fbxRef.current;
    switch (productCode) {
      case 'DW321':
        if (isLoadingComplete) {
          fbx.rotation.y = -0.25;
        }
        break;
      case 'G1103':
      case 'EC122':
      case 'I138':
      case 'I128':
        if (isLoadingComplete) {
          fbx.rotation.y = 1;
        }
        break;
      case 'I228':
        if (isLoadingComplete) {
          fbx.rotation.y = 1;
        }
        break;
      case 'I153':
        if (isLoadingComplete) {
          fbx.rotation.y = 3.1;
        }
        break;
      case 'BGR710':
        if (isLoadingComplete) {
          fbx.rotation.x = 1.5;
        }
        break;
      default:
        break;
    }
  });


  return isLoadingComplete ? (<primitive object={copiedScene} scale={scale} position={position} ref={fbxRef} />) : null;
};
export default Product;
