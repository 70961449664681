import React, { useRef, useState, useMemo, useEffect } from "react"
import * as THREE from "three"
// import five from "../../builtModels/Red/DW304_DW304_Eva_foam_mat_normalColor.png"
import base from "../../builtModels/Ora_pad_mat_BaseColor.png";
import normal from "../../builtModels/Ora_pad_mat_Normal.png";
import metalness from "../../builtModels/Ora_pad_mat_Metallic.png";
import roughness from "../../builtModels/Ora_pad_mat_Roughness.png";
import height from "../../builtModels/Ora_pad_mat_Height.png";

const EvaFoam = (props) => {
    const mesh = useRef()

    const [active, setActive] = useState(false)
    const [zoom, setZoom] = useState(1)

    // const texture = useMemo(() => new THREE.TextureLoader().load(five), [])

    const basePad = useMemo(() => new THREE.TextureLoader().load(base), [])
    const normalPad = useMemo(() => new THREE.TextureLoader().load(normal), [])
    const metalnessPad = useMemo(() => new THREE.TextureLoader().load(metalness), [])
    const roughnessPad = useMemo(() => new THREE.TextureLoader().load(roughness), [])
    const heightPad = useMemo(() => new THREE.TextureLoader().load(height), [])

    let args = [0.7, 380]

    if (props.productCode === 'DW306') {
        args = [0.5, 380]
    } else if (props.productCode === 'DW309') {
        args = [0.625, 380]
    } else if (props.productCode === 'DW310') {
        args = [0.8, 380]
    } else if (props.productCode === 'DW301') {
        args = [0.7, 380]
    } else if (props.productCode === 'DW300') {
        args = [0.625, 380]
    } else if (props.productCode === 'DW308') {
        args = [0.59, 380]
    } else if (props.productCode === 'DW401') {
        args = [0.69, 380]
    } else if (props.productCode === 'DW413') {
        args = [0.675, 380]
    } else if (props.productCode === 'DW307') {
        args = [0.75, 380]
    } else if (props.productCode === 'DW412') {
        args = [0.775, 380]
    } else if (props.productCode === 'DW322') {
        args = [0.6, 380]
    } else if (props.productCode === 'DW323') {
        args = [0.51, 380]
    } else if (props.productCode === 'DW415') {
        args = [0.63, 380]
    } else if (props.productCode === 'DW416') {
        args = [0.5, 380]
    }
    else if (props.productCode === 'DW325') {
        args = [0.74, 380]
    }

    useEffect(() => {
        mesh.current.rotation.x = Math.PI / 2;
    }, [])

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={[zoom, zoom, zoom]}
            onClick={(e) => setActive(!active)}
        >
            <circleBufferGeometry args={args} />
            <meshStandardMaterial transparent
                map={basePad}
                normalMap={normalPad}
                metalnessMap={metalnessPad}
                roughnessMap={roughnessPad}
                heightMap={heightPad}
            >
                {/* <primitive attach="map" object={texture} /> */}
            </meshStandardMaterial>
        </mesh>
    )
}

export default EvaFoam