import { Canvas, useFrame, useThree } from "@react-three/fiber";
import React, {
  useRef,
  Suspense,
  forwardRef,
  useImperativeHandle,
  useState
} from "react";
import { OrbitControls, Environment, Html } from "@react-three/drei";
import * as THREE from "three";
import Swal from "sweetalert2";
import EvaFoam from "./EvaFoam";
import Product from "./Product";
import Logo from "./BottleLogo/Logo";
import LogoCenteredBack from "./BottleLogo/LogoCenteredBack";
import LogoCenteredCap from "./BottleLogo/LogoCenteredCap";
import LogoCenteredBottom from "./BottleLogo/LogoCenteredBottom";
import LogoCenteredUpperBody from "./BottleLogo/LogoCenteredUpperBody";
import LogoCenteredClip from "./BottleLogo/LogoOnClip";
import LogoCenteredRight from "./BagsLogo/LogoCenteredRight";
import LogoCenteredLeft from "./BagsLogo/LogoCenteredLeft";
import LogoCenteredAnchor from "./BagsLogo/LogoCenteredAnchor";
import LogoCenteredTrolley from "./BagsLogo/LogoCenteredTrolley";
import LogoCenteredFlap from "./BagsLogo/LogoCenteredFlap";
// import { useControls } from "leva";




const Snapshot = forwardRef((props, ref) => {
  const { gl, scene, camera } = useThree();
  async function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  useImperativeHandle(ref, () => ({
    async shareProduct() {
      Swal.fire({
        title: "Share your masterpiece",
        text: "Share your design",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: '<i class="bi bi-share"></i> Share',
      }).then(async (result) => {
        if (result.isConfirmed) {
          gl.setClearColor("white", 0);
          gl.render(scene, camera);
          gl.outputEncoding = THREE.sRGBEncoding;
          gl.preserveDrawingBuffer = true;
          var fileNamez = sessionStorage.getItem("productCode");
          var imahes = gl.domElement.toDataURL("image/png");

          const newFile = await dataURLtoBlob(imahes);
          const data = {
            files: [
              new File([newFile], fileNamez + ".png", {
                type: newFile.type,
              }),
            ],
            title: "Bottle for your client",
            text: "Check out this bottle I mocked up for you!",
          };
          try {
            if (!navigator.canShare(data)) {
              console.error("Can't share");
            }
            navigator.share(data);
          } catch (err) {
            console.error(err);
          }
        } else {
          Swal.close();
        }
      });
    },

    takeScreenshot() {
      Swal.fire({
        title: "Save your masterpiece",
        text: "Download your image with either a white or transparent background",
        icon: "info",
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: '<i class="bi bi-image"></i> Transparent',
        denyButtonText: '<i class="bi bi-image-fill"></i> White',
      }).then((result) => {
        if (result.isDenied) {
          gl.setClearColor("white");
          gl.render(scene, camera);
          gl.outputEncoding = THREE.sRGBEncoding;
          gl.preserveDrawingBuffer = true;
          const currentDate = new Date();
          let currentDateTime =
            currentDate.getDate() +
            "/" +
            (currentDate.getMonth() + 1) +
            "/" +
            currentDate.getFullYear() +
            "-" +
            currentDate.getHours() +
            "-" +
            currentDate.getMinutes() +
            "-" +
            currentDate.getSeconds();
          const destCanvas = document.createElement("canvas");
          destCanvas.width = 800;
          destCanvas.height = 800;

          const sourceImageData = gl.domElement.toDataURL("image/png");

          const destinationImage = new Image();
          destinationImage.onload = function () {
            const canvasWidth = destinationImage.width;
            const canvasHeight = destinationImage.height;

            // Adjust zoom factor for zoom-out effect
            const zoomFactor = 1; // You can adjust this value as needed for the zoom-out effect

            // Create an intermediate canvas for the zoom effect
            const intermediateCanvas = document.createElement("canvas");
            intermediateCanvas.width = canvasWidth * zoomFactor;
            intermediateCanvas.height = canvasHeight * zoomFactor;

            const intermediateCanvasContext = intermediateCanvas.getContext("2d");

            // Draw the zoomed image onto the intermediate canvas
            intermediateCanvasContext.drawImage(
              destinationImage,
              0,
              0,
              intermediateCanvas.width,
              intermediateCanvas.height
            );

            // Create the final canvas with the original canvas size
            const finalCanvas = document.createElement("canvas");
            finalCanvas.width = destCanvas.width;
            finalCanvas.height = destCanvas.height;

            const finalCanvasContext = finalCanvas.getContext("2d");

            // Calculate new centerX and centerY for always centered image
            const centerX = Math.floor((finalCanvas.width - intermediateCanvas.width) / 2);
            const centerY = Math.floor((finalCanvas.height - intermediateCanvas.height) / 2);

            // Draw the intermediate canvas onto the final canvas
            finalCanvasContext.drawImage(
              intermediateCanvas,
              centerX,
              centerY,
              intermediateCanvas.width,
              intermediateCanvas.height
            );

            finalCanvas.toBlob((blob) => {
              const a = document.createElement("a");
              const url = URL.createObjectURL(blob);
              a.href = url;
              a.download = "documentmerch_" + currentDateTime;
              a.click();
            });
          };
          destinationImage.src = sourceImageData;

        } else if (result.isConfirmed) {
          gl.setClearColor("white", 0);
          gl.render(scene, camera);
          gl.outputEncoding = THREE.sRGBEncoding;
          gl.preserveDrawingBuffer = true;
          const currentDate = new Date();
          let currentDateTime =
            currentDate.getDate() +
            "/" +
            (currentDate.getMonth() + 1) +
            "/" +
            currentDate.getFullYear() +
            "-" +
            currentDate.getHours() +
            "-" +
            currentDate.getMinutes() +
            "-" +
            currentDate.getSeconds();
          const destCanvas = document.createElement("canvas");
          destCanvas.width = 800;
          destCanvas.height = 800;
          const sourceImageData = gl.domElement.toDataURL("image/png");
          const destinationImage = new Image();
          destinationImage.onload = function () {
            const canvasWidth = destinationImage.width;
            const canvasHeight = destinationImage.height;
            // Adjust zoom factor for zoom-out effect
            const zoomFactor = 1;
            // Adjust width and height for zoom effect
            const zoomedWidth = canvasWidth * zoomFactor;
            const zoomedHeight = canvasHeight * zoomFactor;
            const finalCanvas = document.createElement("canvas");
            finalCanvas.width = destCanvas.width;
            finalCanvas.height = destCanvas.height;
            const finalCanvasContext = finalCanvas.getContext("2d");
            const centerX = Math.floor((finalCanvas.width - zoomedWidth) / 2);
            const centerY = Math.floor((finalCanvas.height - zoomedHeight) / 2);

            finalCanvasContext.drawImage(
              destinationImage,
              centerX,
              centerY,
              zoomedWidth,
              zoomedHeight
            );

            finalCanvas.toBlob((blob) => {
              const a = document.createElement("a");
              const url = URL.createObjectURL(blob);
              a.href = url;
              a.download = "documentmerch_" + currentDateTime;
              a.click();
            });
          };
          destinationImage.src = sourceImageData;
        } else {
          Swal.close();
        }
      });
    },

    changeRotation(position) {
      if (position === "CENTERED ON FRONT" || position === "CENTERED NEXT TO HANDLE - RIGHT HANDED" || position === "CENTERED ON FRONT TOP" || position === "CENTERED BELOW CARRY HANDLE" || position === "CENTERED OPPOSITE OF PHONE STAND IN USE" || position === "FRONT OF THE SILICONE BOOT" || position === "ENLARGED AREA SIDE OF BARREL" || position === "CENTERED FRONT RIGHT POCKET" || position === "CENTERED FRONT LEFT POCKET" || position === "CENTERED BETWEEN ANCHORS - FRONT" || position === "CENTERED ON BOTTLE NECK" || position === "SILICONE BAND"
        || position === "CENTERED ON FRONT BETWEEN STRAPS" || position === "CENTERED ON CLOSING FLAP" || position === "CENTERED ABOVE POCKET" || position === "CENTERED FRONT POCKET" || position === "CENTERED ON FRONT POCKET" || position === "CENTERED BETWEEN ANCHORS - FRONT" || position === "CENTERED ON STRAP STABILIZER" || position === "CENTERED ON FRONT OF POUCH" || position === "CENTER FRONT TOP POCKET" || position === "CENTER FRONT BOTTOM POCKET" || position === "CENTERED BELOW SPOUT") {
        if (sessionStorage.getItem("productCode") === "DW408") {
          camera.position.set(
            -10.912963223004311,
            1.3738422516705024,
            -0.14068106087524515
          );
        } else if (
          sessionStorage.getItem("productCode") === "G3141" ||
          sessionStorage.getItem("productCode") === "G1282" ||
          sessionStorage.getItem("productCode") === "I154" ||
          sessionStorage.getItem("productCode") === "EC117" ||
          sessionStorage.getItem("productCode") === "EC146"
        ) {
          camera.position.set(
            0.04034605378228862,
            0.2430530544605652,
            -10.997240445159935
          );
        } else {
          camera.position.set(0, 6.735557395310443e-16, 11);
        }
      } else if (position === "CENTERED ON CAP" || position === "CENTERED ON LID") {
        camera.position.set(
          -1.9630959459920622e-17,
          10.947650643294331,
          1.071888703353698
        );
      } else if (
        position === "CENTERED ON FRONT BOTTOM" ||
        position === "IN LINE WITH CLIP" ||
        position === "ON CLIP"
      ) {
        if (
          sessionStorage.getItem("productCode") === "G1103" ||
          sessionStorage.getItem("productCode") === "G3001" ||
          sessionStorage.getItem("productCode") === "G3108" ||
          sessionStorage.getItem("productCode") === "G3113" ||
          sessionStorage.getItem("productCode") === "EC122" ||
          sessionStorage.getItem("productCode") === "EC131" ||
          sessionStorage.getItem("productCode") === "I153"
        ) {
          camera.position.set(
            -10.898804430407392,
            0.4213300705903961,
            -1.4277755283478473
          );
        }
        else {
          camera.position.set(0, 6.735557395310443e-16, 11);
        }
      } else if (
        position === "CENTERED ON UPPER BODY" ||
        position === "BESIDE CLIP" ||
        position === "SIDE OF BARREL"
      ) {
        if (
          sessionStorage.getItem("productCode") === "G3141" ||
          sessionStorage.getItem("productCode") === "G3148" ||
          sessionStorage.getItem("productCode") === "I154" ||
          sessionStorage.getItem("productCode") === "EC146" ||
          sessionStorage.getItem("productCode") === "EC117" ||
          sessionStorage.getItem("productCode") === "G1282"
        ) {
          camera.position.set(
            10.912963223004311,
            1.3738422516705024,
            -0.14068106087524515
          );
        } else {
          camera.position.set(0, 6.735557395310443e-16, 11);
        }
      }
      else if (position === "CENTERED ON BACK BETWEEN STRAP") {
        camera.position.set(
          0.04034605378228862,
          0.2430530544605652,
          -10.997240445159935
        );
      }
      else if (position === "CENTERED ON SHOULDER STRAP FLAP") {
        camera.position.set(0.025235793456845027, 5.737633487914955, -5.114668375183032);
      }
      else if (position === "CENTER ON SIDE POCKET") {
        camera.position.set(-11.653347373585651, 1.3870476979426463, 1.951455205129788);
      }
      else if (position === "CENTERED ON SIDE POCKET") {
        camera.position.set(12.002397329388764, 0.3292847432054024, -0.6455759371684262);
      }
      else if (position === "CENTERED BELOW GRAB HANDLE") {
        camera.position.set(-11.950980827641702, 0.9556649810250804, -0.9184226078783337);
      }
      else {
        camera.position.set(
          0.04034605378228862,
          0.2430530544605652,
          -10.997240445159935
        );
      }
    },

    resetView() {
      let currentView = sessionStorage.getItem("currentView");

      if (currentView === "CENTERED ON FRONT") {
        if (sessionStorage.getItem("productCode") === "DW408") {
          camera.position.set(
            -10.912963223004311,
            1.3738422516705024,
            -0.14068106087524515
          );
        } else {
          camera.position.set(0, 0.235557395310443e-16, 11);
        }
      } else if (currentView === "CENTERED ON CAP") {
        camera.position.set(
          -1.9630959459920622e-17,
          10.947650643294331,
          1.071888703353698
        );
      } else if (currentView === "CENTERED ON FRONT BOTTOM") {
        camera.position.set(0, 6.735557395310443e-16, 11);
      } else if (currentView === "CENTERED ON UPPER BODY") {
        camera.position.set(0, 6.735557395310443e-16, 11);
      } else {
        camera.position.set(
          0.04034605378228862,
          0.2430530544605652,
          -10.997240445159935
        );
      }
    },
  }));

  return <mesh />;
});
const Canvas3D = forwardRef((props, ref) => {
  const [withBackground, setWithBackground] = useState(false);
  const snapshotRef = useRef();
  const view = useRef();
  const canvas = useRef();
  const zoomed = false;
  let productCode = "DW305";
  let productColor = "WhitePearl";
  let process = "4CP";
  let ambientLight = 0.3;


  if (props.productCode) {
    productCode = props.productCode;
  }
  if (props.productColor) {
    productColor = props.productColor;
  }
  let frontBottomLogo;
  let frontUpperLogo;
  let frontLogo;
  let backLogo;
  let capLogo;
  let clipLogo;
  let frontLogoRight;
  let frontLogoLeft;
  let frontLogoAnchor
  let frontLogoTrolley;
  let frontLogoFlap;


  let brandingProperties = props.brandingProperties;
  let logoClipPos = [0, -11, 0];
  let logoCapPos = [0, 3.185, 0];
  let logoFrontPos = [0, 0, 0];
  let logoFrontBottomPos = [0, -11, 0];
  let logoFrontUpperPos = [0, -11, 0];
  let logoBackPos = [0, 0, 0];
  let evaFoamPos = [0, -3.06, 0];
  let logoFrontRightPos = [0, 0, 0]
  let logoFrontLeftPos = [0, 0, 0]
  let logoFrontAnchorPos = [0, 0, 0]
  let logoFrontTrolleyPos = [0, 0, 0]
  let logoFrontFlapPos = [0, 0, 0]
  const showStrap = JSON.parse(sessionStorage.getItem('showStrap'))
  let cameraX = -0.5908180946984
  let cameraY = 2.392256127375398
  let cameraZ = 11.591001381224146


  if (productCode === "DW312") {
    logoFrontPos = [0, -0.2, -0.00605];
    logoBackPos = [0, -0.2, 0.007];
  } else if (productCode === "DW409") {
    logoFrontPos = [0, 0.35, 0];
    logoBackPos = [0, 0.35, 0];
  } else if (productCode === "DW410") {
    logoFrontPos = [0, 0.9, -0.00915];
    logoBackPos = [0, 0.9, 0.00915];
  } else if (productCode === "DW412") {
    logoFrontPos = [0, 0.8, -0.00915];
    logoBackPos = [0, 0.8, 0.00915];
    evaFoamPos = [0, -2.25, 0];
  } else if (productCode === "DW414") {
    logoFrontPos = [0, -0.5, 0];
    logoBackPos = [0, -0.5, 0];
  } else if (productCode === "DW415") {
    evaFoamPos = [0.15, -2.57, 0.06];
    logoFrontPos = [0.142, -0.19, 0.048];
    logoBackPos = [0.142, -0.19, 0.04];
  } else if (productCode === "DW319") {
    logoFrontPos = [0, -1, 0];
    logoBackPos = [0, -1, 0];
    logoCapPos = [0, 2.8125, 0];
    logoFrontBottomPos = [0, -11, 0];
  } else if (productCode === "DW316") {
    logoFrontUpperPos = [0, 0.69, 0];
    logoFrontBottomPos = [0.00, -1.60, 0.01];
    logoCapPos = [0, 2.6415, -0.025];
  } else if (productCode === "DW317") {
    logoFrontPos = [0.00, 0.70, 0.00];
    logoFrontBottomPos = [0.00, -1.60, 0.01];
  } else if (productCode === "DW311") {
    logoCapPos = [0, 2.46, 0];
    logoFrontPos = [0, -1.45, 0];
    logoBackPos = [0, -1.45, 0];
    logoFrontUpperPos = [0, 0.45, 0.00];
  } else if (productCode === "DW309") {
    logoFrontPos = [0, -1.45, 0];
    logoBackPos = [0, -1.45, 0];
  } else if (productCode === "DW314") {
    logoCapPos = [0, 2.6575, 0];
    logoFrontPos = [0.01, -0.725, 0];
    logoBackPos = [0.01, -0.725, 0];
  } else if (productCode === "DW402") {
    logoFrontPos = [0, 0.85, -0.00915];
    logoBackPos = [0, 0.85, 0.00915];
  } else if (productCode === "DW403") {
    logoFrontPos = [0, 0.5, 0.001];
    logoBackPos = [0, 0.5, -0.001];
  } else if (productCode === "DW413") {
    logoFrontPos = [0, 0.5, 0.001];
    logoBackPos = [0, 0.5, -0.001];
    evaFoamPos = [0, -1.275, 0];
  } else if (productCode === "DW404") {
    logoFrontPos = [0, 0.5, 0.001];
    logoBackPos = [0, 0.5, -0.001];
    evaFoamPos = [0, -1.275, 0];
  } else if (
    productCode === "DW406" ||
    productCode === "DW407" ||
    productCode === "DW408"
  ) {
    logoFrontPos = [0, 0.4, 0];
    logoBackPos = [0, 0.4, 0];
    evaFoamPos = [0, -1.26, 0];
  } else if (productCode === "DW411") {
    logoFrontPos = [0, 0.4, 0];
    logoBackPos = [0, 0.4, 0];
    evaFoamPos = [0, -1.26, 0];
  } else if (productCode === "DW306") {
    logoFrontPos = [0, -0.85, 0];
    logoBackPos = [0, -0.85, 0];
    logoCapPos = [0, 2.815, 0];
  } else if (productCode === "DW305") {
    logoFrontPos = [0.00, -0.35, -0.05];
    logoBackPos = [0.00, -0.35, 0.05];
    logoCapPos = [0.00, 2.77, 0.00];
    evaFoamPos = [0.00, -3.11, 0.00]
  }
  else if (productCode === "DW101") {
    logoFrontPos = [0, -0.85, 0];
    logoBackPos = [0, -0.85, 0];
  } else if (productCode === "DW301") {
    logoFrontPos = [0, -0.3, 0];
    logoBackPos = [0, -0.3, 0];
  } else if (productCode === "DW103") {
    logoFrontPos = [0, -3, 0];
    logoBackPos = [0, -3, 0];
  } else if (productCode === "DW401") {
    logoFrontPos = [0, -0.7, 0];
    logoBackPos = [0, -0.7, 0];
  } else if (productCode === "DW307") {
    evaFoamPos = [0, -3.0485, 0];
    logoFrontPos = [0, -3.1, 0.01];
    logoBackPos = [0.00, -3.10, -0.01];
  } else if (productCode === "DW604" || productCode === "DW603") {
    logoFrontPos = [0, -0.075, 0];
  }
  else if (productCode === "DW304") {
    logoFrontPos = [0, -0.25, 0];
    logoBackPos = [0, -0.25, 0];
    evaFoamPos = [0, -2.005, 0];
    logoCapPos = [0, 2.05, 0];
  } else if (productCode === "DW320") {
    logoCapPos = [0, 3.2, 0];
  } else if (productCode === "DW313") {
    logoFrontPos = [0, 0, 0];
    logoBackPos = [0, 0, 0];
    logoFrontBottomPos = [0, -2.15, 0.064];
  } else if (productCode === "G1247") {
    logoFrontPos = [0, 0.6, 0];
  } else if (productCode === "G3141") {
    logoBackPos = [0.00, 0.50, 0.00];
    logoFrontBottomPos = [0, -0.3, 0];
    logoFrontUpperPos = [0, 1, 0];
  }
  else if (productCode === "G1298") {
    logoFrontUpperPos = [0.02, 1.10, 0.00];
  }
  else if (productCode === "G1254") {
    logoFrontPos = [0.03, 0.4, 0.06];
  } else if (productCode === "G3001") {
    logoFrontPos = [0, 0, 0];
    logoFrontBottomPos = [0, 0.2, 0];
  } else if (productCode === "G3113") {
    logoClipPos = [-0.275, 1.7, 0];
    logoFrontPos = [0, 0, 0];
    logoFrontUpperPos = [0, 1.6, 0];
    logoFrontBottomPos = [0, 0.2, 0];
  } else if (productCode === "G3038") {
    logoFrontPos = [0, 0.05, 0];
    logoFrontUpperPos = [0, 1.2, 0];
  } else if (productCode === "EC122") {
    logoFrontPos = [0, 1.30, 0.01];
    logoFrontUpperPos = [0, 1, 0];
    logoFrontBottomPos = [0, -0.25, 0];
  } else if (productCode === "EC131") {
    logoFrontUpperPos = [0, 1.5, 0];
    logoFrontBottomPos = [0, 0.275, 0];
  } else if (productCode === "I153") {
    logoFrontPos = [0, 0.30, 0.01];
    logoFrontUpperPos = [0, 1.15, 0];
    logoFrontBottomPos = [0, -0.3, 0];
  }
  else if (productCode === "I160") {
    logoFrontPos = [-0.055, 0.58, 0.01];
    logoFrontUpperPos = [-0.055, 1.15, 0];
  }
  else if (productCode === "I116") {
    logoFrontPos = [0, 0.2, 0];
    logoFrontUpperPos = [0, 1.5, 0];
  } else if (productCode === "I136") {
    logoFrontPos = [0, 0.2, 0];
    logoFrontUpperPos = [0, 1.5, 0];
  } else if (productCode === "G3108") {
    logoFrontBottomPos = [0, -0.1, 0];
    logoFrontUpperPos = [0, 1.85, 0];
  } else if (productCode === "G1249") {
    logoFrontUpperPos = [-0.01, 0.8, -0.25];
    logoBackPos = [-0.01, 0.8, -0.08];
  } else if (productCode === "HW204") {
    logoFrontUpperPos = [-0.01, 1.25, -0.115];
  } else if (productCode === "G3148") {
    logoFrontBottomPos = [0, 0, 0];
    logoFrontUpperPos = [0, 2, 0];
  } else if (productCode === "G1282") {
    logoFrontBottomPos = [0, 0.25, 0];
    logoFrontPos = [0, 0.2, 0];
  } else if (productCode === "G1005" || productCode === "G1009") {
    logoFrontPos = [0, 0.6, 0];
  } else if (productCode === "G1103") {
    logoFrontPos = [0, 1.75, 0.01];
    logoFrontBottomPos = [0, 0, 0];
    logoFrontUpperPos = [0, 1.85, 0];
  } else if (productCode === "G1182") {
    logoFrontPos = [0, 0.4, 0];
    logoFrontUpperPos = [0, 1.125, 0];
  } else if (productCode === "I154") {
    logoFrontUpperPos = [0, 1, 0];
    logoFrontBottomPos = [0, -0.5, 0];
    logoFrontPos = [0, 0.25, 0];
  } else if (productCode === "EC117") {
    logoFrontPos = [-0.07, -0.5, 0];
    logoClipPos = [0, 1.85, 0.38];
  } else if (productCode === "EC146") {
    logoFrontBottomPos = [0, 0, 0];
    logoFrontUpperPos = [0, 1.6, 0];
    logoFrontPos = [0, 0.5, 0];
  } else if (productCode === "DW321") {
  } else if (productCode === "DW322") {
    logoCapPos = [0, 3.1, 0];
    evaFoamPos = [0, -3.105, 0];
  } else if (productCode === "DW323") {
    logoFrontPos = [0, -0.5, 0];
    evaFoamPos = [0, -3.095, 0];
  } else if (productCode === "DW416") {
    logoFrontPos = [0, -0.5, -0.14];
    logoBackPos = [0.00, -0.50, 0.14]
    evaFoamPos = [0, -3.1, 0];
  } else if (productCode === "I151") {
    logoFrontPos = [0, 0.5, 0];
  } else if (productCode === "I150") {
    logoFrontPos = [0, 0.5, 0];
  } else if (productCode === "I138") {
    logoFrontUpperPos = [0, 1, 0];
  } else if (productCode === "I128" || productCode === "I228") {
    logoFrontPos = [0, 0.225, 0];
    logoFrontUpperPos = [0, 1.25, 0];
  } else if (productCode === "DW201") {
    evaFoamPos = [-0.05, -2.5, 0.46];
    logoFrontPos = [-0.05, -0.09, -0.18];
    logoBackPos = [-0.05, -0.19, 0.11]
  } else if (productCode === "DW326") {
    logoFrontPos = [0, -1.4, 0];
  } else if (productCode === "DW325") {
    logoFrontPos = [-0.06, -0.80, -0.02];
    evaFoamPos = [-0.05, -2.96, 0.00];
  } else if (productCode === "DW324") {
    logoFrontPos = [0, -0.7, -1.275];
  } else if (productCode === "DW104") {
    logoFrontPos = [0, -0.6, 0];
  } else if (productCode === "DW405") {
    logoFrontPos = [0, 0.25, -0.24];
  } else if (productCode === "DW417") {
    logoFrontPos = [0, -0.1, -0.961];
  } else if (productCode === "DW418") {
    logoFrontPos = [0, 0.25, 0.17];
  } else if (productCode === "DW500") {
    logoFrontPos = [0, -0.8, 0.0775];
  } else if (productCode === "DW502") {
    logoFrontPos = [0.00, -0.75, -0.02];
  }
  else if (productCode === "BG500") {
    logoFrontRightPos = [1.06, -1.36, 0.52];
    logoFrontLeftPos = [-0.87, -1.36, 0.49];
    logoFrontAnchorPos = [0.10, 0.15, 0.27];
    logoFrontTrolleyPos = [0.10, -0.74, -0.54]
  }
  else if (productCode === "BGR710") {
    logoFrontRightPos = [-0.646, 1.42, -1.04];
    logoFrontLeftPos = [-0.61, 0.32, -0.93];
    logoBackPos = [-0.34, -0.34, -0.93]
  }
  else if (productCode === "BG504") {
    logoFrontAnchorPos = [-0.01, -0.80, 0.299];
  }
  else if (productCode === "BG201") {
    logoFrontAnchorPos = [-0.20, -0.85, 0.7568];
    logoFrontLeftPos = [0.1434, -1.29, -0.39]
    logoFrontRightPos = [0.1046, -0.99, -0.37]
    cameraX = -0.6258397431468316
    cameraY = 1.3311188427222467
    cameraZ = 9.315716075969249
    if (showStrap) {
      logoFrontTrolleyPos = [0.00, -0.446, 1.28]
    } else {
      logoFrontTrolleyPos = [-0.02, -0.31, 1.517]
    }
  }
  else if (productCode === "BGR104") {
    logoFrontAnchorPos = [-0.16, -1.49, 0.886];
    logoFrontTrolleyPos = [-0.16, -1.58, 1.01]
    logoFrontFlapPos = [-0.18, -1.79, 1.0074]
    logoFrontLeftPos = [-0.624, -1.00, 1.65]
    cameraX = -0.6258397431468316
    cameraY = 1.3311188427222467
    cameraZ = 9.315716075969249
  }
  else if (productCode === "BG104") {
    logoFrontAnchorPos = [-0.16, -1.49, 0.886];
    logoFrontTrolleyPos = [-0.16, -1.58, 1.01]
    logoFrontFlapPos = [-0.18, -1.79, 1.069]
    logoFrontLeftPos = [-0.623, -1.00, 1.65]
    cameraX = -0.6258397431468316
    cameraY = 1.3311188427222467
    cameraZ = 9.315716075969249
  }
  else if (productCode === "BG206") {
    logoFrontAnchorPos = [-0.10, -0.82, 1.01];
    logoFrontTrolleyPos = [-0.10, -0.87, -1.3099]
    if (sessionStorage.getItem("rectangleType") === "vertical") {
      logoFrontFlapPos = [0.62, 0.7756, -0.40];
    }
    else {
      console.log('NOT')
      logoFrontFlapPos = [-0.07, 0.911, 0.22];
    }
  }
  else if (productCode === "BGR204") {
    logoFrontAnchorPos = [-0.16, -0.94, 0.122];
    logoFrontTrolleyPos = [0.56, -0.91, 0.71]
    logoFrontRightPos = [-2.218, -0.35, -0.30]
    logoFrontLeftPos = [-0.671, -1.15, -0.09]
  }
  else if (productCode === "BG116") {
    logoFrontAnchorPos = [0.13, 0.22, 0.685];
    ambientLight = 1
  }
  else if (productCode === "BG204") {
    logoFrontAnchorPos = [-0.13, -0.90, 0.115];
    logoFrontTrolleyPos = [0.56, -0.91, 1.072]
    logoFrontRightPos = [-2.2155, -0.35, -0.30]
    logoFrontLeftPos = [-0.155, -1.15, -0.09]
  }
  else if (productCode === "BG600") {
    logoFrontAnchorPos = [0.04, -0.35, 1.285];
    cameraX = -0.6786019998804772
    cameraY = 3.250509590342795
    cameraZ = 10.52279921060357
  }
  else if (productCode === "BG700") {
    logoFrontAnchorPos = [0.08, -0.80, -0.221];
  }
  else if (productCode === "BG706") {
    logoFrontAnchorPos = [-0.20, -0.85, 0.714];
  }
  else if (productCode === "BGR101") {
    logoFrontAnchorPos = [-0.20, -1.11, 0.68];
    logoFrontTrolleyPos = [-0.20, -1.00, 0.68]
    logoFrontFlapPos = [-0.20, -1.20, 0.918];
  }
  else if (productCode === "BGR102") {
    logoFrontAnchorPos = [-0.20, -1.07, 0.745];
    logoFrontTrolleyPos = [-0.20, -0.88, 0.745]
    logoFrontFlapPos = [-0.20, -0.98, 0.978];
  }
  else if (productCode === "BG204") {
    logoFrontAnchorPos = [-0.13, -0.90, 0.115];
    logoFrontTrolleyPos = [0.56, -0.91, 1.072]
    logoFrontRightPos = [-2.218, -0.35, -0.30]
    logoFrontLeftPos = [-0.671, -1.15, -0.09]
  }
  else if (productCode === "BG600") {
    logoFrontAnchorPos = [0.04, -0.43, 1.292];
    cameraX = -0.6786019998804772
    cameraY = 3.250509590342795
    cameraZ = 10.52279921060357
  }
  else if (productCode === "BG700") {
    logoFrontAnchorPos = [0.05, -1.55, -0.077];
  }
  else if (productCode === "BG706") {
    logoFrontAnchorPos = [-0.20, -0.95, 0.2233];
  }


  else if (productCode === "BG400") {
    logoFrontFlapPos = [-0.20, 0.75, 0.318];
  }
  else if (productCode === "BGR103") {
    logoFrontTrolleyPos = [-0.21, -0.87, 1.015];
    logoFrontAnchorPos = [-0.26, -0.90, 1.00];
    ambientLight = 2
  }
  else if (productCode === "BGR122") {
    logoFrontAnchorPos = [-0.22, -0.89, 0.653];
    logoFrontTrolleyPos = [-0.22, -0.85, 0.64]
    logoFrontFlapPos = [-0.22, -0.91, 1.163]
    logoFrontLeftPos = [-0.66, -0.45, 1.57]
  }
  else if (productCode === "BGR210") {
    logoFrontPos = [-0.27, -0.89, 1.179];
    logoFrontTrolleyPos = [-0.22, -0.75, 0.69]
  }
  else if (productCode === "BG102") {
    logoFrontFlapPos = [-0.20, -0.35, 1.372]
    logoFrontTrolleyPos = [-0.20, -1.05, 1.15]
    logoFrontAnchorPos = [0.25, 1.07, 0.14];
  }

  let capProcess;
  let clipProcess;
  let frontProcess;
  let backProcess;
  let frontRightProcess;
  let frontLeftProcess;
  let frontAnchorProcess;
  let frontTrolleyProcess;
  let frontFlapProcess;
  let frontBottomProcess;
  let frontUpperProcess;
  let isRectangleFront;
  let isRectangleBack;
  let isRectangleFrontTop;
  let isRectangleFrontBottom;
  let isRectangleInClip;
  let isRectangleCap;
  let isRectangleFlap;
  let isRectangleRight;
  let isRectangleLeft;
  let isRectangleAnchor;
  let isRectangleTrolley;

  if (brandingProperties) {
    brandingProperties.forEach((branding) => {
      if (
        branding.location === "CENTERED ON FRONT" ||
        branding.location === "SIDE OF BARREL" ||
        branding.location === "CENTERED OPPOSITE OF PHONE STAND IN USE" ||
        branding.location === "CENTERED BELOW SPOUT" ||
        branding.location === "CENTERED ON BAND" ||
        branding.location === "CENTERED BELOW CARRY HANDLE" ||
        branding.location === "CENTERED NEXT TO HANDLE - RIGHT HANDED" ||
        branding.location === "IN LINE WITH WIRE GAUGE" ||
        branding.location === "ENLARGED AREA SIDE OF BARREL" ||
        branding.location === "SILICONE BAND" ||
        branding.location === "CENTERED ON FRONT POCKET"
      ) {
        frontLogo = branding.imgStr;
        frontProcess = branding.process;
        isRectangleFront = branding.isRectangle;
      } else if (branding.location === "CENTERED FRONT RIGHT POCKET" || branding.location === "CENTERED ON RIGHT FLAP" || branding.location === "CENTERED BELOW GRAB HANDLE") {
        frontLogoRight = branding.imgStr
        frontRightProcess = branding.process
        isRectangleRight = branding.isRectangle
      }
      else if (branding.location === "CENTERED FRONT LEFT POCKET" || branding.location === "CENTERED BOTTOM RIGHT" || branding.location === "CENTER ON SIDE POCKET" || branding.location === "CENTERED ON SIDE POCKET") {
        frontLogoLeft = branding.imgStr
        frontLeftProcess = branding.process
        isRectangleLeft = branding.isRectangle
      }
      else if (branding.location === "CENTERED BETWEEN ANCHORS - FRONT" || branding.location === "CENTERED FRONT POCKET" || branding.location === "CENTERED ON FRONT BETWEEN STRAPS" || branding.location === "CENTERED ON FRONT OF POUCH" || branding.location === "CENTERED ON SHOULDER STRAP") {
        frontLogoAnchor = branding.imgStr
        frontAnchorProcess = branding.process
        isRectangleAnchor = branding.isRectangle
      }
      else if (branding.location === "CENTERED ON TROLLEY STRAP" || branding.location === "CENTERED ON STRAP STABILIZER" || branding.location === "CENTERED ABOVE POCKET" || branding.location === "CENTERED ON BACK BETWEEN STRAP" || branding.location === "CENTER FRONT BOTTOM POCKET") {
        frontLogoTrolley = branding.imgStr
        frontTrolleyProcess = branding.process
        isRectangleTrolley = branding.isRectangle
      }
      else if (branding.location === "CENTERED ON BACK" || branding.location === "CENTERED NEXT TO HANDLE - LEFT HANDED" || branding.location === "BESIDE CLIP ( 2ND IMPRINTS LOCATION)" || branding.location === "ENLARGED IMPRINT AREA OTHER SIDE OF BARREL") {
        backLogo = branding.imgStr;
        backProcess = branding.process;
        isRectangleBack = branding.isRectangle;
      } else if (
        branding.location === "CENTERED ON UPPER BODY" ||
        branding.location === "BESIDE CLIP" ||
        branding.location === "CENTERED ON FRONT TOP" ||
        branding.location === "CENTERED ON BOTTLE NECK"
      ) {
        frontUpperLogo = branding.imgStr;
        frontUpperProcess = branding.process;
        isRectangleFrontTop = branding.isRectangle;
      } else if (
        branding.location === "CENTERED ON FRONT BOTTOM" ||
        branding.location === "IN LINE WITH CLIP" ||
        branding.location === "FRONT OF THE SILICONE BOOT"
      ) {
        frontBottomLogo = branding.imgStr;
        frontBottomProcess = branding.process;
        isRectangleFrontBottom = branding.isRectangle;
      } else if (branding.location === "ON CLIP") {
        clipLogo = branding.imgStr;
        clipProcess = branding.process;
        isRectangleInClip = branding.isRectangle;
      }
      else if (branding.location === "CENTERED ON CLOSING FLAP" || branding.location === "CENTERED ON SHOULDER STRAP FLAP" || branding.location === "CENTER FRONT TOP POCKET") {
        frontLogoFlap = branding.imgStr;
        frontFlapProcess = branding.process
        isRectangleFlap = branding.isRectangle

      }
      else {
        capLogo = branding.imgStr;
        capProcess = branding.process;
        isRectangleCap = branding.isRectangle;
      }
    });
  }

  function Loader(props) {
    return (
      <mesh {...props}>
        <Html>
          {/* <Box sx={{ display: 'flex' }}>
                        <CircularProgress style={{ color: '#ffbf4f' }} thickness={5} />
                    </Box> */}
        </Html>
      </mesh>
    );
  }

  useImperativeHandle(ref, () => ({
    shareProduct() {
      snapshotRef.current.shareProduct();
    },

    takeScreenshot() {
      snapshotRef.current.takeScreenshot();
    },

    changeRotation(position) {
      snapshotRef.current.changeRotation(position);
    },

    resetView() {
      snapshotRef.current.resetView();
    },
  }));

  let isFlat = false;
  let isLinear = false;

  if (productCode === "DW300") {
    isFlat = true;
    isLinear = true;
  } else {
    isFlat = false;
    isLinear = false;
  }
  // function CameraLocationFunction() {
  //   const { camera } = useThree();
  //   useFrame(() => {
  //     console.log(camera.position, 'Camera Position')

  //   })
  //   return null;
  // }

  return (
    <Canvas
      ref={canvas}
      style={{ backgroundColor: "white", height: "87.5vh", marginTop: "-5px" }}
      camera={{ position: [cameraX, cameraY, cameraZ], fov: 35, aspect: 2.5, rotateZ: 30 }}
    >
      {/* <CameraLocationFunction /> */}
      <Snapshot ref={snapshotRef} setWithBackground={setWithBackground} />
      <Suspense fallback={<Loader />}>
        <Environment files="/autoshop.hdr" background={false} />
        <Product productCode={productCode} productColor={productColor} />
        {productCode !== "DW100" &&
          productCode !== "DW104" &&
          productCode !== "DW312" &&
          productCode !== "DW409" &&
          productCode !== "DW313" &&
          productCode !== "DW319" &&
          productCode !== "DW316" &&
          productCode !== "DW317" &&
          productCode !== "DW311" &&
          productCode !== "DW314" &&
          productCode !== "DW410" &&
          productCode !== "DW404" &&
          productCode !== "DW406" &&
          productCode !== "DW407" &&
          productCode !== "DW403" &&
          productCode !== "DW402" &&
          productCode !== "DW101" &&
          productCode !== "DW103" &&
          productCode !== "DW604" &&
          productCode !== "DW603" &&
          productCode !== "EC146" &&
          productCode !== "EC117" &&
          productCode !== "I154" &&
          productCode !== "G3141" &&
          productCode !== "G3148" &&
          productCode !== "G1282" &&
          productCode !== "G1005" &&
          productCode !== "G1009" &&
          productCode !== "G1103" &&
          productCode !== "G1182" &&
          productCode !== "G1247" &&
          productCode !== "G1249" &&
          productCode !== "G1254" &&
          productCode !== "G3001" &&
          productCode !== "G3038" &&
          productCode !== "G3108" &&
          productCode !== "G3113" &&
          productCode !== "HW204" &&
          productCode !== "HW205C" &&
          productCode !== "EC122" &&
          productCode !== "EC131" &&
          productCode !== "I153" &&
          productCode !== "I116" &&
          productCode !== "I136" &&
          productCode !== "I151" &&
          productCode !== "I150" &&
          productCode !== "I138" &&
          productCode !== "I128" &&
          productCode !== "I228" &&
          productCode !== "BG504" &&
          productCode !== "BG700" &&
          productCode !== "DW201" &&
          productCode !== "DW324" &&
          productCode !== "DW405" &&
          productCode !== "DW417" &&
          productCode !== "DW500" &&
          productCode !== "DW502" &&
          productCode !== "BG500" &&
          productCode !== "BGR710" &&
          productCode !== "BG201" &&
          productCode !== "BGR104" &&
          productCode !== "BG104" &&
          productCode !== "BG206" &&
          productCode !== "BGR204" &&
          productCode !== "BG116" &&
          productCode !== "BG204" &&
          productCode !== "BG600" &&
          productCode !== "BG706" &&
          productCode !== "BGR101" &&
          productCode !== "BG400" &&
          productCode !== "BGR102" &&
          productCode !== "BGR103" &&
          productCode !== "G1298" &&
          productCode !== "I160" &&
          productCode !== "BGR122" &&
          productCode !== "BGR210" &&
          productCode !== "BG102" &&
          (
            <EvaFoam productCode={props.productCode} position={evaFoamPos} />
          )}
        {frontProcess && (
          <Logo
            productCode={productCode}
            process={frontProcess}
            isRectangle={isRectangleFront}
            image={props.logoFront}
            position={logoFrontPos}
          />
        )}
        {frontRightProcess && (
          <LogoCenteredRight
            productCode={productCode}
            process={frontRightProcess}
            image={props.logoFrontRight}
            position={logoFrontRightPos}
            isRectangle={isRectangleRight}
          />
        )}
        {frontLeftProcess && (
          <LogoCenteredLeft
            productCode={productCode}
            process={frontLeftProcess}
            image={props.logoFrontLeft}
            position={logoFrontLeftPos}
            isRectangle={isRectangleLeft}
          />
        )}
        {frontFlapProcess && (
          <LogoCenteredFlap
            productCode={productCode}
            process={frontFlapProcess}
            image={props.logoFrontFlap}
            position={logoFrontFlapPos}
            isRectangle={isRectangleFlap}
          />
        )}
        {frontAnchorProcess && (
          <LogoCenteredAnchor
            productCode={productCode}
            process={frontAnchorProcess}
            image={props.logoFrontAnchor}
            position={logoFrontAnchorPos}
            isRectangle={isRectangleAnchor}
          />
        )}
        {frontTrolleyProcess && (
          <LogoCenteredTrolley
            productCode={productCode}
            process={frontTrolleyProcess}
            image={props.logoFrontTrolley}
            position={logoFrontTrolleyPos}
            isRectangle={isRectangleTrolley}
          />
        )}
        {backProcess && (
          <LogoCenteredBack
            productCode={productCode}
            process={backProcess}
            isRectangle={isRectangleBack}
            image={props.logoBack}
            position={logoBackPos}
          />
        )}
        {capProcess && (
          <LogoCenteredCap
            productCode={productCode}
            process={capProcess}
            isRectangle={isRectangleCap}
            image={props.logoCap}
            position={logoCapPos}
          />
        )}
        {frontBottomProcess && (
          <LogoCenteredBottom
            productCode={productCode}
            process={frontBottomProcess}
            isRectangle={isRectangleFrontBottom}
            image={props.logoFrontBottom}
            position={logoFrontBottomPos}
          />
        )}
        {frontUpperProcess && (
          <LogoCenteredUpperBody
            productCode={productCode}
            process={frontUpperProcess}
            isRectangle={isRectangleFrontTop}
            image={props.logoFrontUpper}
            position={logoFrontUpperPos}
          />
        )}
        {clipProcess && (
          <LogoCenteredClip
            productCode={productCode}
            process={clipProcess}
            image={props.logoClip}
            isRectangle={isRectangleInClip}
            position={logoClipPos}
          />
        )}
        <ambientLight intensity={ambientLight} />
        <spotLight position={[0, -10, 0]} intensity={0.1} penumbra={0.5} />
        <OrbitControls
          enableDamping={true}
          dampingFactor={0.15}
          ref={view}
          enableZoom={zoomed}
          enablePan={true}
          setPolarAngle={Math.PI / 3}
        />
      </Suspense>
    </Canvas>
  );
});

export default Canvas3D;
