import React, { useState } from 'react';

const ConfirmationDialog = ({ message, onConfirm, onCancel, no, yes }) => {

    return (

        <div className="confirmation-dialog">
            <p>{message}</p>
            <div className='d-flex justify-content-end gap-2'>
                <button onClick={onCancel} className='btn bg-gray '>{no}</button>
                <button onClick={onConfirm} className='btn bg-yellow'>{yes}</button>
            </div>

        </div>


    );
};

export default ConfirmationDialog