import {
  Grid,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

export default function BrandingSelection(props) {
  const {
    brandingOptions,
    locationsBranding,
    filteredLocationOptions,
    productCode,
    removeBrandingRow,
    handleLocationChange,
    handleOpenMultipleModal,
    handleOpenModal,
    addBrandingRow,
    iconSelect,
  } = props;




  return (
    <Table>
      <TableBody>
        {brandingOptions &&
          brandingOptions.map((row, i) => {
            const processOptions = locationsBranding.find(({ location }) => location?.toUpperCase() === row.location?.toUpperCase())?.brandings || []

            return (
              <TableRow key={`row-${row.id}-${i}`}>
                {!["DW603", "DW604", "DW408"].includes(productCode) && (
                  <TableCell style={{ borderBottom: "none" }}>
                    <IconButton sx={{ border: 1 }} onClick={removeBrandingRow}>
                      <RemoveRoundedIcon />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell
                  style={{
                    width: "100%",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <FormControl
                    variant="outlined"
                    sx={{ width: "100%", marginRight: 0 }}
                  >
                    <Select
                      size="medium"
                      sx={{
                        borderRadius: "15px",
                        paddingLeft: "10px",
                        fontSize: "1.2rem",
                      }}
                      name="location"
                      notched={false}
                      IconComponent={iconSelect}
                      onChange={(event) => handleLocationChange(event, row.id)}
                      value={row.location}
                    >
                      {filteredLocationOptions &&
                        filteredLocationOptions.map((location) => {
                          return (
                            <MenuItem
                              className="main-font"
                              key={`location-${location}`}
                              value={location.toUpperCase()}
                            >
                              <span className="main-font">{location.toUpperCase()}</span>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell
                  style={{
                    width: "100%",
                    borderBottom: "none",
                  }}
                >
                  {/* add logic here to show dropdown based on type of product, should create another dropdown for pen branding locs */}
                  <FormControl
                    variant="outlined"
                    sx={{ width: "100%", marginRight: 10 }}
                  >
                    <Select
                      size="medium"
                      sx={{
                        borderRadius: "15px",
                        paddingLeft: "10px",
                        fontSize: "1.2rem",
                      }}
                      name="process"
                      notched={false}
                      IconComponent={iconSelect}
                      onChange={(event) => handleLocationChange(event, row.id)}
                      value={row.process}
                    // disabled
                    >
                      {processOptions &&
                        processOptions.map((process) => {
                          return (
                            <MenuItem
                              className="main-font"
                              key={`process-${process}`}
                              value={process.toUpperCase()}
                            >
                              <span className="main-font">{process.toUpperCase()}</span>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell
                  style={{
                    width: "100%",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <Button
                    onClick={() => {
                      handleOpenModal(row.process, row.id);
                    }}
                    fullWidth
                    style={{
                      color: "gray",
                      border: "solid",
                      borderWidth: "1px",
                      borderColor: "#C4C4C4",
                      height: "50px",
                      borderRadius: 15,
                      width: "170px",
                      padding: "2rem"
                    }}
                  >
                    <PhotoSizeSelectActualOutlinedIcon
                      style={{ paddingRight: 10 }}
                    />

                    {row.logoName?.length > 10
                      ? row.logoName.substring(0, 7) + "..."
                      : row.logoName}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        {/**THIS IS WHERE CONDITION IF brandingOptions.length show BUTTON ADD ADDITIONAL LOCATION */}
        <TableRow>
          <TableCell style={{
            width: "50px",
            borderBottom: "none",
          }}>
            {brandingOptions.length < filteredLocationOptions.length && (
              <Grid container style={{}} alignItems="center">
                <IconButton sx={{ border: 1 }} onClick={addBrandingRow}>
                  <AddRoundedIcon />
                </IconButton>
              </Grid>
            )}
          </TableCell>
          <TableCell style={{
            width: "100%",
            borderBottom: "none",
          }}>
            {brandingOptions.length < filteredLocationOptions.length && (
              <Typography style={{ fontSize: "1.2rem" }}>
                <span className="main-font">Additional Location</span>
              </Typography>
            )}
          </TableCell>
          <TableCell sx={{ borderBottom: "none" }} size="small"></TableCell>
          {/**This code is showing if brandingOptions.length greater than 1 show the button of apply art to all*/}
          <TableCell
            sx={{
              borderBottom: "none",
              width: "100%",
              padding: 0,
            }}
          >
            {brandingOptions.length > 1 && (
              <Button
                onClick={handleOpenMultipleModal}
                sx={{
                  backgroundColor: "#FFBF4F",
                  color: "white",
                  fontSize: "0.7em",
                  fontWeight: "bold",
                  width: "100%",

                }}
              >
                <PhotoLibraryIcon style={{ paddingRight: 5 }} /> Apply Art to
                All
              </Button>
            )}
          </TableCell>
        </TableRow>
        {/**THIS IS WHERE CONDITION IF brandingOptions.length show BUTTON ADD ADDITIONAL LOCATION */}
      </TableBody>
    </Table>
  );
}
