
const ProductLink = () => {
    return "https://decdoutmerch.com/api/v1/";
}
const LocalLink = () => {
    return "http://40.113.34.8/api/v1/";
}
const ActiveLink = () => {
    return ProductLink();
}
const AnalyticsLink = () => {
    return "https://decdoutmerch.com/spector/"
}

export default {
    ProductLink,
    LocalLink,
    ActiveLink,
    AnalyticsLink,
};