import React, { useEffect, useRef, useContext, useState, useMemo } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import EventEmitterContext from '../../../Utils/EventEmitterContext';




const LogoCenteredRight = (props) => {
    const mesh = useRef()
    const texture = new THREE.TextureLoader().load(props.image)
    texture.repeat = new THREE.Vector2(1, 0.95)
    texture.center = new THREE.Vector2(1, 1)
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    texture.encoding = THREE.sRGBEncoding;
    const [load, setLoad] = useState(false)
    const eventEmitter = useContext(EventEmitterContext);

    const [customShape, setCustomShape] = useState(null)


    useEffect(() => {
        if (load) {
            if (props.productCode === "BGR710") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_mesh_f_r_bot.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };

                loadCustomShape();
            }
            else if (props.productCode === "BG201") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_side_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };

                loadCustomShape();
            }
        }
    }, [load, props.productCode]);

    let process = props.process
    let transparent = true
    let roughness = 1
    let metalness = 0
    let clearcoat = 0
    let clearcoatRoughness = 0
    let metalMap = null
    let colorFilter = null;
    let emissiveFilter = null;
    let args = [10, 10]
    let scale = 1;





    useEffect(() => {
        const callback = () => {
            // Handle the event
            setLoad(true)

        };

        eventEmitter.on('fullyLoaded', callback);
        return () => eventEmitter.off('fullyLoaded')
    }, [])

    if (load) {

        if (process === "LASER ENGRAVED" || process === "REFLECTION LASER") {
            metalness = 1;
            roughness = 0.35;
            metalMap = new THREE.TextureLoader().load("brushed.png");
        } else if (process === "SILK SCREENED" || process === "LASER TOUCH") {
            metalness = 0.75;
            roughness = 1;
            // colorFilter = new THREE.Color( 0x969696 )
            // emissiveFilter = new THREE.Color( 0x040404 )
        } else if (process === "4CP FLEX") {
            clearcoat = 0;
            clearcoatRoughness = 0;
        } else if (process === "4CP FLEX TRANSFER") {
            metalness = 0.90;
            roughness = 1;
        } else {
            metalness = 0.75;
            roughness = 1;
            clearcoat = 0.75
            clearcoatRoughness = 0.075
        }
    }
    if (load) {
        if (props.productCode === 'BG500') {
            args = [0.75, 1];
        }
        else if (props.productCode === 'BGR710') {
            scale = 0.11
        }
        else if (props.productCode === 'BGR204') {
            scale = 0.60
            args = [0.75, 1];
        }
        else if (props.productCode === 'BG204') {
            scale = 0.60
            args = [0.72, 1];
        }
        else {
            args = [0.75, 1];
        }
    }

    useEffect(() => {
        if (load) {
            if (props.productCode === 'BG500') {
                mesh.current.rotation.x = -0.13
                mesh.current.rotation.y = 0.00
            }
            else if (props.productCode === 'BGR710') {
                mesh.current.rotation.x = 1.56
                mesh.current.rotation.z = 0.11
            }
            else if (props.productCode === 'BG201') {
                mesh.current.rotation.x = 0.00
                mesh.current.rotation.y = 3.14
                mesh.current.rotation.z = -0.01
            }
            else if (props.productCode === "BGR204") {
                mesh.current.rotation.x = 0
                mesh.current.rotation.y = -1.58
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG204") {
                mesh.current.rotation.x = 0
                mesh.current.rotation.y = -1.58
                mesh.current.rotation.z = 0
            }

        }
    }, [load])
    useFrame(() => {
        if (load && props.productCode === "BG201") {
            mesh.current.scale.set(0.05, 0.07, 0.05);
        }
    })

    return load ? (
        <mesh
            {...props}
            ref={mesh}
            scale={scale}
        >
            {
                (props.productCode === "BGR710" || props.productCode === "BG201") && customShape ? (
                    <bufferGeometry attach="geometry"
                        attributes={customShape.children[0].geometry.attributes}
                        boundingBox={customShape.children[0].geometry.boundingBox}
                        boundingSphere={customShape.children[0].geometry.boundingSphere}
                        drawRange={customShape.children[0].geometry.drawRange}
                        groups={customShape.children[0].geometry.groups}
                        index={customShape.children[0].geometry.index}
                        morphAttributes={customShape.children[0].geometry.morphAttributes}
                        morphTargetsRelative={customShape.children[0].geometry.morphTargetsRelative}
                        name={customShape.children[0].geometry.name}
                        type={customShape.children[0].geometry.type}
                        userData={customShape.children[0].geometry.userData}
                        uuid={customShape.children[0].geometry.uuid}
                    >
                    </bufferGeometry>
                ) : (
                    <planeBufferGeometry attach="geometry" args={args} />
                )
            }
            <meshPhysicalMaterial
                transparent={transparent}

                side={THREE.FrontSide}
                roughness={roughness}
                clearcoatRoughness={clearcoatRoughness}
                clearcoat={clearcoat}
                metalness={metalness}
                metalnessMap={metalMap}
                color={colorFilter}
                emissive={emissiveFilter}
                anisotropy={4}
            >
                <primitive attach="map" object={texture} />
            </meshPhysicalMaterial>
        </mesh>
    ) : null;
}

export default LogoCenteredRight



