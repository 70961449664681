const createEventEmitter = () => {
    const callbacks = {
        base: {}
    };

    const on = (_names, callback) => {
        if (typeof _names === 'undefined' || _names === '') {
            console.warn('wrong names');
            return false;
        }

        if (typeof callback === 'undefined') {
            console.warn('wrong callback');
            return false;
        }

        const names = resolveNames(_names);

        names.forEach((_name) => {
            const name = resolveName(_name);

            if (!(callbacks[name.namespace] instanceof Object)) {
                callbacks[name.namespace] = {};
            }

            if (!(callbacks[name.namespace][name.value] instanceof Array)) {
                callbacks[name.namespace][name.value] = [];
            }

            callbacks[name.namespace][name.value].push(callback);
        });

        return this;
    };

    const off = (_names) => {
        if (typeof _names === 'undefined' || _names === '') {
            console.warn('wrong name');
            return false;
        }

        const names = resolveNames(_names);

        names.forEach((_name) => {
            const name = resolveName(_name);

            if (name.namespace !== 'base' && name.value === '') {
                delete callbacks[name.namespace];
            } else {
                if (name.namespace === 'base') {
                    for (const namespace in callbacks) {
                        if (
                            callbacks[namespace] instanceof Object &&
                            callbacks[namespace][name.value] instanceof Array
                        ) {
                            delete callbacks[namespace][name.value];

                            if (Object.keys(callbacks[namespace]).length === 0) {
                                delete callbacks[namespace];
                            }
                        }
                    }
                } else if (
                    callbacks[name.namespace] instanceof Object &&
                    callbacks[name.namespace][name.value] instanceof Array
                ) {
                    delete callbacks[name.namespace][name.value];

                    if (Object.keys(callbacks[name.namespace]).length === 0) {
                        delete callbacks[name.namespace];
                    }
                }
            }
        });

        return this;
    };

    const trigger = (_name, _args) => {
        if (typeof _name === 'undefined' || _name === '') {
            console.warn('wrong name');
            return false;
        }

        let finalResult = null;
        let result = null;

        const args = !(_args instanceof Array) ? [] : _args;

        const name = resolveNames(_name)[0];
        const resolvedName = resolveName(name);

        if (resolvedName.namespace === 'base') {
            for (const namespace in callbacks) {
                if (
                    callbacks[namespace] instanceof Object &&
                    callbacks[namespace][resolvedName.value] instanceof Array
                ) {
                    callbacks[namespace][resolvedName.value].forEach((callback) => {
                        result = callback.apply(this, args);

                        if (typeof finalResult === 'undefined') {
                            finalResult = result;
                        }
                    });
                }
            }
        } else if (
            callbacks[resolvedName.namespace] instanceof Object &&
            callbacks[resolvedName.namespace][resolvedName.value] instanceof Array
        ) {
            callbacks[resolvedName.namespace][resolvedName.value].forEach((callback) => {
                result = callback.apply(this, args);

                if (typeof finalResult === 'undefined') {
                    finalResult = result;
                }
            });
        }

        return finalResult;
    };

    const resolveNames = (_names) => {
        let names = _names;
        names = names.replace(/[^a-zA-Z0-9 ,/.]/g, '');
        names = names.replace(/[,/]+/g, ' ');
        names = names.split(' ');

        return names;
    };

    const resolveName = (name) => {
        const newName = {};
        const parts = name.split('.');

        newName.original = name;
        newName.value = parts[0];
        newName.namespace = 'base';

        if (parts.length > 1 && parts[1] !== '') {
            newName.namespace = parts[1];
        }

        return newName;
    };

    return {
        on,
        off,
        trigger
    };
};

export default createEventEmitter;
