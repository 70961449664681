import React, { useEffect, useRef, useState, useContext } from "react";
import * as THREE from "three";
import EventEmitterContext from '../../../Utils/EventEmitterContext';


const LogoCenteredCap = (props) => {
    const mesh = useRef()
    const texture = new THREE.TextureLoader().load(props.image)
    texture.offset = new THREE.Vector2(0, 0.01)
    texture.repeat = new THREE.Vector2(1, 0.675)
    texture.center = new THREE.Vector2(0.5, 0.5)
    texture.encoding = THREE.sRGBEncoding;
    texture.wrap = THREE.RepeatWrapping
    let roughness = 1
    let metalness = 0
    let args = [0.6, 100]
    let metalMap = null
    const [load, setLoad] = useState(false)
    const eventEmitter = useContext(EventEmitterContext);



    useEffect(() => {
        const callback = () => {
            // Handle the event
            // console.log('Event triggered! LOGO.JS FUCK YOU KA!');
            setLoad(true)
        };

        eventEmitter.on('fullyLoaded', callback);
        return () => eventEmitter.off('fullyLoaded')
    }, [])



    if (load) {
        if (props.process === 'LASER ENGRAVED' || props.process === 'REFLECTION LASER') {
            metalness = 1.0
            roughness = 0.35
            metalMap = new THREE.TextureLoader().load('brushed.png')
        } else if (props.process === "SILK SCREENED") {
            metalness = 0.75;
            roughness = 1.0;
            // colorFilter = new THREE.Color( 0xFFFFFF )
            // emissiveFilter = new THREE.Color( 0x252525 )
        }
    }

    if (load) {
        if (props.productCode === 'DW319') {
            args = [0.45, 100]
        } else if (props.productCode === 'DW316') {
            args = [0.55, 100]
        } else if (props.productCode === 'DW311') {
            args = [0.4, 100]
        } else if (props.productCode === 'DW314') {
            args = [0.4, 100]
        } else if (props.productCode === 'DW306') {
            args = [0.5, 100]
        }
    }
    useEffect(() => {
        if (load) {
            if (props.productCode === "DW305") {
                mesh.current.rotation.x = -1.58;
            }
            else {
                mesh.current.rotation.x = -Math.PI / 2;
            }
        }
    }, [load])

    return load ? (
        <mesh
            {...props}
            ref={mesh}
        >
            <circleBufferGeometry args={args} />
            <meshStandardMaterial
                transparent
                side={THREE.FrontSide}
                roughness={roughness}
                // normalMap={texture}
                metalness={metalness}
                metalnessMap={metalMap}
            >
                <primitive attach="map" object={texture} />
            </meshStandardMaterial>
        </mesh>
    ) : null;
}

export default LogoCenteredCap