import { useEffect, useReducer } from "react";

const initialState = {
    isLoading: false,
    data: null,
    error: null,
};

function requestReducer(state, action) {
    const updatedState = { ...state };

    switch (action.type) {
        case "FETCHING":
            updatedState.isLoading = true;
            updatedState.data = null;
            updatedState.error = null;
            break;

        case "SUCCESS":
            updatedState.isLoading = false;
            updatedState.data = action.data;
            break;

        case "ERROR":
            updatedState.isLoading = false;
            updatedState.error = action.error;
            break;
        default:
            break;
    }

    return updatedState
}

export default function useRequest({ url, method = "get", onMount }) {
    const [state, dispatch] = useReducer(requestReducer, initialState);

    function sendRequest(payload) {
        dispatch({ type: "FETCHING" });
        fetch(url, { method, body: payload })
            .then((response) => response.json())
            .then((data) => dispatch({ type: "SUCCESS", data }))
            .catch((error) => dispatch({ type: "ERROR", error }));
    }

    useEffect(() => {
        if (onMount) sendRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [state, sendRequest];
}
