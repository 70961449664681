import React, { useEffect, useRef, useState, useContext } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import Draggable from "react-draggable";
import styled from "@emotion/styled";
import { Grid, IconButton, Slider, TextField, Typography, tooltipClasses, Button, Card, SvgIcon, Tooltip } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";
import { useDebounceEffect } from "./UseDebounceEffect";
import { canvasPreview } from "./CanvasPreview";

function centerAspectCrop() {
    return centerCrop(makeAspectCrop({ unit: "%", width: 100 }, 1, 512, 512), 512, 512);
}

const handleClickBack = () => {
    window.location.href = "/";
    sessionStorage.removeItem("showStrap");
};

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className, tooltip: { marginLeft: 500 } }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#ffffff",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 500,
            minWidth: 250,
            border: "1px solid #dadde9",
        },
        [`& .${tooltipClasses.arrow}`]: {
            "&:before": {
                border: "1px solid #E6E8ED",
                padding: -5,
            },
        },
    })
);

export default function BrandDesigner(props) {
    const { index, branding, setBrandingOptions, setLogoFront, setLogoBack, setLogoFrontUpper, setLogoFrontBottom, setLogoClip, setLogoCap, setLogoFrontRight, setLogoFrontLeft, setLogoFrontAnchor, setLogoFrontTrolley, setLogoFrontFlap } = props;
    const { completedCrop, scale, showImg, showScale, showRotate, showMove, translate } = branding;
    const [crop, setCrop] = useState();
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasLength, setCanvasLength] = useState(0);
    const [openRotateTooltip, setOpenRotateTooltip] = useState(false);
    const [openScaleTooltip, setOpenScaleTooltip] = useState(false);
    const [isVisibleBackground, setIsVisibleBackground] = useState(false);
    const [showStrap, setShowStrap] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const imgRef = useRef(branding.imgRef);
    const previewCanvasRef = useRef(branding.previewCanvasRef);
    const translateRef = useRef(translate);
    const rotateRef = useRef();


    const updateBrandingDetail = (updatedDetails) => {
        setBrandingOptions((prevOptions) => {
            const newState = [...prevOptions];
            newState[index] = {
                ...newState[index],
                ...updatedDetails,
            };
            return newState;
        });
    };
    const handleRotateTooltipClose = () => {
        setOpenRotateTooltip(false);
    };
    const handleRotateTooltipOpen = () => {

        if (openRotateTooltip) {
            setOpenScaleTooltip(false);
            setOpenRotateTooltip(false);
            handleRotateTooltipClose();
        } else {
            setOpenScaleTooltip(false);
            setOpenRotateTooltip(true);
        }

        setOpenRotateTooltip(true);
    };
    const handleScaleTooltipClose = () => {
        setOpenScaleTooltip(false);
        let scaleVal = sessionStorage.getItem("currentScaling");
        updateBrandingDetail({ scale: scaleVal });
    };
    const handleScaleTooltipOpen = () => {
        if (openScaleTooltip) {
            setOpenRotateTooltip(false);
            handleScaleTooltipClose();
        } else {
            setOpenScaleTooltip(true);
            setOpenRotateTooltip(false);
        }
    };
    const moveImage = () => {
        updateBrandingDetail({ showImg: false, showRotate: false, showScale: false, showMove: true });
    };
    const resetImage = () => {

        if (sessionStorage.getItem("productType" === "pen")) {
            sessionStorage.setItem("currentRotation" + branding.location, -90);


        } else {
            sessionStorage.setItem("currentRotation" + branding.location, 0);
        }
        sessionStorage.setItem("currentScaling", 0);

        updateBrandingDetail({ showImg: true, showRotate: false, showScale: false, showMove: false, translate: { x: 0, y: 0 }, scale: 100, rotate: 0 });
    };
    const onImageLoad = (event) => {
        setCrop(centerAspectCrop());
        updateBrandingDetail({ showImg: true });
    };
    const handleScaleInput = (event) => {
        if (event.keyCode === 13 || event.keyCode === 27) {
            handleScaleTooltipClose();
        }
    };
    const handleScaleSlider = (event, newValue) => {
        let scaleVal;
        document.getElementById(branding.location).value = newValue;
        scaleVal = newValue;
        sessionStorage.setItem("currentScaling", parseFloat(scaleVal));
    };
    const handleScale = (event) => {
        event.preventDefault();
        let scaleVal;
        scaleVal = event.target.value;
        let percentTotal = parseInt(event.target.value);
        let factor = 0.507;

        if (parseInt(event.target.value) >= 200) {
            document.getElementById(branding.location + "-slider").children[1].style.width = `100%`;
            document.getElementById(branding.location + "-slider").children[2].style.left = `100%`;
        } else if (parseInt(event.target.value) <= 10) {
            document.getElementById(branding.location + "-slider").children[1].style.width = `0%`;
            document.getElementById(branding.location + "-slider").children[2].style.left = `0%`;
        } else {
            let stylingPercentage = (percentTotal * factor).toString();
            document.getElementById(branding.location + "-slider").children[1].style.width = `${stylingPercentage}%`;
            document.getElementById(branding.location + "-slider").children[2].style.left = `${stylingPercentage}%`;
        }

        document.getElementById(branding.location + "-slider").children[2].children[0].value = event.target.value;

        if (!isNaN(parseFloat(scaleVal))) {
            if (parseFloat(scaleVal) > 200) {
                sessionStorage.setItem("currentScaling", 200);
            } else if (parseFloat(scaleVal) <= 0) {
                sessionStorage.setItem("currentScaling", 10);
            } else {
                sessionStorage.setItem("currentScaling", parseFloat(scaleVal));
            }
        } else {
            sessionStorage.setItem("currentScaling", 10);
        }
    };
    const handleRotateButton = (value) => {
        // updateBrandingDetail({ showMove: false });
        const currentValue = parseFloat(rotateRef.current.value);
        const newValue = currentValue + value;
        let rotatedValue = newValue % 360;
        if (rotatedValue < 0) {
            rotatedValue += 360;
        }
        rotateRef.current.value = rotatedValue;
    };
    const handleRotateInputEnter = (event) => {
        if (event.keyCode === 13 || event.keyCode === 27) {
            handleRotateTooltipClose();
            updateBrandingDetail({ rotate: parseFloat(event.target.value) });
        }
    };
    const handleDrag = (e, dragData) => {
        e.preventDefault();

        translateRef.current = {
            x: translateRef.current.x + dragData.deltaX,
            y: translateRef.current.y + dragData.deltaY,
        };
    };

    let initialTouch = null;

    const onStop = () => {
        updateBrandingDetail({ translate: translateRef.current });
        initialTouch = null;
    };

    const onTouchStart = (e) => {
        e.preventDefault();
        initialTouch = e.touches[0];
    };

    const onTouchMove = (e) => {
        e.preventDefault();
        if (!initialTouch) return;
        const touch = e.touches[0];
        const { clientX, clientY } = touch;
        const deltaX = clientX - initialTouch.clientX;
        const deltaY = clientY - initialTouch.clientY;

        const newTranslate = {
            x: translate.x + deltaX,
            y: translate.y + deltaY,
        };
        updateBrandingDetail({ translate: newTranslate });
    };

    const onTouchEnd = (e) => {
        e.preventDefault();
        initialTouch = null;
    };

    useDebounceEffect(
        () => {
            if (!completedCrop?.width || !completedCrop?.height || !imgRef?.current || !previewCanvasRef?.current) return;
            const canvasWidth = completedCrop.width;
            const canvasHeight = completedCrop.height;

            // Calculate the center point of the image
            const centerX = canvasWidth / 2;
            const centerY = canvasHeight / 2;
            // Set the origin point for the rotation transformation
            const originX = centerX / scale;
            const originY = centerY / scale;

            const location = branding.location.toUpperCase();

            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                scale / 100,
                branding.rotate,
                translate.x,
                translate.y,
                location,
                originX,
                originY
            ).then((data) => {

                if (location === "CENTERED ON FRONT" || location === "SIDE OF BARREL" || location === "CENTERED BELOW SPOUT" || location === "CENTERED ON BAND" || location === "CENTERED BELOW CARRY HANDLE" || location === "CENTERED NEXT TO HANDLE - RIGHT HANDED" || location === "CENTERED OPPOSITE OF PHONE STAND IN USE" || location === "IN LINE WITH WIRE GAUGE" || location === "ENLARGED AREA SIDE OF BARREL" || location === "SILICONE BAND" || location === "CENTERED ON FRONT POCKET") {
                    setLogoFront(data);
                } else if (location === "CENTERED ON BACK" || location === "CENTERED NEXT TO HANDLE - LEFT HANDED" || location === "BESIDE CLIP ( 2ND IMPRINTS LOCATION)" || location === "ENLARGED IMPRINT AREA OTHER SIDE OF BARREL") {
                    setLogoBack(data);
                } else if (location === "CENTERED ON UPPER BODY" || location === "BESIDE CLIP" || location === "CENTERED ON BOTTLE NECK" || location === "CENTERED ON FRONT TOP") {
                    setLogoFrontUpper(data);
                } else if (location === "CENTERED ON FRONT BOTTOM" || location === "IN LINE WITH CLIP" || location === "CENTERED ON FRONT BOTTOM" || location === "FRONT OF THE SILICONE BOOT") {
                    setLogoFrontBottom(data);
                } else if (location === "ON CLIP") {
                    setLogoClip(data);
                }
                else if (location === "CENTERED FRONT RIGHT POCKET" || location === "CENTERED ON RIGHT FLAP" || location === "CENTERED BELOW GRAB HANDLE") {
                    setLogoFrontRight(data)
                }
                else if (location === "CENTERED FRONT LEFT POCKET" || location === "CENTERED BOTTOM RIGHT" || location === "CENTER ON SIDE POCKET" || location === "CENTERED ON SIDE POCKET") {

                    setLogoFrontLeft(data)
                }
                else if (location === "CENTERED BETWEEN ANCHORS - FRONT" || location === "CENTERED FRONT POCKET" || location === "CENTERED ON FRONT BETWEEN STRAPS" || location === "CENTERED ON FRONT OF POUCH" || location === "CENTERED ON SHOULDER STRAP") {
                    setLogoFrontAnchor(data)
                }
                else if (location === "CENTERED ON TROLLEY STRAP" || location === "CENTERED ON STRAP STABILIZER" || location === "CENTERED ABOVE POCKET" || location === "CENTERED ON BACK BETWEEN STRAP" || location === "CENTER FRONT BOTTOM POCKET") {
                    setLogoFrontTrolley(data)
                }
                else if (location === "CENTERED ON CLOSING FLAP" || location === "CENTERED ON SHOULDER STRAP FLAP" || location === "CENTER FRONT TOP POCKET") {
                    setLogoFrontFlap(data)
                }
                else {
                    setLogoCap(data);
                }
            });
        },
        250,
        [branding]
    );

    useEffect(() => {
        if (sessionStorage.getItem("productType") === "pen") {
            if (sessionStorage.getItem("rectangleType") === "vertical") {
                updateBrandingDetail({ rotate: 180 })
            } else {
                updateBrandingDetail({ rotate: 0 })
            }
        }
        if (sessionStorage.getItem("productCode") === "DW307" && sessionStorage.getItem("rectangleType") === "normal") {
            updateBrandingDetail({ rotate: -90 })
        }
    }, [])

    let x = "";
    let y = ""

    const sizes = JSON.parse(sessionStorage.getItem("product"))

    sizes.brandings.map((area) => {

        if (branding.location.toUpperCase() === area.location.toUpperCase()) {
            if (sessionStorage.getItem("productType") === "pen") {
                x = area.areaY
                y = area.areaX
            } else {
                x = area.areaX
                y = area.areaY
            }
        }

    })
    // console.log(one, 'PLEASE')
    useEffect(() => {
        if (branding.location === "CENTERED ON CAP") {
            setCanvasWidth(576);
            setCanvasLength(576);
        } else if (sessionStorage.getItem("productType") === "pen") {
            console.log('PENS')
            setCanvasWidth(212);
            setCanvasLength(535);
        } else {
            setCanvasWidth(386);
            setCanvasLength(535);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    function handleToggleStrap() {
        // Toggle the state and store it in sessionStorage
        const updatedShowStrap = !showStrap;
        setShowStrap(updatedShowStrap);
        sessionStorage.setItem('showStrap', JSON.stringify(updatedShowStrap));
        window.location.reload();
    }
    function handleToggleHideStrap() {
        // Toggle the state and store it in sessionStorage
        const updatedShow = !showNew;
        setShowNew(updatedShow);
        sessionStorage.setItem('showNew', JSON.stringify(updatedShow));
        window.location.reload();
    }


    function safeJsonParse(data, defaultValue = null) {
        try {
            return JSON.parse(data);
        } catch (error) {
            return defaultValue;
        }
    }
    useEffect(() => {
        const storedShowStrap = safeJsonParse(sessionStorage.getItem('showStrap'));
        const storedNewStrap = safeJsonParse(sessionStorage.getItem('showNew'));
        if (storedShowStrap !== null) {
            setShowStrap(storedShowStrap);
        }
        if (storedNewStrap !== null) {
            setShowNew(storedNewStrap);
        }
    }, []);

    return (
        <>
            <Grid container spacing={1} sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 5 }} alignItems="center">
                <Grid item xl={1} lg={2}>
                    <div className="custom-box-container">
                        <div
                            className="custom-box"
                        >
                            <Typography sx={{ paddingBottom: 2, fontWeight: 900 }}>TOOLS</Typography>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {isVisibleBackground ? (
                                    <Tooltip arrow placement="right" title="Click to turn the background on">
                                        <VisibilityOffTwoToneIcon
                                            onClick={() => {
                                                setIsVisibleBackground(false)
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginBottom: "10px",
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip arrow placement="right" title="Click to turn the background off">
                                        <VisibilityTwoToneIcon
                                            onClick={() => {
                                                setIsVisibleBackground(true)
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginBottom: "10px",
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                            <IconButton sx={{ border: 0 }} onClick={resetImage}>
                                <LoopIcon sx={{ color: "black", fontSize: "2em" }} />
                            </IconButton>
                            <Typography sx={{ paddingBottom: 1, paddingTop: 0 }}>RESET</Typography>
                            <IconButton sx={{ border: 0 }} onClick={moveImage} style={showMove ? { backgroundColor: "lightgray" } : {}}>
                                <SvgIcon
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    sx={{ color: "black", fontSize: "2em" }}
                                >
                                    <polyline points="5 9 2 12 5 15"></polyline>
                                    <polyline points="9 5 12 2 15 5"></polyline>
                                    <polyline points="15 19 12 22 9 19"></polyline>
                                    <polyline points="19 9 22 12 19 15"></polyline>
                                    <line x1="2" y1="12" x2="22" y2="12"></line>
                                    <line x1="12" y1="2" x2="12" y2="22"></line>
                                </SvgIcon>
                            </IconButton>
                            <Typography sx={{ paddingBottom: 1, paddingTop: 0 }}>MOVE</Typography>
                            <IconButton
                                sx={{ border: 0 }}
                                id="rotate-apply"
                                onClick={() => {
                                    handleRotateTooltipOpen();
                                }}
                                style={showRotate ? { backgroundColor: "lightgray" } : {}}
                            >
                                <SvgIcon
                                    viewBox="0 0 64 64"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    sx={{ color: "black", fontSize: "2em" }}
                                >
                                    <g id="Layer_59" data-name="Layer 59">
                                        <path d="M39.75,22.28A1.5,1.5,0,0,0,41.05,24l9.58,1.19a1.56,1.56,0,0,0,1.28-.47,1.66,1.66,0,0,0,.3-.49h0a1.31,1.31,0,0,0,.09-.34l1.19-9.58a1.5,1.5,0,0,0-3-.37l-.64,5.21C37.4,2.29,10.63,11,10.5,32A21.81,21.81,0,0,0,53,38.79a1.5,1.5,0,0,0-2.85-.93A18.81,18.81,0,0,1,13.5,32c.16-18.73,24.38-25.79,34.6-10.2L41.42,21A1.5,1.5,0,0,0,39.75,22.28Z" />
                                    </g>
                                </SvgIcon>
                            </IconButton>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Grid container spacing={1} sx={{ padding: 1, justifyContent: "flex-end" }} alignContent="center">
                                            <Grid item xs={3}></Grid>
                                            <Grid item xs={3} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                                                <Tooltip title="Rotate -90°" arrow placement="top">
                                                    <IconButton onClick={() => handleRotateButton(-90)}>
                                                        <Rotate90DegreesCcwIcon style={{ color: "#000000" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={3} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                                                <Tooltip title="Rotate +90°" arrow placement="top">
                                                    <IconButton onClick={() => handleRotateButton(90)}>
                                                        <Rotate90DegreesCwIcon style={{ color: "#000000" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={3}></Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={9}>
                                                <TextField
                                                    id={branding.location + "-rotate-btn"}
                                                    type="number"
                                                    label="Rotate"
                                                    color="warning"
                                                    inputRef={rotateRef}
                                                    variant="outlined"
                                                    defaultValue={branding.rotate}
                                                    onKeyDown={(e) => {
                                                        handleRotateInputEnter(e);
                                                    }}
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    onClick={() => {
                                                        handleRotateTooltipClose();
                                                        updateBrandingDetail({ rotate: rotateRef.current.value });
                                                    }}
                                                    variant="contained"
                                                    color="warning"
                                                    style={{ width: "100%", height: "100%" }}
                                                >
                                                    Apply
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }
                                placement="right"
                                arrow
                                interactive="true"
                                open={openRotateTooltip}
                                disableFocusListener
                                disableHoverListener
                            >
                                <Typography sx={{ paddingBottom: 1, paddingTop: 0 }}>ROTATE</Typography>
                            </HtmlTooltip>
                            <IconButton sx={{ border: 0 }} onClick={() => handleScaleTooltipOpen()} style={showScale ? { backgroundColor: "lightgray" } : {}}>
                                <ZoomOutMapIcon sx={{ color: "black", fontSize: "2em" }} />
                            </IconButton>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Slider
                                            id={branding.location + "-slider"}
                                            color="warning"
                                            aria-label="Scale"
                                            defaultValue={scale}
                                            onChange={handleScaleSlider}
                                            valueLabelDisplay="auto"
                                            min={10}
                                            max={200}
                                        />
                                        <Grid container spacing={1}>
                                            <Grid item xs={9}>
                                                <TextField
                                                    id={branding.location}
                                                    color="warning"
                                                    autoFocus={true}
                                                    onChange={(e) => {
                                                        handleScale(e, null);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        handleScaleInput(e);
                                                    }}
                                                    inputProps={{ defaultValue: scale }}
                                                    type="number"
                                                    label="Scale"
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button onClick={handleScaleTooltipClose} variant="contained" color="warning" style={{ width: "100%", height: "100%" }}>
                                                    Apply
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }
                                placement="right"
                                arrow
                                interactive="true"
                                open={openScaleTooltip}
                                disableFocusListener
                                disableHoverListener
                            >
                                <Typography sx={{ paddingBottom: 1, paddingTop: 0 }}>SCALE</Typography>
                            </HtmlTooltip>
                            {/* {sessionStorage.getItem("productCode") === "BG201" ? (
                            <Button sx={{ color: "black" }} onClick={() => handleToggleStrap()}>{showStrap ? "Show Strap" : "Down Strap"}</Button>
                        ) : null
                        }
                        {sessionStorage.getItem("productCode") === "BG206" ? (
                            <Button sx={{ color: "black" }} onClick={() => handleToggleStrap()}>{showStrap ? "Down Strap" : "Up Strap"}</Button>
                        ) : null
                        } */}
                        </div>

                        <Button
                            onClick={handleClickBack}
                            className="btn"
                            style={{
                                backgroundColor: "#FFBF4F",
                                color: "black",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                marginTop: "1.8rem",
                                paddingTop: "0.7rem",
                                paddingBottom: "0.7rem",
                                paddingRight: "0.6rem",
                                paddingLeft: "1rem",

                            }}
                        >
                            <NavigateBeforeRoundedIcon />
                            Back
                        </Button>

                    </div>
                </Grid>
                {
                    <Grid item xs={5} sx={{ display: "none" }}>
                        <div>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: "1px solid black",
                                    marginTop: 50,
                                    width: sessionStorage.getItem("productType") === "pen" ? 128 : 384,
                                    height: 535,
                                    backgroundColor: "pink",
                                }}
                            ></canvas>
                        </div>
                    </Grid>
                }
                <Grid item xs={6} sx={{ minHeight: 630, margin: "auto" }} align="center">
                    <Typography sx={{ fontSize: "1.5em", fontzWeight: 200 }}>
                        {/* Imprint Area: {branding.location === 'CENTERED ON CAP' ? '2" x 2"': '3" x 2"'} */}
                        Product: {sessionStorage.getItem("productCode")}
                    </Typography>
                    <Typography sx={{ fontSize: "0.95em", fonztWeight: 200, marginTop: 0 }}>
                        {branding.process} - {branding.location}
                    </Typography>
                    <Typography sx={{ fontSize: "0.95em", fontzWeight: 200 }}>
                        Width: {parseFloat(x).toFixed(2)}", Height:{parseFloat(y).toFixed(2)}"
                    </Typography>
                    <Grid item xs={4} sx={{ padding: 2 }}></Grid>
                    {showImg === true && (
                        <Grid
                            className="canvas-img"
                            style={{
                                backgroundColor: "#e8e8e8",
                                width: canvasWidth,
                                height: canvasLength,
                                backgroundImage: isVisibleBackground ? "url(/pre-background.png)" : "",
                                display: "flex",
                                placeContent: "center",
                                borderRadius: branding.location === "CENTERED ON CAP" ? "500px" : "0px",
                            }}
                        >
                            <ReactCrop
                                crop={crop}
                                disabled
                                style={{
                                    height: 535,
                                    width: 386,
                                    borderRadius: branding.location === "CENTERED ON CAP" ? "180px" : "0px",
                                    borderColor: "black",
                                }}
                                onChange={(_, percentCrop) => {
                                    setCrop(percentCrop);
                                }}
                                onComplete={(cropped) => updateBrandingDetail({ completedCrop: cropped })}
                            >
                                {/* seems there should be a div here encapsulating img, might work with a Draggable, maybe use objectPosition to remove TRANSLATE altogether */}
                                <div style={{
                                    display: "flex",
                                    placeContent: "center",
                                }}>
                                    <Draggable disabled position={{ x: translate.x, y: translate.y }} scale={scale / 100}>
                                        <div
                                            style={{
                                                transform: `scale(${scale / 100}) rotate(${branding.rotate}deg)`,
                                            }}
                                        >
                                            <img
                                                ref={imgRef}
                                                alt="Logo Here"
                                                src={branding.imgStr}
                                                style={{
                                                    transform: `scale(${scale / 100}) rotate(${branding.rotate}deg)`,
                                                    marginTop: 84,
                                                    width: 384,
                                                }}
                                                onLoad={onImageLoad}
                                                id="img-main"
                                            />
                                        </div>
                                    </Draggable>
                                </div>
                            </ReactCrop>
                        </Grid>
                    )}
                    {showMove === true && (
                        <div
                            className="drag-area"
                            style={{
                                cursor: "move",
                                borderRadius: branding.location === "CENTERED ON CAP" ? "500px" : "0px",
                                backgroundColor: "#e8e8e8",
                                width: canvasWidth,
                                height: canvasLength,
                                overflow: "hidden",
                                backgroundImage: isVisibleBackground ? "url(/pre-background.png)" : "",
                                display: "flex",
                                placeContent: "center"
                            }}
                        >
                            <Draggable
                                onDrag={handleDrag}
                                onStop={onStop}
                                onTouchStart={onTouchStart}
                                onTouchMove={onTouchMove}
                                onTouchEnd={onTouchEnd}
                                position={{ x: translate.x, y: translate.y }}
                            >
                                <div>
                                    <img
                                        ref={imgRef}
                                        alt="Move Me"
                                        src={branding.imgStr}
                                        style={{
                                            transform: `scale(${scale / 100}) rotate(${branding.rotate}deg)`,
                                            marginTop: 84,
                                            width: 384,
                                        }}
                                    />
                                </div>
                            </Draggable>
                        </div>
                    )}
                </Grid>
            </Grid>
            {(sessionStorage.getItem("productCode") === "BG201" || sessionStorage.getItem("productCode") === "BG206") ? (
                <Grid>
                    <div className="custom-box-container">
                        <div className="options-container">
                            <Typography sx={{ fontWeight: 900 }}>Options:</Typography>
                            <div
                                className="custom-box two"
                            >

                                {sessionStorage.getItem("productCode") === "BG201" ? (
                                    <Button
                                        className="location-btn"
                                        style={{
                                            backgroundColor: "#FFBF4F",
                                            color: "black",
                                            fontSize: "0.64rem",
                                            marginRight: 10,
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => handleToggleStrap()}
                                    >
                                        {showStrap ? "Show Strap" : "Down Strap"}
                                    </Button>
                                ) : null
                                }
                                {sessionStorage.getItem("productCode") === "BG206" ? (
                                    <Button
                                        className="location-btn"
                                        style={{
                                            backgroundColor: "#FFBF4F",
                                            color: "black",
                                            fontSize: "0.64rem",
                                            marginRight: 10,
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => handleToggleStrap()}
                                    >
                                        {showStrap ? "Down Strap" : "Up Strap"}
                                    </Button>
                                ) : null
                                }
                            </div>
                        </div>

                    </div>
                </Grid>
            ) : null
            }
        </>
    );
}
