import React, { useEffect, useRef, useContext, useState, useMemo } from "react";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import EventEmitterContext from '../../../Utils/EventEmitterContext';
import { useFrame } from "@react-three/fiber";



const LogoCenteredAnchor = (props) => {
    const mesh = useRef()
    const texture = new THREE.TextureLoader().load(props.image)
    texture.repeat = new THREE.Vector2(1, 0.95)
    texture.center = new THREE.Vector2(1, 1)
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    texture.encoding = THREE.sRGBEncoding;
    const [load, setLoad] = useState(false)
    const eventEmitter = useContext(EventEmitterContext);
    const [customShape, setCustomShape] = useState(null)



    let process = props.process
    let transparent = true
    let roughness = 1
    let metalness = 0
    let clearcoat = 0
    let clearcoatRoughness = 0
    let metalMap = null
    let colorFilter = null;
    let emissiveFilter = null;
    let args = [10, 10];
    let scale = 1;


    useEffect(() => {
        const callback = () => {
            // Handle the event
            setLoad(true)

        };

        eventEmitter.on('fullyLoaded', callback);
        return () => eventEmitter.off('fullyLoaded')
    }, [])


    useEffect(() => {
        // Load the FBX file only when load is true 
        if (load) {
            if (props.productCode === "BG504") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_mesh_f.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            } else if (props.productCode === "BGR104" || props.productCode === "BG104") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG206") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_front.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR204") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_centeredBetweenAnchors_Front.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG204") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_centeredBetweenAnchors_Front.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG201") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_center_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG600") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_centered_F_brand.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG700") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_f.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG706") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_centered_F.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR101") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_front_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR102") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_front_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR103") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_centeredPocket_F.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR122") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_pocket.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
        }
    }, [load, props.productCode]);


    if (load) {

        if (process === "LASER ENGRAVED" || process === "REFLECTION LASER") {
            metalness = 1;
            roughness = 0.35;
            metalMap = new THREE.TextureLoader().load("brushed.png");
        } else if (process === "SILK SCREENED" || process === "LASER TOUCH") {
            metalness = 0.75;
            roughness = 1;
            // colorFilter = new THREE.Color( 0x969696 )
            // emissiveFilter = new THREE.Color( 0x040404 )
        } else if (process === "4CP FLEX") {
            clearcoat = 0;
            clearcoatRoughness = 0;
        } else if (process === "4CP FLEX TRANSFER") {
            metalness = 0.90;
            roughness = 1;
        } else {
            metalness = 0.75;
            roughness = 1;
            clearcoat = 0.75
            clearcoatRoughness = 0.075
            // colorFilter = new THREE.Color(0x969696)
            // emissiveFilter = new THREE.Color(0x040404)
        }
    }
    if (load) {
        if (props.productCode === 'BG500') {
            args = [0.75, 1];
        }
        else if (props.productCode === 'BG504') {
            // texture.repeat = new THREE.Vector2(1.20, 1.10);
        }

        else if (props.productCode === 'BG104') {
            args = [1, 1]
        }
        else if (props.productCode === 'BG201') {
            args = [1, 1]
        }
        else if (props.productCode === 'BG116') {
            args = [0.55, 0.75]
        }
        else if (props.productCode === 'BG102') {
            args = [0.33, 0.47]
        }
        else {
            args = [0.75, 1];
        }
    }
    useEffect(() => {
        if (load) {
            if (props.productCode === 'BG500') {
                mesh.current.rotation.x = -0.24
                mesh.current.rotation.y = 0.00
            } else if (props.productCode === 'BG504') {
                mesh.current.rotation.x = -0.011
                mesh.current.rotation.y = 0.00
                mesh.current.rotation.z = 0.00
            }
            else if (props.productCode === "BG201") {
                mesh.current.rotation.x = -0.05
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR104") {
                mesh.current.rotation.x = 0.01
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG104") {
                mesh.current.rotation.x = 0.01
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR204") {
                mesh.current.rotation.x = -0.07
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG204") {
                mesh.current.rotation.x = -0.07
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG104") {
                mesh.current.rotation.x = 0.01
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG206") {
                mesh.current.rotation.x = -0.05
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG116") {
                mesh.current.rotation.x = -0.18
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG600") {
                mesh.current.rotation.x = 0
                mesh.current.rotation.y = 0.06
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG700") {
                mesh.current.rotation.x = -0.02
                mesh.current.rotation.y = -0.02
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG706") {
                mesh.current.rotation.x = -0.046
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR101") {
                mesh.current.rotation.x = 0.02
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR102") {
                mesh.current.rotation.x = 0.02
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG102") {
                mesh.current.rotation.x = 0.015
                mesh.current.rotation.y = 3.17
                mesh.current.rotation.z = 0
            }

        }
    }, [load])

    useFrame(() => {
        if (load && props.productCode === "BG504") {
            mesh.current.scale.set(0.06, 0.06, 0.06);
        }
        else if (load && props.productCode === "BGR104") {
            mesh.current.scale.set(0.05, 0.068, 0.06);
        }
        else if (load && props.productCode === "BG104") {
            mesh.current.scale.set(0.05, 0.068, 0.06);
        }
        else if (load && props.productCode === "BG206") {
            mesh.current.scale.set(0.05, 0.077, 0.001);
        }
        else if (load && props.productCode === "BGR204") {
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BG204") {
            mesh.current.scale.set(0.048, 0.07, 0.07);
        }
        else if (load && props.productCode === "BG201") {
            mesh.current.scale.set(0.05, 0.068, 0.05);
        }
        else if (load && props.productCode === "BG600") {
            mesh.current.scale.set(0.55, 0.60, 0.50);
        }
        else if (load && props.productCode === "BG700") {
            // mesh.current.scale.set(0.15, 0.20, 1);
            mesh.current.scale.set(0.12, 0.175, 0.19)
        } else if (load && props.productCode === "BG706") {
            // mesh.current.scale.set(0.15, 0.20, 1);
            mesh.current.scale.set(0.10, 0.147, 0.11)
        }
        else if (load && props.productCode === "BGR101") {
            // mesh.current.scale.set(0.15, 0.20, 1);
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BGR102") {
            // mesh.current.scale.set(0.15, 0.20, 1);
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BGR103") {
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }
        else if (load && props.productCode === "BGR122") {
            mesh.current.scale.set(0.05, 0.07, 0.07);
        }

    })

    return load ? (
        <mesh
            {...props}
            ref={mesh}
            scale={scale}
        >
            {
                (props.productCode === "BG504" ||
                    props.productCode === "BGR104" ||
                    props.productCode === "BG104" ||
                    props.productCode === "BG206" ||
                    props.productCode === "BGR204" ||
                    props.productCode === "BG204" ||
                    props.productCode === "BG201" ||
                    props.productCode === "BG700" ||
                    props.productCode === "BG706" ||
                    props.productCode === "BGR101" ||
                    props.productCode === "BGR102" ||
                    props.productCode === "BGR103" ||
                    props.productCode === "BGR122"
                ) && customShape ? (
                    <bufferGeometry attach="geometry"
                        attributes={customShape.children[0].geometry.attributes}
                        boundingBox={customShape.children[0].geometry.boundingBox}
                        boundingSphere={customShape.children[0].geometry.boundingSphere}
                        drawRange={customShape.children[0].geometry.drawRange}
                        groups={customShape.children[0].geometry.groups}
                        index={customShape.children[0].geometry.index}
                        morphAttributes={customShape.children[0].geometry.morphAttributes}
                        morphTargetsRelative={customShape.children[0].geometry.morphTargetsRelative}
                        name={customShape.children[0].geometry.name}
                        type={customShape.children[0].geometry.type}
                        userData={customShape.children[0].geometry.userData}
                        uuid={customShape.children[0].geometry.uuid}
                    >
                    </bufferGeometry>
                ) : (
                    <planeBufferGeometry attach="geometry" args={args} />
                )
            }
            <meshPhysicalMaterial
                transparent={transparent}
                side={THREE.FrontSide}
                roughness={roughness}
                clearcoatRoughness={clearcoatRoughness}
                clearcoat={clearcoat}
                metalness={metalness}
                metalnessMap={metalMap}
                color={colorFilter}
                emissive={emissiveFilter}
            // anisotropy={4}

            >
                <primitive attach="map" object={texture} />
            </meshPhysicalMaterial>
        </mesh>
    ) : null;
}

export default LogoCenteredAnchor