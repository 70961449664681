import React, { createContext } from 'react';
import createEventEmitter from './EventEmitter';

// Create an instance of the event emitter
const eventEmitter = createEventEmitter();

// Create the event emitter context
const EventEmitterContext = createContext(eventEmitter);

export default EventEmitterContext;
