import React, { useEffect, useRef, useContext, useState, useMemo } from "react";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import EventEmitterContext from '../../../Utils/EventEmitterContext';
import { useFrame } from "@react-three/fiber";



const LogoCenteredFlap = (props) => {
    const mesh = useRef()
    const texture = new THREE.TextureLoader().load(props.image)
    texture.repeat = new THREE.Vector2(1, 0.95)
    texture.center = new THREE.Vector2(1, 1)
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    texture.generateMipmaps = true;
    texture.encoding = THREE.sRGBEncoding;
    const [load, setLoad] = useState(false)
    const eventEmitter = useContext(EventEmitterContext);
    const [customShape, setCustomShape] = useState(null)



    let process = props.process
    let transparent = true
    let roughness = 1
    let metalness = 0
    let clearcoat = 0
    let clearcoatRoughness = 0
    let metalMap = null
    let colorFilter = null;
    let emissiveFilter = null;
    let args = [10, 10];
    let scale = 1;


    useEffect(() => {
        const callback = () => {
            setLoad(true)
        };
        eventEmitter.on('fullyLoaded', callback);
        return () => eventEmitter.off('fullyLoaded')
    }, [])


    useEffect(() => {
        if (load) {
            if (props.productCode === "BG504" || props.productCode === "BGR104" || props.productCode === "BG104") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_mesh_f.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            } else if (props.productCode === "BG206") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_top.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR101") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_flap.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR102") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_flap.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BGR122") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_flap.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
            else if (props.productCode === "BG102") {
                const loadCustomShape = async () => {
                    try {
                        const customShapeData = await new Promise((resolve, reject) => {
                            new FBXLoader().load('/' + props.productCode + '_branding_flap.fbx', resolve, undefined, reject);
                        });
                        setCustomShape(customShapeData);
                    } catch (error) {
                        console.error("Error loading FBX:", error);
                    }
                };
                loadCustomShape();
            }
        }
    }, [load, props.productCode]);

    // const options = useMemo(() => {
    //     return {
    //         x: { value: 0, min: -15, max: 15, step: 0.01 },
    //         y: { value: 0, min: -15, max: 15, step: 0.01 },
    //         z: { value: 0, min: -15, max: 15, step: 0.01 },
    //     }
    // }, [])

    // const rot = useControls('ROTATION FLAP', options)
    if (load) {
        if (process === "LASER ENGRAVED" || process === "REFLECTION LASER") {
            metalness = 1;
            roughness = 0.35;
            metalMap = new THREE.TextureLoader().load("brushed.png");
        } else if (process === "SILK SCREENED" || process === "LASER TOUCH") {
            metalness = 0.75;
            roughness = 1;
            // colorFilter = new THREE.Color( 0x969696 )
            // emissiveFilter = new THREE.Color( 0x040404 )
        } else if (process === "4CP FLEX") {
            clearcoat = 0;
            clearcoatRoughness = 0;
        } else if (process === "4CP FLEX TRANSFER") {
            metalness = 0.90;
            roughness = 1;
        } else {
            metalness = 0.75;
            roughness = 1;
            clearcoat = 0.75
            clearcoatRoughness = 0.075
            // colorFilter = new THREE.Color(0x969696)
            // emissiveFilter = new THREE.Color(0x040404)
        }
    }
    if (load) {
        if (props.productCode === 'BG500') {
            args = [0.75, 1];
        }
        else if (props.productCode === 'BGR104') {
            args = [1, 1]
        }
        else if (props.productCode === 'BG400') {
            args = [0.70, 1]
        }
        else {
            args = [0.75, 1];
        }
    }
    useEffect(() => {
        if (load) {
            if (props.productCode === "BGR104") {
                mesh.current.rotation.x = -0.05
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            } else if (props.productCode === "BG104") {
                mesh.current.rotation.x = -0.07
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG206") {
                if (props.isRectangle === "vertical") {
                    mesh.current.rotation.x = -3.85
                    mesh.current.rotation.y = 4.69
                    mesh.current.rotation.z = -3.70
                }
                else {
                    mesh.current.rotation.x = -0.12
                    mesh.current.rotation.y = 3.16
                    mesh.current.rotation.z = 0.00
                }
            }
            else if (props.productCode === "BGR101") {
                mesh.current.rotation.x = -0.07
                mesh.current.rotation.y = 0.03
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR102") {
                mesh.current.rotation.x = -0.08
                mesh.current.rotation.y = 0.02
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG400") {
                mesh.current.rotation.x = -0.07
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BGR122") {
                mesh.current.rotation.x = -0.11
                mesh.current.rotation.y = 0.02
                mesh.current.rotation.z = 0
            }
            else if (props.productCode === "BG102") {
                mesh.current.rotation.x = -0.02
                mesh.current.rotation.y = 0
                mesh.current.rotation.z = 0
            }
        }
    }, [load])

    useFrame(() => {
        // const cst = mesh.current
        if (load && props.productCode === "BGR104") {
            mesh.current.scale.set(0.05, 0.077, 0.06);
        } else if (load && props.productCode === "BG104") {
            mesh.current.scale.set(0.05, 0.077, 0.06);
        }
        else if (load && props.productCode === "BGR101") {
            mesh.current.scale.set(0.056, 0.077, 0.06);
        }
        else if (load && props.productCode === "BGR102") {
            mesh.current.scale.set(0.056, 0.077, 0.06);
        }
        else if (load && props.productCode === "BG206") {
            if (props.isRectangle === "vertical") {
                mesh.current.scale.set(0.012, 0.012, 0.12);
            }
            else {
                mesh.current.scale.set(0.0135, 0.010, 0.101);
            }
        }
        else if (load && props.productCode === "BGR122") {
            mesh.current.scale.set(0.05, 0.07, 0.05);
        }
        else if (load && props.productCode === "BG102") {
            if (props.isRectangle === "vertical") {
                console.log('vertical')
                mesh.current.scale.set(0.012, 0.012, 0.12);
            }
            else {
                console.log('else')
                mesh.current.scale.set(0.034, 0.05, 0.03);
            }
        }
    })
    return load ? (
        <mesh
            {...props}
            ref={mesh}
            scale={scale}
        >
            {
                (props.productCode === "BGR104" ||
                    props.productCode === "BG104" ||
                    props.productCode === "BG206" ||
                    props.productCode === "BGR101" ||
                    props.productCode === "BGR102" ||
                    props.productCode === "BGR122" ||
                    props.productCode === "BG102"
                ) && customShape ? (
                    <bufferGeometry attach="geometry"
                        attributes={customShape.children[0].geometry.attributes}
                        boundingBox={customShape.children[0].geometry.boundingBox}
                        boundingSphere={customShape.children[0].geometry.boundingSphere}
                        drawRange={customShape.children[0].geometry.drawRange}
                        groups={customShape.children[0].geometry.groups}
                        index={customShape.children[0].geometry.index}
                        morphAttributes={customShape.children[0].geometry.morphAttributes}
                        morphTargetsRelative={customShape.children[0].geometry.morphTargetsRelative}
                        name={customShape.children[0].geometry.name}
                        type={customShape.children[0].geometry.type}
                        userData={customShape.children[0].geometry.userData}
                        uuid={customShape.children[0].geometry.uuid}
                    >
                    </bufferGeometry>
                ) : (
                    <planeBufferGeometry attach="geometry" args={args} />
                )
            }
            <meshPhysicalMaterial
                transparent={transparent}
                side={THREE.FrontSide}
                roughness={roughness}
                clearcoatRoughness={clearcoatRoughness}
                clearcoat={clearcoat}
                metalness={metalness}
                metalnessMap={metalMap}
                color={colorFilter}
                emissive={emissiveFilter}
                anisotropy={4}

            >
                <primitive attach="map" object={texture} />
            </meshPhysicalMaterial>
        </mesh>
    ) : null;
}

export default LogoCenteredFlap