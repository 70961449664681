import React, { useState, useEffect } from 'react'
import circle from "../../assets/circle.gif"
import { useParams } from "react-router-dom";


const ThankYou = ({ onConfirm, onCancel, title, messageOne, redirectMess, french }) => {
    const [countdown, setCountdown] = useState(10)
    const [shouldCountdown, setShouldCountdown] = useState(true);
    const { productCode } = useParams();
    let timer;


    useEffect(() => {
        if (countdown === 0) {

            if (french) {
                window.location.href = `http://spectorandco.com/fr/spe/product/${productCode}`;
            } else {
                window.location.href = `http://spectorandco.com/us/spe/product/${productCode}`;
            }
            setShouldCountdown(false);
        }
    }, [countdown, productCode]);

    useEffect(() => {
        if (shouldCountdown && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCount) => prevCount - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [shouldCountdown, countdown]);

    const handleConfirm = () => {
        setShouldCountdown(false);
        clearInterval(timer);
        onConfirm();
        // window.location.href = `http://spectorandco.com/us/spe/product/${productCode}`;
    };

    const handleCancel = () => {
        setShouldCountdown(false);
        clearInterval(timer);
        onCancel();
    };

    return (
        <div className="d-flex flex-column align-items-center my-4">
            <div className="my-2 text-center">
                <h1 className="font-bold">{title}</h1>
                <p className="">
                    {messageOne}
                </p>
            </div>

            <img src={circle} alt='loading' loading='lazy' />

            <div className="text-center mt-2">
                {redirectMess} <span className='countdown'>{countdown} {french ? "secondes" : "seconds"}.</span>
                <div className='d-flex gap-5 justify-content-center mt-5'>
                    <button onClick={handleCancel} className='btn bg-gray'>{french ? "Non" : "No"}</button>
                    <button onClick={handleConfirm} className='btn bg-yellow'>{french ? "Oui" : "Yes"}</button>
                </div>

            </div>

        </div>
    )
}

export default ThankYou