


const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0,
  translateX,
  translateY,
  position,
  originX = 0,
  originY = 0,
  imageWidth = 520, // Hardcoded width value
  imageHeight = 560 // Hardcoded height value
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;

  if (sessionStorage.getItem("productType") === "pen") {
    canvas.width = 190;
    crop.width = 190;
  }

  if (position === "CENTERED ON CAP") {
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
  }

  if (sessionStorage.getItem("productCode") === "DW307") {
    canvas.width = crop.width * scaleX;
  }

  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;

  ctx.save();

  const wrh = image.width / image.height;
  let newWidth, newHeight;

  if (sessionStorage.getItem("productCode") === "BGR710") {
    // Use static width and height for productCode "BGR710"
    newWidth = imageWidth;
    newHeight = imageHeight;
  } else if (sessionStorage.getItem("productCode") === "BG504") {
    newWidth = imageWidth;
    newHeight = imageHeight;
  }
  else {
    newWidth = canvas.width;
    newHeight = newWidth / wrh;
  }

  const xOffset = (canvas.width - newWidth) / 2;
  const yOffset = (canvas.height - newHeight) / 2;

  ctx.translate(translateX * scaleX, translateY * scaleY);
  ctx.translate(originX, originY); // Apply the origin point translation

  ctx.translate(-cropX, -cropY);
  ctx.translate(canvas.width / 2, canvas.height / 2); // Translate to the center of the canvas

  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);

  const drawX = -newWidth / 2 + xOffset;
  const drawY = -newHeight / 2 + yOffset;
  const drawWidth = newWidth - 2 * xOffset;
  const drawHeight = newHeight - 2 * yOffset;

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    drawX,
    drawY,
    drawWidth,
    drawHeight
  );

  ctx.restore();

  return canvas.toDataURL("image/png");
}
