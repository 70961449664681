export const decodeHtmlEntities = (str) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
};

export const cleanText = (str) => {
    return str
        .replace(/â/g, "'")      // Replace ’
        .replace(/â¦/g, "...")     // Replace …
        .replace(/â¢/g, "•")      // Replace •
        .replace(/â/g, "–")      // Replace en-dash
        .replace(/â/g, "—")      // Replace em-dash
        .replace(/Â½/g, "½")       // Replace ½
        .replace(/â/g, "√")      // Replace √
        .replace(/%3B/g, "")       // Remove %3B
        .replace(/â/g, "“")      // Replace opening quote
        .replace(/â/g, "”")      // Replace closing quote
        .replace(/â/g, "‘")      // Replace opening single quote
        .replace(/â/g, "’")      // Replace closing single quote
        .replace(/Ã©/g, "é")       // Replace é
        .replace(/Ã¨/g, "è")       // Replace è
        .replace(/Ã/g, "")         // Remove Ã
        .replace(/Â/g, "")         // Remove Â (specific replacement added)
        .replace(/¢/g, "à")        // Replace ¢ with à
        .replace(/ª/g, "à")        // Replace ª with à
        .replace(/¢/g, "à")        // Replace ¢ with à
        .replace(/ª/g, "à")        // Replace ª with à
        .replace(/prªt/g, "prêt")  // Fix "prªt" to "prêt"
        .replace(/gr¢ce/g, "grâce")// Fix "gr¢ce" to "grâce"
        .replace(//g, "")         // Remove the invalid character ''
        .replace(/c´té/g, "coté")  // Fix "c´té" to "côté"
        .replace(/inaper§u/g, "inaperçu") // Fix "inaper§u" to "inaperçu"
        .replace(/%26/g, "&")      // Replace %26 with &
        .replace(/\s{2,}/g, " ")   // Replace multiple spaces with a single space
        .trim();                    // Remove any leading or trailing spaces
};


export const getCleanedDescription = (description) => {
    if (!description) return "";
    const decodedDescription = decodeHtmlEntities(description);
    return cleanText(decodedDescription);
};
