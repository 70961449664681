import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Models/Home";
import ProductBuild from "./components/Models/ProductBuild";
import Quote from "./components/Models/Quote";
import QuoteFrench from "./components/Models/QuoteFrench";
import ProductSelection from "./components/Models/ProductSelectionNew";
import NotSupported from "./components/Models/NotSupported";
import EventEmitterContext from "./Utils/EventEmitterContext";
import createEventEmitter from "./Utils/EventEmitter";

const eventEmitter = createEventEmitter();

const App = () => {
  return (
    <EventEmitterContext.Provider value={eventEmitter}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/brand-designer" element={<Home />} />
          <Route exact path="/:sku" element={<ProductSelection />} />
          <Route exact path="/" element={<ProductSelection />} />
          <Route exact path="/product-build" element={<ProductBuild />} />
          <Route exact path="/not-supported" element={<NotSupported />} />
          <Route exact path="/forms/us/quote/:productCode" element={<Quote />} />
          <Route exact path="/forms/fr/quote/:productCode" element={<QuoteFrench />} />
        </Routes>
      </BrowserRouter>
    </EventEmitterContext.Provider>
  );
};

export default App;
