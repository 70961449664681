import React, { useEffect, useRef, useState, useContext } from "react";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import EventEmitterContext from '../../../Utils/EventEmitterContext';
import { useFrame } from "@react-three/fiber";
const Logo = (props) => {
  const mesh = useRef();
  const texture = new THREE.TextureLoader().load(props.image);
  texture.encoding = THREE.sRGBEncoding;
  const transparent = true;
  const [load, setLoad] = useState(false)
  const eventEmitter = useContext(EventEmitterContext);
  const [customShape, setCustomShape] = useState(null)

  // const transparent = false
  let process = props.process;
  let roughness = 1;
  let metalness = 0;
  let clearcoat = 0;
  let clearcoatRoughness = 0;
  let metalMap = null;
  let args = [0.9575, 0.9575, 2.35, 250, 250, true, -1, 2];
  let colorFilter = null;
  let emissiveFilter = null;
  let scale = 1;
  // const prodType = sessionStorage.getItem("productType")

  useEffect(() => {
    const callback = () => {
      setLoad(true)
    };

    eventEmitter.on('fullyLoaded', callback);
    return () => eventEmitter.off('fullyLoaded')
  }, [])

  useEffect(() => {
    // Load the FBX file only when props.productCode is not "BG504"
    if (load && (props.productCode === "DW307" || props.productCode === "DW103")) {
      const loadCustomShape = async () => {
        try {
          const customShapeData = await new Promise((resolve, reject) => {
            new FBXLoader().load('/' + props.productCode + '_branding_mesh_f.fbx', resolve, undefined, reject);
          });
          setCustomShape(customShapeData);
        } catch (error) {
          console.error("Error loading FBX:", error);
        }
      };
      loadCustomShape();
    } else if (props.productCode === "BGR210") {
      const loadCustomShape = async () => {
        try {
          const customShapeData = await new Promise((resolve, reject) => {
            new FBXLoader().load('/' + props.productCode + '_branding_f.fbx', resolve, undefined, reject);
          });
          setCustomShape(customShapeData);
        } catch (error) {
          console.error("Error loading FBX:", error);
        }
      };
      loadCustomShape();
    }
  }, [load, props.productCode]);



  if (load) {
    if (props.productCode !== "DW307" && props.productCode !== "DW103") {
      texture.repeat = new THREE.Vector2(1, 0.95);
      texture.center = new THREE.Vector2(1, 1);
    } else {
      if (props.productCode === "DW307") {
        scale = 0.255;
        if (props.isRectangle === "vertical") {
          texture.repeat = new THREE.Vector2(1, 1.65);
          texture.center = new THREE.Vector2(1, 0.5);
        } else if (props.isRectangle === "normal") {
          texture.repeat = new THREE.Vector2(0.55, 1.2);
          texture.center = new THREE.Vector2(0.5, 0.55);
        } else {
          texture.repeat = new THREE.Vector2(1, 1.65);
          texture.center = new THREE.Vector2(1, 0.5);
        }
      } else if (props.productCode === "DW403") {
        scale = 0.255;
        texture.repeat = new THREE.Vector2(0.2, 1.2);
        texture.center = new THREE.Vector2(0.5, 0.5);
      } else if (props.productCode === "DW415") {
        // scale = 0.21;
        // texture.repeat = new THREE.Vector2(0.5, 0.75);
        // texture.center = new THREE.Vector2(0.1, 0.1);
      } else if (props.productCode === "DW103") {
        scale = 0.2125;
        texture.repeat = new THREE.Vector2(1, 0.95);
        texture.center = new THREE.Vector2(1, 1);
      }
    }
    if (process === "LASER ENGRAVED" || process === "REFLECTION LASER") {
      metalness = 1;
      roughness = 0.35;
      metalMap = new THREE.TextureLoader().load("brushed.png");
    } else if (process === "SILK SCREENED" || process === "LASER TOUCH") {
      metalness = 0.75;
      roughness = 1;

    } else if (process === "4CP FLEX") {
      clearcoat = 0;
      clearcoatRoughness = 0;
    }
    else if (process === "4CP FLEX TRANSFER") {
      metalness = 0.90;
      roughness = 1;
    }
    else {
      metalness = 0.75;
      roughness = 1;
      clearcoat = 0.75
      clearcoatRoughness = 0.075

    }
  }
  if (load) {
    if (props.productCode === "DW409") {
      args = [0.995, 0.995, 2.35, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW313") {
      args = [0.835, 0.835, 2.35, 250, 250, true, -1, 2.35];
    } else if (props.productCode === "DW319") {
      args = [0.985, 0.985, 2.35, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW316" || props.productCode === "DW317") {
      args = [0.8205, 0.8205, 1.85, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW311") {
      args = [0.8, 0.745, 1.9, 250, 250, false, -1, 2];
    } else if (props.productCode === "DW309") {
      args = [0.8, 0.745, 1.9, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW314") {
      args = [0.82, 0.7675, 1.9, 250, 250, false, -1, 2];
    } else if (props.productCode === "DW403") {
      args = [1.095, 1.095, 1.15, 250, 250, true, -1, 1.05];
    } else if (props.productCode === "DW404") {
      args = [1.095, 1.095, 1.425, 250, 250, true, -1, 1.05];
    } else if (props.productCode === "DW413") {
      args = [1.095, 1.095, 1.2, 250, 250, true, -1, 1.05];
    } else if (props.productCode === "DW410") {
      args = [1.1065, 1.11525, 1.65, 250, 250, true, -1, 1.55];
    } else if (props.productCode === "DW412") {
      args = [1.11, 1.11, 1.75, 250, 250, true, -1, 1.55];
    } else if (props.productCode === "DW414") {
      args = [1.109475, 0.905, 2.15, 250, 250, true, -1, 1.55];
    } else if (props.productCode === "DW415") {
      args = [1.1125, 0.91, 1.75, 250, 250, true, -0.9, 1.4];
    } else if (props.productCode === "DW416") {
      args = [1.11, 0.91, 1.75, 250, 250, true, -0.9, 1.4];
    } else if (props.productCode === "DW321") {
      args = [0.91, 0.91, 2.125, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW322") {
      args = [0.875, 0.875, 2.11, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW323") {
      args = [0.76, 0.76, 1.85, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW402") {
      args = [1.1065, 1.11525, 1.65, 250, 250, true, -1, 1.55];
    } else if (props.productCode === "DW406") {
      args = [1.11775, 1.11775, 2, 250, 250, true, -1, 1.4];
    } else if (props.productCode === "DW408") {
      args = [1.12, 1.12, 2, 250, 250, true, -1, 1.4];

    } else if (props.productCode === "DW411") {
      args = [1.11775, 1.11775, 2, 250, 250, true, -1, 1.4];
    } else if (props.productCode === "DW407") {
      args = [1.1177, 1.1179, 2, 250, 250, true, -1, 1.4];
    } else if (props.productCode === "DW306") {
      args = [0.985, 0.985, 2.15, 250, 250, true, -1, 1.55];
    } else if (props.productCode === "DW101") {
      args = [0.9, 0.915, 2.15, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW100" || props.productCode === "DW300") {
      args = [0.895, 0.895, 2.15, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW310") {
      args = [0.9275, 0.9275, 2.15, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW301") {
      args = [0.991875, 1.005, 1.85, 250, 250, true, -1, 1.6];
    } else if (props.productCode === "DW308") {
      args = [0.875, 0.875, 2.35, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW103") {
      args = [1, 0.875, 2.1, 250, 250, true, -2, 1.5]
    } else if (props.productCode === "DW401") {
      args = [1.142, 0.948, 2.35, 250, 250, false, -1, 2];
    } else if (props.productCode === "DW307") {
      args = [0.9575, 0.9575, 2.35, 250, 250, true, -0.625, 1.25];
    } else if (props.productCode === "DW604" || props.productCode === "DW603") {
      args = [1.365, 1.2165, 1.55, 250, 250, true, -0.6, 1.25];
    } else if (props.productCode === "G3141") {
      args = [0.1775, 0.1775, 1, 250, 250, true, 0.7, 1.8];
    } else if (props.productCode === "G1282") {
      args = [0.185, 0.185, 1.1, 250, 250, true, 0.7, 1.8];
    } else if (props.productCode === "G1005" || props.productCode === "G1009") {
      args = [0.215, 0.215, 1.25, 250, 250, true, -0.9, 1.8];
    } else if (props.productCode === "G1247") {
      args = [0.199, 0.215, 1.25, 250, 250, true, -0.9, 1.8];
    } else if (props.productCode === "G1182") {
      args = [0.205, 0.205, 1.1, 250, 250, true, -0.7, 1.8];
    } else if (props.productCode === "EC122") {
      args = [0.205, 0.205, 1.1, 250, 250, true, -0.9, 1.8];
    } else if (props.productCode === "I153") {
      args = [0.2125, 0.2125, 1.1, 250, 250, true, -0.8, 1.5];
    }
    else if (props.productCode === "I160") {
      if (props.isRectangle === "vertical") {
        console.log('vertical')
        args = [0.2125, 0.2125, 1.7, 250, 250, true, -0.8, 1.5];
      } else if (props.isRectangle === "normal") {
        console.log('normal')
        args = [0.2125, 0.2125, 1.1, 250, 250, true, -0.8, 1.5];
      } else {
        console.log('else')
        args = [0.2125, 0.2125, 0.89, 250, 250, true, -0.8, 1.5];
      }

    }
    else if (props.productCode === "G3001") {
      args = [0.205, 0.205, 1.1, 250, 250, true, -0.9, 1.8];
    } else if (props.productCode === "G3038") {
      args = [0.205, 0.205, 1, 250, 250, true, -0.7, 1.4];
    } else if (props.productCode === "G3113") {
      args = [0.205, 0.205, 1, 250, 250, true, -0.7, 1.4];
    } else if (props.productCode === "G1254") {
      args = [0.22, 0.21, 1.2, 250, 250, true, -0.9, 1.8];
    } else if (props.productCode === "G1103") {
      args = [0.2445, 0.235, 1.25, 250, 250, true, -0.9, 1.8];
    } else if (props.productCode === "I154") {
      args = [0.175, 0.175, 1.1, 250, 250, true, 0.7, 1.8];
    } else if (props.productCode === "EC146") {
      args = [0.1925, 0.1925, 1.1, 250, 250, true, 0.7, 1.8];
    } else if (props.productCode === "EC117") {
      args = [0.275, 0.275, 1.4, 250, 250, true, 0.7, 1.7];
    } else if (props.productCode === "I116") {
      args = [0.205, 0.205, 1, 250, 250, true, -0.65, 1.45];
    } else if (props.productCode === "I136") {
      args = [0.205, 0.205, 1, 250, 250, true, -0.8, 1.7];
    } else if (props.productCode === "I151") {
      args = [0.2, 0.212, 1.25, 250, 250, true, -0.8, 1.6];
    } else if (props.productCode === "I150") {
      args = [0.215, 0.215, 1.2, 250, 250, true, -0.7, 1.7];
    } else if (props.productCode === "I128" || props.productCode === "I228") {
      args = [0.205, 0.205, 1, 250, 250, true, -0.7, 1.4];
    } else if (props.productCode === "DW326") {
      args = [1.03, 1.03, 2.1, 250, 250, true, -0.85, 1.7];
    } else if (props.productCode === "DW325") {
      args = [1.03, 1.03, 2.1, 250, 250, true, -0.85, 1.7];
    } else if (props.productCode === "DW324") {
      args = [2, 2, 1.7, 250, 250, true, -0.25, 0.5];
    } else if (props.productCode === "DW201") {
      args = [1.0, 1.0, 2, 250, 250, true, -0.9, 1.6];
    } else if (props.productCode === "DW104") {
      args = [0.78, 0.78, 1.9, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW405") {
      args = [0.9575, 0.9575, 2.35, 250, 250, true, -1, 2];
    } else if (props.productCode === "DW417") {
      args = [2, 2, 1.7, 250, 250, true, -0.3, 0.6];
    } else if (props.productCode === "DW418") {
      args = [0.9575, 0.9575, 1.8, 250, 250, true, -0.75, 1.5];
    } else if (props.productCode === "DW500") {
      args = [0.9575, 0.9575, 1.4, 250, 250, true, -0.575, 1.2];
    } else if (props.productCode === "DW502") {
      args = [0.9575, 0.9575, 1.2, 250, 250, true, -0.565, 1.1];
    }

  }
  useEffect(() => {
    if (load) {
      if (props.productCode === "DW316") {
        mesh.current.rotation.x = 0.01;
      }
      else if (props.productCode === "G1103") {
        mesh.current.rotation.x = 0;
        mesh.current.rotation.y = 0.1
      }
      else if (props.productCode === "DW317") {
        mesh.current.rotation.x = -0.02;
      }
      else if (props.productCode === "DW311") {
        mesh.current.rotation.x = 0;
        mesh.current.rotation.z = 0.0;
      } else if (props.productCode === "DW309") {
        mesh.current.rotation.x = 0;
      } else if (props.productCode === "DW313") {
        mesh.current.rotation.y = -0.175;
      } else if (props.productCode === "DW314") {
        mesh.current.rotation.x = 0;
        mesh.current.rotation.z = 0;
      } else if (props.productCode === "DW404") {
        mesh.current.rotation.x = -0.0725;
        mesh.current.rotation.z = -0.0425;
      } else if (props.productCode === "DW413") {
        mesh.current.rotation.x = -0.0825;
        mesh.current.rotation.y = 0.5;
        mesh.current.rotation.z = -0;
      } else if (props.productCode === "DW403") {
        mesh.current.rotation.x = -0.0828;
        mesh.current.rotation.y = 0.5;
        mesh.current.rotation.z = -0;
      } else if (props.productCode === "DW410" || props.productCode === "DW402") {
        mesh.current.rotation.x = 0.03;
        mesh.current.rotation.y = 0.225;
      } else if (props.productCode === "DW412") {
        mesh.current.rotation.x = 0.03;
        mesh.current.rotation.y = 0.225;
      } else if (props.productCode === "DW414") {
        mesh.current.rotation.y = 0.2;
        mesh.current.rotation.x = 0;
        mesh.current.rotation.z = 0;
      } else if (props.productCode === "DW415") {
        mesh.current.rotation.x = -0.04;
        mesh.current.rotation.y = -0.05;
        mesh.current.rotation.z = 0;
      } else if (props.productCode === "G1254") {
        mesh.current.rotation.y = 0;
        mesh.current.rotation.x = 0;
      } else if (props.productCode === "DW416") {
        mesh.current.rotation.y = 0.2;
        mesh.current.rotation.x = -0.0055;
        mesh.current.rotation.z = 0;
      } else if (props.productCode === "DW402") {
      } else if (props.productCode === "DW406" || props.productCode === "DW407") {
        mesh.current.rotation.y = 0.275;
      } else if (props.productCode === "DW408") {
        mesh.current.rotation.y = -1.275;
      } else if (props.productCode === "DW306") {
        mesh.current.rotation.y = 0.225;
      } else if (props.productCode === "DW301") {
        mesh.current.rotation.x = -0.015;
        mesh.current.rotation.y = 0.2;
      } else if (props.productCode === "DW103") {
        mesh.current.rotation.y = 0;
      } else if (props.productCode === "DW401") {
        mesh.current.rotation.x = 0;
      } else if (props.productCode === "DW307") {
        mesh.current.rotation.y = -0.009;
      } else if (props.productCode === "DW603" || props.productCode === "DW604") {
        mesh.current.rotation.y = 0;
      } else if (props.productCode === "DW411") {
        mesh.current.rotation.y = 0.3;
      } else if (props.productCode === "DW324") {
        mesh.current.rotation.x = 0.02;
      } else if (props.productCode === "DW405") {
        mesh.current.rotation.x = 0.04;
      } else if (props.productCode === "DW417") {
        mesh.current.rotation.x = 0.09;
      } else if (props.productCode === "DW418") {
        mesh.current.rotation.x = 0.04;
      } else if (props.productCode === "DW500") {
        mesh.current.rotation.x = 0.04;
      } else if (props.productCode === "DW502") {
        mesh.current.rotation.x = 0.07;
      }
      else if (props.productCode === "DW101") {
        mesh.current.rotation.x = 0.001;
      }
      else if (props.productCode === "EC117") {

        mesh.current.rotation.x = 0.001
      }
      else if (props.productCode === "I153") {
        mesh.current.rotation.x = 0
        mesh.current.rotation.y = 0.13
        mesh.current.rotation.z = 0
      }
      else if (props.productCode === "I160") {
        mesh.current.rotation.x = 0
        mesh.current.rotation.y = 1.75
        mesh.current.rotation.z = 0
      }
    }
  }, [load]);
  useFrame(() => {
    // const cst = mesh.current
    if (load && props.productCode === "DW415") {
      mesh.current.scale.set(0.85, 0.85, 0.85);
    } else if (load && props.productCode === "BGR210") {
      mesh.current.scale.set(0.05, 0.07, 0.05);
    }

  })
  return load ? (
    <mesh {...props} ref={mesh} scale={scale}>
      {
        (props.productCode === 'DW307' || props.productCode === 'DW103' || props.productCode === 'BGR210') && customShape ? (
          <bufferGeometry attach="geometry"
            attributes={customShape.children[0].geometry.attributes}
            boundingBox={customShape.children[0].geometry.boundingBox}
            boundingSphere={customShape.children[0].geometry.boundingSphere}
            drawRange={customShape.children[0].geometry.drawRange}
            groups={customShape.children[0].geometry.groups}
            index={customShape.children[0].geometry.index}
            morphAttributes={customShape.children[0].geometry.morphAttributes}
            morphTargetsRelative={customShape.children[0].geometry.morphTargetsRelative}
            name={customShape.children[0].geometry.name}
            type={customShape.children[0].geometry.type}
            userData={customShape.children[0].geometry.userData}
            uuid={customShape.children[0].geometry.uuid}

          >
          </bufferGeometry>
        ) : (
          <cylinderBufferGeometry attach="geometry" args={args} />
        )
      }
      {
        <meshPhysicalMaterial
          transparent={transparent}
          side={THREE.DoubleSide}
          roughness={roughness}
          clearcoatRoughness={clearcoatRoughness}
          clearcoat={clearcoat}
          metalness={metalness}
          metalnessMap={metalMap}
          color={colorFilter}
          emissive={emissiveFilter}
          // clipShadows={true}
          opacity={1}
          alphaTest={0.5}

        >
          <primitive attach="map" object={texture} />
        </meshPhysicalMaterial>
      }
    </mesh>
  ) : null;
};

export default Logo;
