const productData = [
    {
        "type": "bottle",
        "productCode": "DW100",
        "productName": "Straight Shooter",
        "productSummary": "700 ML / 24 OZ TRITAN BOTTLE",
        "productDimensions": '(1.750" x 9.840" x 2.280")',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Silver",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW100_v1632513672.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW101",
        "productName": "Shot Caller",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "White", "code": "White" },
            { "name": "Yellow", "code": "Yellow" },
        ],
        "defaultColor": "White",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW101_v1632513736.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW103",
        "productName": "Show Stopper",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "White", "code": "White" },
            { "name": "Taupe", "code": "Taupe" },
        ],
        "defaultColor": "White",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW103_v1636568424.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW300",
        "productName": "Crunch Time",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Stainless Steel", "code": "StainlessSteel" },
            { "name": "Blue", "code": "Blue" },
            { "name": "White", "code": "White" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW300_v1632837592.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW301",
        "productName": "Mastermind",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Silver", "code": "Silver" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW301_v1632513917.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW302",
        "productName": "Trend Setter Reflection",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDimensions": '(2.000" x 9.720" x 2.950")',
        "productDescription": "The 600 ml / 20 oz Trend Setter Reflection is a matte black double wall vacuum insulated bottle made with pro-grade 18/8 stainless steel. It offers a beautifully understated design and features a shiny metallic threaded lid that matches the Reflection undercoating when revealed by our Reflection Laser branding method. It’s the perfect bottle for that every day carry with its comfort sip insert for smooth sipping and wide mouth feature to easily add ice and clean.",
        "colorsAvailable": [
            { "name": "Black/Chrome", "code": "BlackChrome" },
            { "name": "Black/Gold", "code": "BlackGold" },
            { "name": "Black/Rose Gold", "code": "BlackRose" }
        ],
        "defaultColor": "BlackGold",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW302_v1632514024.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW303",
        "productName": "Trend Setter Natural",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Bamboo", "code": "Bamboo" },
            { "name": "Walnut", "code": "Walnut" }
        ],
        "defaultColor": "Bamboo",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW303_v1632514092.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW304",
        "productName": "Trend Setter Mini",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Red", "code": "Red" },
            { "name": "Stainless", "code": "Stainless" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW304_v1632514141.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW305",
        "productName": "Trend Setter Metallic",
        "productSummary": "600 ML / 20 OZ STAINLESS STEEL BOTTLE",
        "productDimensions": '(2.000" x 9.720" x 2.950")',
        "productDescription": "The 600 ml / 20 oz Trend Setter Metallic is a double walled vacuum insulated bottle made with pro-grade 18/8 stainless steel. It offers a matte metallic painted body with a shiny chrome leakproof threaded lid. It’s the perfect bottle for that every day carry with its comfort sip insert for smooth sipping and wide mouth feature to easily add ice and clean.",
        "colorsAvailable": [
            { "name": "White Pearl", "code": "WhitePearl" },
            { "name": "Rose Gold", "code": "RoseGold" },
            { "name": "Stainless Steel", "code": "StainlessSteel" }
        ],
        "defaultColor": "WhitePearl",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW305_v1643993509.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW306",
        "productName": "Top Notch",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black Chrome", "code": "BlackChrome" },
            { "name": "Black Gold", "code": "BlackGold" },
            { "name": "Charcoal", "code": "Gunmetal" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "White",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW306_v1653489589.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW307",
        "productName": "Twist & Shout",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Rose Gold", "code": "RoseGold" },
            { "name": "Stainless Steel", "code": "StainlessSteel" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "White",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW307_v1632514515.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW308",
        "productName": "Head Honcho",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Red", "code": "Red" },
            { "name": "Stainless Steel", "code": "StainlessSteel" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW308_v1632514570.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW309",
        "productName": "Eye Candy",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Pink", "code": "Pink" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW309_v1632514624.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW310",
        "productName": "Ring Leader",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "White", "code": "White" },
            { "name": "Green", "code": "Green" },
            { "name": "Grey", "code": "Grey" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW310_v1653668615.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW311",
        "productName": "Eye Candy - Double Dip",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW311_v1652109593.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW312",
        "productName": "Trend Setter Mini 360",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Gold", "code": "Gold" },
            { "name": "Silver", "code": "Silver" },
            { "name": "White Pearl", "code": "WhitePearl" },
        ],
        "defaultColor": "Silver",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW312_v1652113239.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW313",
        "productName": "The Boot Metallic",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Silver", "code": "Silver" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Silver",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW313_v1652109593.jpg"

    },
    {
        "type": "bottle",
        "productCode": "DW314",
        "productName": "Eye Candy - Unwined",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Silver", "code": "Silver" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Silver",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW314_v1652109593.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW316",
        "productName": "Switch Hitter - Wide Mouth",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Silver", "code": "Silver" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Silver",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW316_v1652109593.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW317",
        "productName": "Switch Hitter - Sport",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Silver", "code": "Silver" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Silver",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW317_v1652109593.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW319",
        "productName": "Top Notch Bamboo",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Navy", "code": "Navy" },
            { "name": "Red", "code": "Red" },
            { "name": "White", "code": "White" },
            { "name": "Yellow", "code": "Yellow" },
        ],
        "defaultColor": "Navy",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW319_v1652109593.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW320",
        "productName": "Trend Setter Ronan",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Navy", "code": "Navy" },
            { "name": "Gray", "code": "Gray" },
        ],
        "defaultColor": "Navy",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW320_v1662743227.jpg"
    },

    {
        "type": "bottle",
        "productCode": "DW321",
        "productName": "Call of the Wild Trend Setter",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Green", "code": "Green" },
            { "name": "Grey", "code": "Grey" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "./DW321_tooltip.png"
    },
    {
        "type": "bottle",
        "productCode": "DW322",
        "productName": "Media Bottle",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/w_DW322_v1671983584.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW323",
        "productName": "Media Bottle",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW323_v1672230405.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW324",
        "productName": "Loose Canon",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Red", "code": "Red" },
            { "name": "Blue", "code": "Blue" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/DW324_montage_brand_v1681958102.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW325",
        "productName": "Big Swig Sr.",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Pink", "code": "Pink" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Orange", "code": "Orange" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/DW325_montage_brand_v1682000409.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW326",
        "productName": "Big Swig Jr.",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Pink", "code": "Pink" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Orange", "code": "Orange" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/DW326_montage_brand_v1682001285.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW401",
        "productName": "Tough Cookie",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Red", "code": "Red" },
            { "name": "Stainless Steel", "code": "StainlessSteel" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW401_v1632699062.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW402",
        "productName": "Cruise Control",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Rose Gold", "code": "RoseGold" },
            { "name": "White", "code": "White" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW402_v1643993751.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW403",
        "productName": "Small Talk",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Rose Gold", "code": "RoseGold" },
            { "name": "White", "code": "White" },
            { "name": "Stainless Steel", "code": "StainlessSteel" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW403_v1643994063.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW404",
        "productName": "Small Talk (Natural)",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Walnut", "code": "Walnut" },
            { "name": "Bamboo", "code": "Bamboo" },

        ],
        "defaultColor": "Walnut",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW404_v1655151556.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW405",
        "productName": "Quick Draw Tumbler",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Navy", "code": "Navy" },
            { "name": "Red", "code": "Red" },

        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW405_montage_brand_v1682001689.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW406",
        "productName": "Mean Muggin' Metallic",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Pearl", "code": "Pearl" },
            { "name": "Rose Gold", "code": "RoseGold" },
            { "name": "Stainless Steel", "code": "StainlessSteel" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW406_v1643994258.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW407",
        "productName": "Mean Muggin' Luxe Metallic",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Chrome", "code": "Chrome" },
            { "name": "Gold", "code": "Gold" },
        ],
        "defaultColor": "Chrome",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW407_v1632699257.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW408",
        "productName": "Cool Cat Fabrizio",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Brown", "code": "Fabrizio" },
            { "name": "Brown", "code": "Fabrizio" },
        ],
        "defaultColor": "Fabrizio",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW408_v1630504649.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW409",
        "productName": "Game Changer",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Purple", "code": "Purple" },
            { "name": "Red", "code": "Red" },
            { "name": "White", "code": "White" },
            { "name": "Yellow", "code": "Yellow" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW409_v1652109593.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW410",
        "productName": "Cruise Control Natural",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Bamboo", "code": "Bamboo" },
            { "name": "Walnut", "code": "Walnut" },
        ],
        "defaultColor": "Bamboo",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW410_v1652109593.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW411",
        "productName": "Mean Muggin' Ronan",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Navy", "code": "Navy" },
            { "name": "Gray", "code": "Gray" },
        ],
        "defaultColor": "Navy",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW411_v1661963369.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW412",
        "productName": "Cruise Control Reflection",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black/Gold", "code": "BlackGold" },
            { "name": "Rose Gold", "code": "RoseGold" },
        ],
        "defaultColor": "BlackGold",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW412_v1661963369.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW413",
        "productName": "Small Talk Reflection",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black/Gold", "code": "BlackGold" },
            { "name": "Rose Gold", "code": "RoseGold" },
        ],
        "defaultColor": "BlackGold",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW413_v1661963369.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW414",
        "productName": "Tall Muggin Metallic",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Black", "code": "Black" },
            // {"name": "Gold", "code": "Gold"},
            { "name": "Pearl", "code": "Pearl" },
            { "name": "Pearl", "code": "Pearl" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW414_v1661963369.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW415",
        "productName": "Call of the Wild XL Mug",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Green", "code": "Green" },
            { "name": "Grey", "code": "Grey" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW415_v1672230782.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW416",
        "productName": "Call of the Wild Tall XXL Mug",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Green", "code": "Green" },
            { "name": "Grey", "code": "Grey" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW416_v1672231076.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW417",
        "productName": "Wild Card",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Red", "code": "Red" },
            { "name": "Navy Blue", "code": "NavyBlue" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/0_DW417_montage_brand_v1682002008.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW418",
        "productName": "Go Getter Tumbler",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Red", "code": "Red" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Yellow", "code": "Yellow" },
            { "name": "Purple", "code": "Purple" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW418_montage_brand_v1682002686.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW500",
        "productName": "Smooth Operator Glass",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Red", "code": "Red" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Grey", "code": "Grey" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/0_DW500_montage_brand_v1682003271.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW502",
        "productName": "Lil' Sipper Glass Cup",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "White", "code": "White" },
            { "name": "Grey", "code": "Grey" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW502_montage_brand_v1682003656.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW603",
        "productName": "The Grind (Blank)",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Coffee", "code": "Coffee" },
            { "name": "Coffee", "code": "Coffee" },
        ],
        "defaultColor": "Coffee",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW603_v1630504649.jpg"
    },
    {
        "type": "bottle",
        "productCode": "DW604",
        "productName": "The Grind (Printed)",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Brown", "code": "Brown" },
            { "name": "Brown", "code": "Brown" },
        ],
        "defaultColor": "Brown",
        "productImg": "https://www.spectorandco.com/product_images/products/1_DW604_v1630504649.jpg"
    },
    {
        "type": "pen",
        "productCode": "EC146",
        "productName": "Cork",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Natural", "code": "Natural" },
            { "name": "Natural", "code": "Natural" },
        ],
        "defaultColor": "Natural",
        "productImg": "https://www.spectorandco.com/product_images/products/1_EC146_v1639417761.jpg",
    },
    {
        "type": "pen",
        "productCode": "EC117",
        "productName": "Trigo",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Grey", "code": "Grey" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Natural", "code": "Natural" },
        ],
        "defaultColor": "Blue",
        "productImg": "https://www.spectorandco.com/product_images/products/1_EC117_v1623421479.jpg",
    },
    {
        "type": "pen",
        "productCode": "I154",
        "productName": "Top Notch Reflection",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black Chrome", "code": "BlackChrome" },
            { "name": "Black Gold", "code": "BlackGold" },
            { "name": "Black Rose Gold", "code": "BlackRoseGold" },
            { "name": "Charcoal Chrome", "code": "CharcoalChrome" },
            { "name": "White Rose Gold", "code": "WhiteRoseGold" },
        ],
        "defaultColor": "BlackChrome",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I154_v1639417761.jpg",
    },
    {
        "type": "pen",
        "productCode": "G3141",
        "productName": "Donald",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G3141_v1504626262.jpg",
    },
    {
        "type": "pen",
        "productCode": "G3148",
        "productName": "Belmond Donald",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G3148_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1005",
        "productName": "Veneno Silver",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Red", "code": "Red" },
            { "name": "Gray", "code": "Gray" },
            { "name": "Orange", "code": "Orange" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1005_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1009",
        "productName": "Veneno",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Blue", "code": "Blue" },
            { "name": "Gun Metal", "code": "GunMetal" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
        ],
        "defaultColor": "Blue",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1009_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1282",
        "productName": "Alix Ballpoint Pen",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Gray", "code": "Gray" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Purple", "code": "Purple" },
            { "name": "Red", "code": "Red" },
            { "name": "Yellow", "code": "Yellow" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1282_v1594664540.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1103",
        "productName": "Lara",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1103_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1182",
        "productName": "Andy",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1182_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1247",
        "productName": "Veneno Pen/Stylus",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Gun Metal", "code": "GunMetal" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1247_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1249",
        "productName": "Cynthia",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Purple", "code": "Purple" },
            { "name": "Red", "code": "Red" },
            { "name": "Yellow", "code": "Yellow" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1249_v1578684671.jpg",
    },
    {
        "type": "pen",
        "productCode": "G1254",
        "productName": "Donald",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G1254_v1580941092.jpg",
    },
    {
        "type": "pen",
        "productCode": "G3001",
        "productName": "Bruno",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G3001_v1491589325.jpg",
    },
    {
        "type": "pen",
        "productCode": "G3038",
        "productName": "Ali",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Burgundy", "code": "Burgundy" },
            { "name": "Green", "code": "Green" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G3038_v1583434911.jpg",
    },
    {
        "type": "pen",
        "productCode": "G3108",
        "productName": "Ali Executive",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Gun Metal", "code": "GunMetal" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G3108_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "G3113",
        "productName": "Romilda",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black Chrome", "code": "BlackChrome" },
            { "name": "Black Gun Metal", "code": "BlackGunMetal" },
            { "name": "Silver", "code": "Silver" },
            { "name": "White Chrome", "code": "WhiteChrome" },
        ],
        "defaultColor": "BlackChrome",
        "productImg": "https://www.spectorandco.com/product_images/products/1_G3113_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "HW204",
        "productName": "Cynthia",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_HW204_v1610058847.jpg",
    },
    // {
    //     "type": "pen",
    //     "productCode": "HW205C",
    //     "productName": "Misty Pen/Hand Sanitizer Spray",
    //     "productSummary": "",
    //     "productDimensions": '',
    //     "productDescription": "",
    //     "colorsAvailable": [
    //         {"name": "Black", "code": "Black"},
    //         {"name": "Blue", "code": "Blue"},
    //         {"name": "Green", "code": "Green"},
    //         {"name": "Red", "code": "Red"},
    //         {"name": "White", "code": "White"},
    //     ],
    //     "defaultColor": "Black",
    //     "productImg": "https://www.spectorandco.com/product_images/products/1_HW205_v1614010306.jpg",
    // },
    {
        "type": "pen",
        "productCode": "EC122",
        "productName": "Asia",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Black Silver", "code": "BlackSilver" },
            { "name": "Brown", "code": "Brown" },
            { "name": "Grey", "code": "Grey" },
            { "name": "Navy Blue", "code": "NavyBlue" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_EC122_v1583421381.jpg",
    },
    {
        "type": "pen",
        "productCode": "EC131",
        "productName": "Ali Bamboo",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Natural", "code": "Natural" },
            { "name": "Natural", "code": "Natural" },
        ],
        "defaultColor": "Natural",
        "productImg": "https://www.spectorandco.com/product_images/products/1_EC131_v1576536062.jpg",
    },
    {
        "type": "pen",
        "productCode": "I153",
        "productName": "Lindsay",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I153_v1630526226.jpg",
    },
    {
        "type": "pen",
        "productCode": "I116",
        "productName": "Ali",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Green", "code": "Green" },
            { "name": "Gunmetal", "code": "Gunmetal" },
            { "name": "Navy Blue", "code": "NavyBlue" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I116_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "I136",
        "productName": "Ali Pen/Stylus",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Blue", "code": "Blue" },
            { "name": "Chrome", "code": "Chrome" },
            { "name": "Gold", "code": "Gold" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "White", "code": "White" },
        ],
        "defaultColor": "Blue",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I136_v1582665478.jpg",
    },
    {
        "type": "pen",
        "productCode": "I151",
        "productName": "Veneno Pen/Stylus/Highlighter",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            // {"name": "Black", "code": "Black"},
            // {"name": "Green", "code": "Green"},
            // {"name": "Lime", "code": "Lime"},
            // {"name": "Orange", "code": "Orange"},
            // {"name": "Pink", "code": "Pink"},
            // {"name": "White", "code": "White"},
            { "name": "Blue", "code": "Blue" }
        ],
        "defaultColor": "Blue",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I151_v1568648058.jpg",
    },
    {
        "type": "pen",
        "productCode": "I150",
        "productName": "Locus",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Charcoal", "code": "Charcoal" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I150_v1616528082.jpg",
    },
    {
        "type": "pen",
        "productCode": "I138",
        "productName": "Glacio Pen/Stylus",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Blue", "code": "Blue" },
            { "name": "Dark Blue", "code": "DarkBlue" },
            { "name": "Green 348", "code": "Green348" },
            { "name": "Green 367", "code": "Green367" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "White", "code": "White" },
            { "name": "Yellow", "code": "Yellow" },
        ],
        "defaultColor": "Blue",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I138_v1596046275.jpg",
    },
    {
        "type": "pen",
        "productCode": "I128",
        "productName": "Glacio",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Gold", "code": "Gold" },
            { "name": "Gunmetal", "code": "Gunmetal" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I128_v1484802000.jpg",
    },
    {
        "type": "pen",
        "productCode": "I228",
        "productName": "Glacio Pen/Stylus",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Black", "code": "Black" },
            { "name": "Blue", "code": "Blue" },
            { "name": "Green", "code": "Green" },
            { "name": "Orange", "code": "Orange" },
            { "name": "Red", "code": "Red" },
            { "name": "Silver", "code": "Silver" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_I228_v1523650023.jpg",
    },
    {
        "type": "bag",
        "productCode": "BG700",
        "productName": "Nomad Must Haves Accessory Case",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Grey", "code": "Grey" },
            { "name": "Grey", "code": "Grey" },
        ],
        "defaultColor": "Grey",
        "productImg": "https://www.spectorandco.com/product_images/products/1_BG700_v1539874294.jpg",
    },
    {
        "type": "bag",
        "productCode": "BG504",
        "productName": "Call of The Wild Tote Bag",
        "productSummary": "",
        "productDimensions": '',
        "productDescription": "",
        "colorsAvailable": [
            { "name": "Grey", "code": "Grey" },
            { "name": "Black", "code": "Black" },
        ],
        "defaultColor": "Black",
        "productImg": "https://www.spectorandco.com/product_images/products/1_BG504_v1539874294.jpg",
    },
]

export default productData
