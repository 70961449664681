import React, { useState, } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TableContainer,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import { VideoCard } from "material-ui-player";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";


const theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
  },
});

const NotSupported = () => {
  const [openVideo, setOpenVideo] = useState(false);

  const handleCloseTutorialModal = () => {
    setOpenVideo(false);
  };

  const tutorialModal = (
    <div
      id="tutorial-modal"
      style={{
        background: "white",
        maxWidth: 1000,
        maxHeight: 600,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 20,
        padding: 50,
        marginTop: 40,
        paddingLeft: 50,
        paddingRight: 50,
      }}
    >
      <ThemeProvider theme={theme}>
        <VideoCard
          src="./tutorial.mp4"
          autoplay={true}
          mute={true}
          thickness={"thin"}
          onEnded={handleCloseTutorialModal}
          width={800}
          background={"white"}
        />
      </ThemeProvider>
    </div>
  );

  return (
    <Grid
      container
      className="home-page"
      direction="row"
      style={{
        paddingTop: "100px",
        // paddingLeft: '120px',
        userSelect: "none",
      }}
      spacing={1}>
      {/**HEADER */}
      <Grid item xs={12} style={{ display: "inline-block" }}>
        <img
          alt="Decd Out Logo"
          src={"./logo-alt.png"}
          className="decdout-logo"
        />
        {/* play button here */}
        <Button
          onClick={() => setOpenVideo(true)}
          variant="contained"
          style={{
            position: "absolute",
            top: "40px",
            left: "225px",
            borderRadius: "50px",
            color: "white",
            backgroundColor: "#FCB034",
          }}
        >
          Watch the tutorial
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Modal
          open={openVideo}
          onClose={handleCloseTutorialModal}
          style={{ overflowY: "scroll", marginBottom: "20px" }}
        >
          {tutorialModal}
        </Modal>
      </Grid>
      {/**HEADER */}
      <div className="not-support-container">
        <div className="mess">
          <Typography sx={{ fontSize: "2rem", fontWeight: 900, color: "#FCB034" }}>Oops!</Typography>
          <Typography sx={{ fontWeight: 900, marginTop: "1rem", marginBottom: "1rem" }}>It appears that your computer's hardware might not meet the minimum requirements for Dec'd Out. To fully immerse yourself in a visually stunning experience, we highly recommend using a desktop with a more robust graphics card.</Typography>
          <img width="100" height="100" src="./icons8-front-view-100.png" alt="front-view" />
        </div>

      </div>
    </Grid>
  );

}

export default NotSupported;