import { scale } from "chroma-js";
import React, { useEffect, useRef, useState, useContext } from "react";
import * as THREE from "three";
import EventEmitterContext from '../../../Utils/EventEmitterContext';

const LogoCenteredClip = (props) => {
    const mesh = useRef()
    const texture = new THREE.TextureLoader().load(props.image)
    texture.repeat = new THREE.Vector2(1, 0.95)
    texture.center = new THREE.Vector2(1, 1)
    texture.encoding = THREE.sRGBEncoding;
    const [load, setLoad] = useState(false)
    const eventEmitter = useContext(EventEmitterContext);
    let process = props.process
    let roughness = 1
    let metalness = 0
    let clearcoat = 0
    let clearcoatRoughness = 0
    let metalMap = null
    let args = [10, 10]
    let scale = 1;

    useEffect(() => {
        const callback = () => {
            // Handle the event
            console.log('Event triggered! LOGO.JS FUCK YOU KA!');
            setLoad(true)
        };
        eventEmitter.on('fullyLoaded', callback);
        return () => eventEmitter.off('fullyLoaded')
    }, [])

    if (process === 'LASER ENGRAVED' || process === 'REFLECTION LASER') {
        metalness = 1
        roughness = 0.35
        metalMap = new THREE.TextureLoader().load('brushed.png')
    } else if (process === 'SILK SCREENED') {
        metalness = 0.75
        roughness = 1
    } else if (process === '4CP FLEX') {
        clearcoat = 0
        clearcoatRoughness = 0
    } else if (process === "4CP FLEX TRANSFER") {
        metalness = 0.90;
        roughness = 1;
    } else {
        metalness = 0.75
        roughness = 1
        // clearcoat = 0.75
        // clearcoatRoughness = 0.075
    }

    if (load) {
        if (props.productCode === 'EC117') {
            args = [0.17, 0.7];
        } else if (props.productCode === 'G3113') {
            args = [0.17, 0.7];
        }
        if (props.productCode === "I153") {
            scale = 1.5
        }
    }

    useEffect(() => {
        if (load) {
            if (props.productCode === 'G3113') {
                mesh.current.rotation.y = -1.6;
            }
        }
    }, [load])

    // if (productCode === 'DW321') {
    //     fbx.rotateY(-0.25)
    // } else if (productCode === 'G1103') {
    //     // fbx.rotateY(0)
    //     fbx.rotateY(1)
    // }

    return load ? (
        <mesh
            {...props}
            ref={mesh}
            scale={scale}
        >
            <planeBufferGeometry attach="geometry" args={args} />
            <meshPhysicalMaterial
                transparent
                side={THREE.FrontSide}
                roughness={roughness}
                clearcoatRoughness={clearcoatRoughness}
                clearcoat={clearcoat}
                metalness={metalness}
                metalnessMap={metalMap}
            >
                <primitive attach="map" object={texture} />
            </meshPhysicalMaterial>
        </mesh>
    ) : null;
}

export default LogoCenteredClip